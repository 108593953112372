import React, { useState, useEffect } from "react";

const sections = ["introduction", "rubric"];

const ScoringCaratTool = () => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div id="introduction" className="reading-sections-container">
          <div className="reading-subsections-container">
            <h1 className="custom-display text-gray900">
              Scoring and Using the Audit Tool
            </h1>
            <p className="custom-18 text-gray600">
              CARAT uses seven scoring sections to help community leaders and
              advocates to: - Identify and document your community’s assets; -
              Engage purposefully with a wide variety of food system actors; -
              Gain perspective from a diverse array of community voices; and -
              Better understand strengths and gaps in your community’s overall
              food system infrastructure.
            </p>
          </div>
          <p className="custom-18 text-gray600">
            The scoring mechanism is not a simple checklist of ‘yes’ and ‘no’
            activities. Instead, it is intended to be a qualitative assessment
            of how well your food system focus area currently seems to be
            managing seven primary elements of community resilience.
          </p>
          <p className="custom-18 text-gray600">
            To make the most effective use of the CARAT tool, it is best that
            each participating respondent provide an honest appraisal of how
            well their food system focus area has fulfilled each of the listed
            resilience indicators, using the provided scoring mechanism and
            background information sheet. Keep in mind that the primary
            objective here is to understand where your community stands at this
            particular point in time, not to achieve a high numeric score. The
            more context you can provide for why certain action steps were—or
            were not—taken, the richer and more valuable your assessment will
            be. For example, explaining why your food system focus area has
            limited geographic access to farmland is far more valuable than a
            simple ‘no’ response. Optimally, these answers should be generated
            through community dialogue so your response can be as detailed and
            inclusive as possible.
          </p>
          <p className="custom-18 text-gray600">
            Since each locality has distinct characteristics, we have applied
            very general language (e.g., the terms “policies, practices, and
            programs”) to describe the nature of activities occurring in your
            food system. With all the possible variations in community food
            systems and creativity in how people have pursued the goal of
            resilience, we may not have captured all the possibilities.
            Therefore, if the topic of a particular indicator of your food
            system is prevalent in other ways, please acknowledge that in your
            assessment. We encourage you to include anything missing in the
            CARAT tool as prompts in the “additional” section at the end of each
            theme.
          </p>
          <div id="rubric" className="reading-sections-container">
            <div className="reading-subsections-container">
              <h2 className="custom-30 text-gray700">Notes</h2>
              <p className="custom-18 text-gray600">
                Since each locality has distinct characteristics, we have
                applied very general language (e.g., the terms “policies,
                practices, and programs”) to describe the nature of activities
                occurring in your food system. With all the possible variations
                in community food systems and creativity in how people have
                pursued the goal of resilience, we may not have captured all the
                possibilities. Therefore, if the topic of a particular indicator
                of your food system is prevalent in other ways, please
                acknowledge that in your assessment. We encourage you to include
                anything missing in the CARAT tool as prompts in the
                “additional” section at the end of each theme.
              </p>
            </div>
            <div id="Rubric" className="flex flex-col gap-4 custom-16 w-full">
              <div className="flex-row flex">
                <div className="rubric-tag">N/A</div>

                <p className="w-5/6 text-gray600">
                  <span className="text-gray700 font-semibold">
                    Does not apply:
                  </span>{" "}
                  This response indicates that the indicator item is irrelevant
                  to your particular community food system. For example, a
                  high-density urban area may not have food production at an
                  acreage scale for agriculture.
                </p>
              </div>
              <div className="flex-row flex">
                <div className="rubric-tag">0</div>
                <p className="w-5/6 text-gray600">
                  <span className="text-gray700 font-semibold">No:</span> Your
                  team is not aware of any current policy, practice, or program
                  in place, no currently active discussion, and no movement
                  toward developing a pertinent policy, practice, program, or
                  investment addressing the topic.
                </p>
              </div>
              <div className="flex-row flex">
                <div className="rubric-tag">1</div>
                <p className="w-5/6 text-gray600">
                  <span className="text-gray700 font-semibold">
                    Being considered:
                  </span>{" "}
                  One or more groups within the community is currently
                  advocating for a pertinent policy, practice, program, or
                  investment regarding this topic, but no formal plan is in
                  place.
                </p>
              </div>
              <div className="flex-row flex">
                <div className="rubric-tag">2</div>
                <p className="w-5/6 text-gray600">
                  <span className="text-gray700 font-semibold">In place:</span>{" "}
                  A pertinent policy, practice, program, or investment
                  addressing the problem or opportunity has been newly adopted
                  or adopted for a year or more without active implementation
                  efforts and/or dedicated funding.
                </p>
              </div>
              <div className="flex-row flex">
                <div className="rubric-tag">3</div>
                <p className="w-5/6 text-gray600">
                  <span className="text-gra700 font-semibold">
                    Being implemented:
                  </span>{" "}
                  A pertinent policy, practice, program, or investment is
                  currently in force with substantial resources allocated to
                  support continuing active steps to implement it effectively
                  and, if in effect for more than a year, it is producing
                  demonstrable results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[108px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoringCaratTool;
