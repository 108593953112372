import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

const IndicatorsSection = ({ assessment, bookmarks, handleBookmark }) => {
  const { user } = useAuthContext();
  const [resourcesData, setResourcesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchResources();
  }, [user]);

  const fetchResources = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/resources`
      );
      setResourcesData(response.data);
    } catch (error) {
      console.error("Error fetching resources", error);
    }
  };

  const findResourcesForIndicator = (indicatorNum) => {
    return resourcesData.filter(
      (resource) => resource.indicatorNum === indicatorNum
    );
  };

  const allQuestions = assessment.themes.flatMap((theme) =>
    theme.sections.flatMap((section) =>
      section.questions.map((question) => ({
        qNum: question.qNum,
        questionText: question.question,
        answer:
          question.answer === 5 ? "N/A" : (question.answer - 1).toString(),
        comment: question.comment,
      }))
    )
  );

  const answerColor = (answer) => {
    if (["0", "1", "2"].includes(answer)) {
      return "text-error"; // red color for 0, 1, 2
    } else if (answer === "N/A") {
      return "text-stone"; // grey color for N/A
    } else {
      return "text-sprout"; // green color for other values
    }
  };

  const filteredQuestions = allQuestions.filter(
    (question) =>
      question.questionText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      question.qNum.toString().includes(searchQuery)
  );

  return (
    <div className="print-page-break border border-bordersecondary rounded-lg print:border-transparent">
      <div className="px-6 py-5">
        <h2 className="custom-18-semi text-gray900">All Indicators</h2>
      </div>

      <div
        id="Divider"
        className="h-[1px] w-full bg-bordersecondary print:hidden"
      ></div>
      <div className="px-6 py-5 print:hidden">
        {/* Implement search bar here */}
        <input
          type="text"
          placeholder="Search for an indicators name or number"
          className="search-bar"
          style={{
            backgroundImage: "url('/assets/search-lg.png')",
            backgroundPosition: "10px center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "20px",
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
      <div className="px-6 py-5">
        <div className="warning-banner print:hidden">
          <img
            src="/assets/warning-block.png"
            alt="Warning"
            className="w-12 h-12"
          />
          <div>
            <p className="custom-16-med text-gray700">
              Below are all the indicators in the assessment
            </p>
            <p className="custom-14 text-gray600">
              If you wish to view the all the indicator's resources, please
              visit the{" "}
              <a
                href="https://docs.google.com/document/d/1WzuuYXPqL2LqLuV34QmP8oVuo846VmUvIKdcFEcp8Yk/edit"
                className="text-nafsnBlue underline"
              >
                CARAT Resource Archive
              </a>
            </p>
          </div>
        </div>
      </div>

      <ul className="flex flex-col gap-4 desktop-lg:max-w-[600px]">
        {filteredQuestions.map((question, index) => {
          const resources = findResourcesForIndicator(question.qNum);

          return (
            <li key={index} className="px-6 py-5 print-avoid-break">
              <div className="">
                <div id="indicator-info" className="">
                  <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm inline-flex items-center">
                    <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                    <h2 className="custom-14-med text-gray700">
                      Indicator {question.qNum}
                    </h2>
                  </div>
                  <p className="custom-20-med text-gray900 mb-2">
                    {question.questionText}
                  </p>
                  <p className="custom-16 text-gray600 mb-1">
                    Your response:{" "}
                    <span className={answerColor(question.answer)}>
                      {question.answer}
                    </span>
                  </p>
                  <p className="custom-16 text-gray600 desktop:mb-0 mobile:mb-4">
                    Your notes:{" "}
                    <span className="text-gray900 custom-16">
                      {question.comment || "None"}
                    </span>
                  </p>
                </div>

                {/* <div className="resource-list flex flex-col gap-4 w-full">
                  {resources.length === 0 ? (
                    <div
                      id="no-resources"
                      className="p-6 rounded-lg border-bordersecondary border flex-grow"
                    >
                      <p className="custom-16 text-gray600">
                        No resources available for this indicator.
                      </p>
                    </div>
                  ) : (
                    resources.map((resource) => {
                      const isBookmarked = bookmarks.some(
                        (bookmark) => bookmark._id === resource._id
                      );

                      return (
                        <div
                          key={resource._id}
                          id="resource-item"
                          className="border tablet:p-6 mobile:p-4 rounded-lg border-bordersecondary"
                        >
                          <div className="flex flex-row gap-6 justify-between">
                            <p className="resource-name custom-18-semi text-gray900 mb-2">
                              {resource.resourceName}
                            </p>
                            <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm items-center max-h-[24px] tablet:inline-flex mobile:hidden">
                              <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                              <h2 className="custom-14-med text-gray700">
                                Resource
                              </h2>
                            </div>
                          </div>

                          <p className="resource-description custom-16 text-gray600 mb-4">
                            {resource.resourceDescription}
                          </p>
                          <div className="flex flex-row gap-3">
                            <div className="small-button">
                              <img
                                src="/assets/link-external.png"
                                alt="External Link Icon"
                                className="w-4 h-4"
                              />
                              <a
                                href={resource.resourceLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Learn More
                              </a>
                            </div>
                            <button
                              onClick={() =>
                                handleBookmark(resource, assessment.name)
                              }
                              className={`small-button custom-14-med  ${
                                isBookmarked ? "text-sprout" : "text-nafsnBlue"
                              }`}
                            >
                              <div>
                                {isBookmarked ? (
                                  <img
                                    src="/assets/bookmark-solid.png"
                                    alt="Unbookmark Icon"
                                    className="w-4 h-4"
                                  />
                                ) : (
                                  <img
                                    src="/assets/bookmark.png"
                                    alt="Bookmark Icon"
                                    className="w-4 h-4"
                                  />
                                )}
                              </div>
                              <p>{isBookmarked ? "Unbookmark" : "Bookmark"}</p>
                            </button>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div> */}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default IndicatorsSection;
