import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

const ResourcesEditor = () => {
  const { user } = useAuthContext();

  const [resources, setResources] = useState([]);
  const [newResource, setNewResource] = useState({
    themeName: "",
    indicatorNum: "",
    indicatorName: "",
    resourceName: "",
    resourceDescription: "",
    resourceLink: "",
  });
  const [editingResource, setEditingResource] = useState(null);

  // Fetch resources from the backend
  const fetchResources = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/resources`,
        {
          headers: {
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setResources(data);
    } catch (error) {
      console.error("Fetch resources failed:", error);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  // Handle input changes
  const handleChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setEditingResource({ ...editingResource, [name]: value });
    } else {
      setNewResource({ ...newResource, [name]: value });
    }
  };

  // Add a new resource
  const addResource = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/resources`,
      {
        method: "POST",
        body: JSON.stringify(newResource),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
        },
      }
    );

    if (response.ok) {
      setNewResource({
        themeName: "",
        indicatorNum: "",
        indicatorName: "",
        resourceName: "",
        resourceDescription: "",
        resourceLink: "",
      });
      fetchResources(); // Refresh the list
    }
  };

  // Delete a resource
  const deleteResource = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/resources/${id}`,
      {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${user.token}`,
        },
      }
    );
    if (response.ok) {
      fetchResources(); // Refresh the list
    }
  };

  // Update a resource
  const updateResource = async () => {
    if (editingResource) {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/resources/${editingResource._id}`,
        {
          method: "PATCH",
          body: JSON.stringify(editingResource),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        setEditingResource(null);
        fetchResources(); // Refresh the list
      }
    }
  };

  return (
    <div className="mt-4 flex flex-col gap-6 flex-grow w-full">
      <h1 className="text-2xl font-semibold text-gray-900 mb-4">
        Edit Resources
      </h1>
      <div className="flex flex-col gap-4">
        {/* Form for adding/editing a resource */}
        <input
          type="text"
          placeholder="Theme Name"
          name="themeName"
          value={
            editingResource ? editingResource.themeName : newResource.themeName
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <input
          type="number"
          placeholder="Indicator Number"
          name="indicatorNum"
          value={
            editingResource
              ? editingResource.indicatorNum
              : newResource.indicatorNum
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Indicator Name"
          name="indicatorName"
          value={
            editingResource
              ? editingResource.indicatorName
              : newResource.indicatorName
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Resource Name"
          name="resourceName"
          value={
            editingResource
              ? editingResource.resourceName
              : newResource.resourceName
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <textarea
          placeholder="Resource Description"
          name="resourceDescription"
          value={
            editingResource
              ? editingResource.resourceDescription
              : newResource.resourceDescription
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Resource Link"
          name="resourceLink"
          value={
            editingResource
              ? editingResource.resourceLink
              : newResource.resourceLink
          }
          onChange={(e) => handleChange(e, !!editingResource)}
          className="input-field"
        />
        <div>
          <button
            onClick={editingResource ? updateResource : addResource}
            className="normal-button"
          >
            {editingResource ? "Update Resource" : "Add Resource"}
          </button>
        </div>
      </div>

      {/* List of resources */}
      <div className="mt-8">
        {resources.map((resource) => (
          <div
            key={resource._id}
            className="mb-4 p-4 border border-borderprimary rounded-lg flex flex-col gap-2"
          >
            <div>
              <h3 className="text-lg font-semibold">{resource.resourceName}</h3>
              <p>
                <strong>Description:</strong> {resource.resourceDescription}
              </p>
              <a
                href={resource.resourceLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-river hover:underline"
              >
                View Resource at {resource.resourceLink}
              </a>
            </div>
            <div>
              <p>
                <strong>Theme Name:</strong> {resource.themeName}
              </p>
              <p>
                <strong>Indicator Number:</strong> {resource.indicatorNum}
              </p>
              <p>
                <strong>Indicator Name:</strong> {resource.indicatorName}
              </p>
            </div>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() => {
                  setEditingResource(resource);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="normal-button"
              >
                Edit
              </button>
              <button
                onClick={() => deleteResource(resource._id)}
                className="normal-button"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourcesEditor;
