import React, { useState, useEffect } from "react";

const sections = ["notes", "food systems diagram"];

const NotesFromDev = () => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div id="notes" className="reading-sections-container">
          <div className="reading-subsections-container">
            <h1 className="custom-display text-gray900">
              Notes from the Developers
            </h1>
            <p className="custom-18 text-gray600">
              As food system leaders representing a wide range of disciplines,
              we have observed that the strengths of local food systems
              contribute significantly to developing and maintaining community
              resilience.
            </p>
          </div>
          <div className="reading-subsections-container">
            <p className="custom-18 text-gray600">
              All too often, people view food systems as simply comprising the
              linear connections between food production, distribution, and
              consumption. This perspective fails to consider the fact that
              vibrant local food systems play multidimensional roles in
              community resilience, some of which are not intuitively obvious at
              first glance. These include the role of local food systems in
              helping communities to:
            </p>
            <ul className="list-disc pl-5">
              <li className="custom-18 text-gray600 mb-1">
                better plan for and manage supply chain disruptions;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                reduce the occurrence of household food insecurity;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                advance nutritional and public health goals;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                advance nutritional and public health goals;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                keep a greater portion of farm-based income and other resources
                recycled within the local community;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                increase a community’s capacity to address the cultural food
                preferences of a diverse population.
              </li>
            </ul>
          </div>
        </div>
        <div id="food systems diagram" className="reading-sections-container">
          {" "}
          <div className="flex flex-col items-center justify-center w-full ">
            {" "}
            <img
              src="/assets/food-systems-diagram.png"
              alt="Foodicons"
              className="w-fill max-h-[500px] max-w-[500px]"
            />
            <p className="text-gray600 italic custom-16">
              Image borrowed from{" "}
              <a
                href="https://sociology.colostate.edu/research-outreach-and-engagement/food-wheel/?"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nafsnBlue hover:underline custom-16"
              >
                Colorado State University, Department of Sociology
              </a>{" "}
            </p>
          </div>
          <p className="custom-18 text-gray600">
            The image above illustrates the complex impacts of the food system.
            The interior circle represents a simplified structural perspective
            of the food system, primarily focusing on individual stakeholders
            and their economic contributions. The exterior circle features the
            seven community capitals developed by Flora and Flora, and
            represents a more holistic view of a food system’s impact on
            community resilience and collective well-being.
          </p>
          <p className="custom-18 text-gray600">
            By aligning our CARAT tool with a broader-based community capitals
            framework, our working group — convened by the{" "}
            <a
              href="https://sociology.colostate.edu/research-outreach-and-engagement/food-wheel/?"
              target="_blank"
              rel="noopener noreferrer"
              className="text-nafsnBlue hover:underline custom-18"
            >
              North American Food System Network
            </a>{" "}
            — seeks to give you and your team a more in-depth perspective of the
            relationship between developments in your local food system and your
            community’s ability to achieve your desired levels of empowerment,
            self-sufficiency, and quality of life.
          </p>
          <p className="custom-18 text-gray600">
            The self-assessment process outlined by the CARAT tool will allow
            you to identify those elements of your current food system that
            appear to be advancing your community’s move toward greater
            resilience, as well as identifying those areas that may be creating
            impediments to resilience or may be in need of improvement.
          </p>
          <div className="reading-subsections-container">
            <p className="custom-18 text-gray600">
              We envision that the CARAT tool can be used by community leaders
              and members like yourself to:
            </p>
            <ul className="list-disc pl-5">
              <li className="custom-18 text-gray600 mb-1">
                prompt and guide an engaged community-wide conversation about
                resilience priorities and needs;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                identify ideas and potential solutions that may increase your
                community’s resilience to unexpected disruptions;
              </li>
              <li className="custom-18 text-gray600 mb-1">
                assess whether the resilience of your current local food system
                is optimized to meet perceived community needs.
              </li>
            </ul>
          </div>
          <p className="custom-18 text-gray600">
            Our definition of resilience borrows from the{" "}
            <a
              href="https://sociology.colostate.edu/research-outreach-and-engagement/food-wheel/?"
              target="_blank"
              rel="noopener noreferrer"
              className="text-nafsnBlue hover:underline custom-18"
            >
              RAND Corporation
            </a>
            : Resilience is a measure of the sustained ability of a community to
            utilize available resources to respond to, withstand, and recover
            from adverse situations.
          </p>
          <p className="custom-18 text-gray600">
            By focusing our attention on seven specific areas of community
            resilience (aligned closely to the seven community capitals
            framework developed by Flora and Flora), we expect that the use of
            the CARAT tool will help you to acknowledge, lift up, and document
            your community’s current assets and areas of strength, while making
            it easier to identify and address existing barriers to resilience.
          </p>
          <p className="custom-18 text-gray600">
            Obstacles in achieving community resilience are often heightened by
            structural inequities, and thus it is imperative to focus on
            diversity, inclusion, and equity — ensuring that the CARAT process
            is fair and impartial to all. Many of these obstacles are deeply
            rooted in policy, yet they can often be solved through
            community-based practices and programs that emphasize inclusivity
            and engagement. While identifying and discussing your current
            policies, practices, and programs, we hope the CARAT tool will help
            you recognize and tackle equity-related challenges to build toward a
            more inclusive community food system.
          </p>
          <p className="custom-18 text-gray600">
            The CARAT tool is designed to be used by all types of communities no
            matter how much financial or political capital you have at your
            disposal. Ultimately, it is through collaborative efforts that
            significant change in your community food system is possible.
          </p>
        </div>
      </div>
      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[108px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesFromDev;
