import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";

const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const iconSrc = isOpen
    ? "/assets/minus-circle.png"
    : "/assets/plus-circle.png";

  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div className="my-6">
      <div className="flex justify-between items-center transition-all duration-300 ease-in-out">
        <h4 className="custom-18-med text-gray900">{question}</h4>
        <button className="" onClick={() => setIsOpen(!isOpen)}>
          <img
            src={iconSrc}
            alt={isOpen ? "Close" : "Open"}
            className="w-6 h-6 mt-[2px]" // Width and height set to 24px
          />
        </button>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: maxHeight }}
        className={`overflow-hidden transition-[max-height] duration-300 ease-in-out`}
      >
        <p className="mt-2 text-gray600 custom-16">{answer}</p>
      </div>
    </div>
  );
};

const CaratResources = () => {
  const [activeTab, setActiveTab] = useState("indicators");

  const getBgColor = () => {
    switch (activeTab) {
      case "indicators":
        return "bg-river";
      case "themes":
        return "bg-sprout";
      case "report":
        return "bg-cloud";
      default:
        return "bg-river";
    }
  };

  return (
    <div id="main-footer" className="">
      <div
        id="Overall-container"
        className="margin-adaptive mb-20 w-full mx-auto flex flex-col gap-24"
      >
        <div
          id="Landing-block"
          className="pt-44 flex flex-grow gap-16 flex-col bg-left bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url('/assets/bg-pattern-landing.png')`,
          }}
        >
          <div id="Intro" className="flex flex-col flex-grow gap-12">
            <div className="flex flex-col gap-6 ">
              <h1 className="custom-display2 max-w-[1024px] text-left">
                CARAT Resources
              </h1>
              <p className="text-gray600 custom-20 max-w-[450px]">
                {" "}
                Need to implement. Using AI for placeholder: This guide will
                cover everything you need to know about Carat, from the basics
                of getting started to advanced features that will help you make
                the most out of the platform.
              </p>
            </div>
            <div id="cta-buttons" className="flex flex-row gap-3">
              <Link to="/login">
                <button className="xlg-button">Log In</button>
              </Link>
              <Link to="/signup">
                <button className="xlg-button-cta">
                  Start using CARAT for free
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default CaratResources;
