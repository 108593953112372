import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import SectionsPreview from "../sectioncomponents/SectionsPreview";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useIsMobile } from "../hooks/useIsMobile";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMemo } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { useNavigate } from "react-router-dom";

import Questions from "../sectioncomponents/Questions";

export function processSectionsData(data) {
  // Check if data is valid and contains a sections array
  if (!data || !Array.isArray(data.sections)) {
    console.error("Invalid data:", data);
    return [];
  }

  // Sort sections in ascending order based on the 'index' field (or another field)
  // and return a new array with the sections in sorted order.
  // The spread operator (...) is used to ensure we're not modifying the original objects.
  return data.sections
    .map((section) => ({ ...section })) // Create a copy of each section
    .sort((a, b) => a.sNum - b.sNum); // Sort based on the 'index' field
}

export function sortQuestions(questions) {
  if (!questions || !Array.isArray(questions)) {
    console.error("Invalid questions data:", questions);
    return [];
  }

  return questions.sort((a, b) => a.qNum - b.qNum);
}

// Function that converts an integer into english word
function intToEnglishWord(num) {
  const words = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
    "twenty",
  ];

  return words[num] || "Number out of range";
}

const Sections = () => {
  const { assessmentId, themeName } = useParams();
  const [themeDesc, setThemeDesc] = useState([]);
  const [themeTNum, setThemeTNum] = useState([]);
  const [section, setSection] = useState([]);
  const { user } = useAuthContext();
  const [isUserAuthorizedToEdit, setIsUserAuthorizedToEdit] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [assessmentName, setAssessmentName] = useState("");
  const { logout } = useLogout();
  const processedSections = useMemo(() => {
    return processSectionsData(section);
  }, [section]);
  // Function to update the processedSections state
  const handleSectionsUpdate = (newSections) => {
    setSection(newSections);
  };

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  // State to track if all questions in the selected section are completed
  const [allQuestionsCompleted, setAllQuestionsCompleted] = useState(false);

  const [updatedVariable, setUpdatedVariable] = useState(false);
  // Function to update the variable
  const handleVariableUpdate = (newValue) => {
    setUpdatedVariable(newValue);
  };

  // New state for tracking save status
  const [saveStatus, setSaveStatus] = useState("");

  // Function to handle save status updates
  const handleSaveStatusUpdate = (status) => {
    setSaveStatus(status);

    if (status === "saving") {
      setTimeout(() => {
        setSaveStatus("saved");
        setTimeout(() => {
          setSaveStatus("");
        }, 1500);
      }, 300);
    }
  };

  // useEffect to track changes in updateVariable
  useEffect(() => {
    handleSaveStatusUpdate("saving");
  }, [updatedVariable]);

  const handleSectionSelect = async (section) => {
    setSelectedSection(section);
    setCurrentSectionIndex(section.sNum);
    // Fetch questions for the selected section
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/assessments/${assessmentId}/${encodeURIComponent(themeName)}/${
          section.name
        }/questions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setQuestions(data.questions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    var allCompleted = false;
    console.log("QUESTION FLAG", processedSections);
    setSelectedSection(processedSections[currentSectionIndex]);
    if (selectedSection && selectedSection.questions) {
      console.log("SELECTED SECTION", selectedSection);
      allCompleted = selectedSection.questions.every(
        (question) => question.completion
      );
      setAllQuestionsCompleted(allCompleted);
      console.log("SECTION COMPLETION STATUS", allCompleted);

      // Move the API call here
      if (allCompleted && user) {
        const updateCompletionStatus = async () => {
          console.log("Completion status updated");
          try {
            const config = {
              headers: {
                "Authorization": `Bearer ${user.token}`,
              },
            };
            const sectionToUpdate = processedSections[currentSectionIndex];
            await axios.patch(
              `${
                process.env.REACT_APP_SERVER_URL
              }/api/assessments/completion/${assessmentId}/themes/${encodeURIComponent(
                themeName
              )}/sections/${encodeURIComponent(
                sectionToUpdate.name
              )}/completion`,
              { completion: true },
              config
            );
          } catch (error) {
            console.error("Error updating theme completion:", error);
          }
        };
        updateCompletionStatus();
      }
    }
  }, [selectedSection, processedSections, user, currentSectionIndex]);

  // function to fetch the themes's description
  useEffect(() => {
    const fetchThemeDescription = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/assessments/${assessmentId}/${encodeURIComponent(
            themeName
          )}/description`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received themeDesc:", data);
        setThemeDesc(data);
      } catch (error) {
        console.error("Theme desc fetch error:", error);
      }
    };

    const fetchAssessmentName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched assessments:", data);
        setAssessmentName(data.name);
      } catch (error) {
        console.error("Fetch error mannn:", error);
      }
    };

    if (user && user.assessments) {
      const isAuthorized = user.assessments.some(
        (userAssessment) => userAssessment._id === assessmentId
      );
      setIsUserAuthorizedToEdit(isAuthorized);
    }

    if (user) {
      fetchThemeDescription();
      fetchAssessmentName();
    }
  }, [assessmentId, user]);

  // function to fetch the themes's tnum
  useEffect(() => {
    const fetchThemeTNum = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/assessments/${assessmentId}/${encodeURIComponent(
            themeName
          )}/tnum`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received theme tnum:", data);
        setThemeTNum(data);
      } catch (error) {
        console.error("Theme tnum fetch error:", error);
      }
    };
    if (user) {
      fetchThemeTNum();
    }
  }, [assessmentId, user]);

  // useEffect to fetch the sections for the selected theme and set the first incomplete section as the selected section
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/assessments/${assessmentId}/${encodeURIComponent(
            themeName
          )}/sections`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setSection(data);

        // Processing sections to find the first incomplete section with smallest sNum
        const processedSections = processSectionsData(data);
        const incompleteSections = processedSections.filter(
          (section) => !section.completion
        );

        if (incompleteSections.length > 0) {
          const firstIncompleteSection = incompleteSections.reduce(
            (prev, current) => (prev.sNum < current.sNum ? prev : current)
          );

          handleSectionSelect(firstIncompleteSection); // This will also set 'selectedSection' and 'questions'
          setCurrentSectionIndex(firstIncompleteSection.sNum); // Update current section index
        } else {
          // If no incomplete sections are found, set 'firstIncompleteSection' to the section with the largest sNum
          const smallestSNumSection = processedSections.reduce(
            (prev, current) => (prev.sNum > current.sNum ? prev : current)
          );

          handleSectionSelect(smallestSNumSection); // This will also set 'selectedSection' and 'questions'
          setCurrentSectionIndex(smallestSNumSection.sNum); // Update current section index
        }
      } catch (error) {
        console.error("Section fetch error:", error);
      }
    };

    if (user) {
      fetchSections();
    }
  }, [assessmentId, user]);

  // useEffect to fetch questions for the selected section
  useEffect(() => {
    const fetchQuestions = async () => {
      if (!selectedSection) return;

      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/assessments/${assessmentId}/${encodeURIComponent(themeName)}/${
            selectedSection.name
          }/questions`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setQuestions(data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [selectedSection, assessmentId, themeName]); // Dependencies array

  useEffect(() => {
    if (allQuestionsCompleted) {
      const updatedSections = processedSections.map((sec, index) => {
        if (index === currentSectionIndex) {
          return { ...sec, completion: true };
        }
        return sec;
      });

      setSection({ sections: updatedSections });
    }
  }, [allQuestionsCompleted]);

  const navigateToNextSection = async () => {
    const nextSectionIndex = currentSectionIndex + 1;
    if (nextSectionIndex < processedSections.length) {
      // Navigate to the next section
      setSelectedSection(processedSections[nextSectionIndex]);
      setCurrentSectionIndex(nextSectionIndex);
    }
    window.scrollTo({
      top: 0,
    });
  };

  // Function to navigate to the previous section
  const navigateToPreviousSection = () => {
    if (currentSectionIndex > 0) {
      const prevIndex = currentSectionIndex - 1;
      setCurrentSectionIndex(prevIndex);
      setSelectedSection(processedSections[prevIndex]);
      // No need to setQuestions here as useEffect will handle it
    }
    window.scrollTo({
      top: 0,
    });
  };

  const navigate = useNavigate();

  // Brings you back to the themes page
  const finishTheme = () => {
    navigate(`/assessment/${assessmentId}`);
    window.scrollTo({
      top: 0,
    });
  };

  const handleLogoutClick = () => {
    logout();
  };

  const [lastScrollY, setLastScrollY] = useState(0);
  const [breadcrumbTop, setBreadcrumbTop] = useState("80");
  const [sidebarTop, setSidebarTop] = useState("80px");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // Added threshold to avoid immediate hide
        // Scrolling down
        setBreadcrumbTop("0px");
        setSidebarTop("64px");
      } else {
        // Scrolling up
        setBreadcrumbTop("80px");
        setSidebarTop("156px");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState(!isMobile ? "Progress" : "");

  const tabContainerRef = useRef(null);

  const changeTab = (event, tabName) => {
    setActiveTab(tabName);

    const slider = document.getElementById("slider");
    const tab = event.currentTarget;

    slider.style.width = `${tab.offsetWidth}px`;
    slider.style.left = `${tab.offsetLeft}px`;
  };

  const changeTabMobile = (tabName) => {
    setActiveTab(activeTab === tabName ? "" : tabName);
  };

  const progressButtonRef = useRef(null);

  useEffect(() => {
    // Check if the ref is set and the current property is available
    if (!isMobile && progressButtonRef.current) {
      progressButtonRef.current.click(); // Trigger a click on the Progress button
    }
  }, []); // Empty dependency array to run only once on mount

  console.log("onvariableupdate", updatedVariable);

  return (
    <div
      id="Overall-Container"
      className="flex-col justify-between items-center w-full mx-auto mt-20 pb-32 margin-adaptive"
    >
      <div
        className="fixed top-[80px] transition-all duration-700 ease-in-out z-[54]"
        style={{ top: breadcrumbTop }}
      >
        <div className="">
          <Breadcrumbs assessmentName={assessmentName} />
        </div>
      </div>
      <div className="h-[53px] bg-white"></div>
      <div
        id="Main-Content"
        className="tablet:flex-row mobile:flex-col flex desktop-lg:space-x-20 desktop:space-x-10 tablet:space-x-4 desktop:justify-start tablet:justify-center mt-8"
      >
        <div
          id="Left-content"
          className="flex-col desktop-lg:max-w-[445px] desktop:max-w-[360px] tablet:max-w-[260px] justify-start tablet:border-r border-bordersecondary"
        >
          <div></div>
          <h2 className="text-nafsnBlue custom-16-med mb-1 desktop-lg:mr-8 mobile:mr-4">
            {"Theme " + themeTNum.tnum}
          </h2>
          <h1 className="custom-30 mb-2 text-gray900 desktop-lg:mr-8 mobile:mr-4">
            {themeName}
          </h1>

          <h3 className="custom-16 tablet:mb-8 mobile:mb-4 text-gray600 desktop-lg:mr-8 mobile:mr-4">
            {themeDesc.description}
          </h3>
          {/*  Loading progress*/}
          {/* <div className="py-2  flex items-center gap-[6px]">
            {saveStatus === "saving" ? (
              <img
                src="/assets/loading-icon.png"
                alt="Loading"
                className="h-[20px] w-[20px] animate-spin bg-white"
              />
            ) : (
              <img
                src="/assets/saved-icon.png"
                alt="Saved"
                className="h-[20px] w-[20px] bg-white"
              />
            )}
            <div
              className={`custom-14 text-${
                saveStatus === "saving" ? "gray600" : "gray600"
              }`}
            >
              {saveStatus}
            </div>
          </div> */}

          {/* 
          <button className="normal-button mb-8">
            <img
              src="/assets/compass.png"
              alt="Compass Icon for View Rubric"
              className="w-[20px] h-[20px]"
            />
            <p>View rubric</p>
          </button> */}

          <div
            className="desktop-lg:mr-8 mobile:mr-4 z-2 tablet:sticky transition-all duration-700 ease-in-out"
            style={{ top: sidebarTop }}
          >
            {/* Visible on desktop and tablet but not on mobile */}
            <div className="relative flex flex-col mb-8 w-full tablet:block mobile:hidden">
              <div className="flex w-full gap-4" ref={tabContainerRef}>
                <button
                  ref={progressButtonRef}
                  className={`tab-button ${
                    activeTab === "Progress" ? "active" : ""
                  }`}
                  onClick={(e) => changeTab(e, "Progress")}
                >
                  Progress
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Rubric" ? "active" : ""
                  }`}
                  onClick={(e) => changeTab(e, "Rubric")}
                >
                  Scoring Rubric
                </button>
              </div>
              <div
                id="background-bar"
                className="h-[1px] bg-bordersecondary absolute bottom-0 w-full"
              ></div>
              <div
                id="slider"
                className="h-[2px] bg-nafsnBlue absolute bottom-0 transition-all duration-300 ease-in-out"
              ></div>
            </div>

            {/* Visible on mobile only */}
            <div className="flex flex-row w-full gap-3 mb-4 tablet:hidden">
              <button
                className={` ${
                  activeTab === "Progress" ? "selected-button" : "normal-button"
                }`}
                onClick={(e) => changeTabMobile("Progress")}
              >
                <img
                  src="/assets/progress-icon.png"
                  alt="Progress Icon for View Progress"
                  className="w-[20px] h-[20px]"
                />
                <p>Progress</p>
              </button>
              <button
                className={`${
                  activeTab === "Rubric" ? "selected-button" : "normal-button"
                }`}
                onClick={(e) => changeTabMobile("Rubric")}
              >
                <img
                  src="/assets/compass.png"
                  alt="Compass Icon for View Rubric"
                  className="w-[20px] h-[20px]"
                />
                <p>Scoring rubric</p>
              </button>
            </div>

            {/* Left side displays */}
            {activeTab === "Progress" && (
              <div id="Section Progress Bar" className="">
                <SectionsPreview
                  sections={processedSections}
                  assessmentId={assessmentId}
                  themeName={themeName}
                  onSectionSelect={handleSectionSelect}
                  selectedSection={selectedSection}
                />
              </div>
            )}
            {activeTab === "Rubric" && (
              <div id="Rubric" className="flex flex-col gap-4 custom-14 w-full">
                <div className="flex-row flex">
                  <div className="rubric-tag">N/A</div>

                  <p className="w-5/6 text-gray600">
                    <span className="text-gray900 font-semibold">
                      Does not apply:
                    </span>{" "}
                    This response indicates that the indicator item is
                    irrelevant to your particular community food system. For
                    example, a high-density urban area may not have food
                    production at an acreage scale for agriculture.
                  </p>
                </div>
                <div className="flex-row flex">
                  <div className="rubric-tag">0</div>
                  <p className="w-5/6 text-gray600">
                    <span className="text-gray900 font-semibold">No:</span> Your
                    team is not aware of any current policy, practice, or
                    program in place, no currently active discussion, and no
                    movement toward developing a pertinent policy, practice,
                    program, or investment addressing the topic.
                  </p>
                </div>
                <div className="flex-row flex">
                  <div className="rubric-tag">1</div>
                  <p className="w-5/6 text-gray600">
                    <span className="text-gray900 font-semibold">
                      Being considered:
                    </span>{" "}
                    One or more groups within the community is currently
                    advocating for a pertinent policy, practice, program, or
                    investment regarding this topic, but no formal plan is in
                    place.
                  </p>
                </div>
                <div className="flex-row flex">
                  <div className="rubric-tag">2</div>
                  <p className="w-5/6 text-gray600">
                    <span className="text-gray900 font-semibold">
                      In place:
                    </span>{" "}
                    A pertinent policy, practice, program, or investment
                    addressing the problem or opportunity has been newly adopted
                    or adopted for a year or more without active implementation
                    efforts and/or dedicated funding.
                  </p>
                </div>
                <div className="flex-row flex">
                  <div className="rubric-tag">3</div>
                  <p className="w-5/6 text-gray600">
                    <span className="text-gray900 font-semibold">
                      Being implemented:
                    </span>{" "}
                    A pertinent policy, practice, program, or investment is
                    currently in force with substantial resources allocated to
                    support continuing active steps to implement it effectively
                    and, if in effect for more than a year, it is producing
                    demonstrable results.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          id="Right-Questions"
          className="flex desktop:space-x-6 tablet:justify-center"
        >
          <div id="Questions" className="tablet:max-w-[606px] mobile:w-fill">
            {selectedSection && (
              <div className="flex-col flex tablet:gap-8 mobile:gap-6">
                <div className=" justify-between tablet:flex mobile:hidden">
                  <button
                    className={`normal-button ${
                      currentSectionIndex <= 0
                        ? "opacity-50 cursor-not-allowed desktop:hover:-translate-y-0"
                        : ""
                    }`}
                    onClick={
                      currentSectionIndex > 0
                        ? navigateToPreviousSection
                        : undefined
                    }
                    disabled={currentSectionIndex <= 0}
                  >
                    <img
                      src="/assets/arrow-right.png"
                      alt="Arrow Left"
                      className="w-[20px] h-[20px] transform rotate-180"
                    />
                  </button>
                  {currentSectionIndex < processedSections.length - 1 && (
                    <button
                      className="normal-button"
                      onClick={navigateToNextSection}
                    >
                      <img
                        src="/assets/arrow-right.png"
                        alt="Arrow right"
                        className="w-[20px] h-[20px]"
                      />
                    </button>
                  )}
                  {currentSectionIndex === processedSections.length - 1 && (
                    <button className="normal-button" onClick={finishTheme}>
                      <img
                        src="/assets/exitout.png"
                        alt="Arrow back to themes"
                        className="w-[20px] h-[20px] opacity-70"
                      />
                    </button>
                  )}
                </div>
                {!isUserAuthorizedToEdit && (
                  <div className="warning-banner">
                    <img
                      src="/assets/warning-block.png"
                      alt="Bookmarked"
                      className="w-12 h-12 "
                    />
                    <div>
                      <p className="custom-16-med text-gray700">
                        You do not have edit access to this CARAT assessment
                      </p>
                      <p className="custom-14 text-gray600">
                        If you believe this is an error, try log out and log
                        back in. Otherwise, please reach out to the owner(s) of
                        the assessment for access.
                      </p>
                    </div>
                  </div>
                )}
                {/* for testing purposes {<h2 className="custom-font-tag mb-4">{selectedSection.name}</h2>} */}
                <Questions
                  questions={questions}
                  assessmentId={assessmentId}
                  updatedVariable={updatedVariable}
                  onVariableUpdate={handleVariableUpdate}
                  themeName={themeName}
                  onSectionUpdate={handleSectionsUpdate}
                  selectedSection={selectedSection}
                />

                <div className="flex justify-end space-x-2">
                  {currentSectionIndex > 0 && (
                    <button
                      className="pop-up-cancel"
                      onClick={navigateToPreviousSection}
                    >
                      Previous
                    </button>
                  )}
                  {currentSectionIndex < processedSections.length - 1 && (
                    <button
                      className="pop-up-cta"
                      onClick={navigateToNextSection}
                    >
                      Continue
                    </button>
                  )}
                  {currentSectionIndex === processedSections.length - 1 && (
                    <button onClick={finishTheme} className="pop-up-cta">
                      Finish Theme
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sections;
