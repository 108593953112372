import React, { useState, useEffect, useRef } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const EditCollaboratorsForm = ({
  assessmentId,
  onClose,
  onAssessmentUpdate,
  assessmentCollaborators,
}) => {
  const { dispatch } = useAssessmentsContext();
  const { user, dispatch: authDispatch } = useAuthContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [error, setError] = useState(null);
  const [collaboratorError, setCollaboratorError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [descError, setDescError] = useState(null);
  const modalRef = useRef();
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const fetchUserEmailById = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/email`
      );
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Error fetching user email");
      }
      return json.email;
    } catch (error) {
      console.error("Failed to fetch user email:", error);
    }
  };

  const fetchUserInfoByEmail = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${email}`
      );
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "User not found");
      }
      return json;
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || "Error fetching assessment");
        }
        setName(data.name);
        setDescription(data.description);

        // Reordering the collaborators to ensure user is first
        setCollaborators(
          assessmentCollaborators
            .filter((c) => c)
            .sort((a, b) => {
              if (a.email === user.email) return -1;
              if (b.email === user.email) return 1;
              return 0;
            })
        );
      } catch (error) {
        setError("Failed to load assessment details");
      }
    };

    fetchAssessmentDetails();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assessmentId]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameError("Please enter a name for the assessment");
      return;
    }

    if (!description) {
      setDescError("Please enter a short description for the assessment");
      return;
    }

    try {
      const collaboratorIds = collaborators.map((c) => c.id);
      const updatedAssessment = {
        name,
        description,
        user_ids: collaboratorIds,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
        {
          method: "PATCH",
          body: JSON.stringify(updatedAssessment),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );

      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Failed to update assessment");
      }

      // Notify the parent component (Themes) about the update
      const updatedDataForParent = {
        name: name,
        description: description,
        collaborators: collaborators, // Make sure this is the correct data format expected by the parent
      };
      onAssessmentUpdate(updatedDataForParent);

      dispatch({ type: "UPDATE_ASSESSMENT", payload: json });
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddCollaborator = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop the event from bubbling up

    if (collaboratorEmail === user.email) {
      setCollaboratorError("You are already a collaborator");
      return;
    }

    const isDuplicate = collaborators.some(
      (c) => c.email === collaboratorEmail
    );
    if (isDuplicate) {
      setCollaboratorError("Collaborator already added");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${collaboratorEmail}`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error || "User not found");
      }

      setCollaborators((prev) => [...prev, json]);
      setCollaboratorEmail("");
    } catch (error) {
      setCollaboratorError(error.message);
    }
  };

  const handleRemoveCollaborator = (collaboratorId) => {
    setCollaborators(collaborators.filter((c) => c.id !== collaboratorId));
  };

  const addAssessmentToUser = async (collaboratorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${collaboratorId}/add-assessment`, // Corrected endpoint
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
          body: JSON.stringify({ assessmentId }), // Correctly structured body
        }
      );

      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Failed to make user an editor");
      }

      // Logic to update local state or context to reflect the changes
    } catch (error) {
      console.error("Error making user an editor:", error);
      // Handle error (e.g., set error state or show notification)
    }
  };

  // Render form with pre-filled values
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[70] ">
      <div
        ref={modalRef}
        className="w-[500px] bg-white rounded-lg shadow m-2 desktop:py-9 mobile:py-4"
      >
        <div className="desktop:px-9 mobile:px-4">
          {" "}
          <h1 className="custom-18-semi text-gray900 mb-1">
            Edit collaborators
          </h1>
          <p className="text-gray700 custom-14 mb-5">
            All collaborators will be able to view this assessment, but only
            editors can modify and delete it.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="flex-col space-y-6">
          <div className="flex-col desktop:px-9 mobile:px-4">
            <div className="flex items-end">
              <div className="flex-grow mr-2">
                <label className="block mb-2 custom-14-med text-gray700">
                  Email
                </label>
                <input
                  type="text"
                  className="input-field"
                  onChange={(e) => setCollaboratorEmail(e.target.value)}
                  value={collaboratorEmail}
                  placeholder="Enter their email"
                />
              </div>
              <button
                onClick={handleAddCollaborator}
                className={`${
                  collaboratorEmail ? "blue-button" : "normal-button"
                } `}
              >
                Add
              </button>
            </div>
            {collaboratorError && (
              <div className="text-error custom-functional mt-1">
                {collaboratorError}
              </div>
            )}
          </div>

          {error && <div className="text-red-500 mb-5">{error}</div>}

          <div id="CollabDivider" className="flex items-center gap-2">
            <div className="h-[1px] flex-1 bg-borderprimary"></div>
            <h3 className="text-gray600 custom-14-med">
              Current collaborators: [{collaborators.length}]
            </h3>
            <div className="h-[1px] flex-1 bg-borderprimary"></div>
          </div>

          <div className="h-[200px] overflow-y-auto flex flex-col	">
            {collaborators.map((collaborator) => {
              // Check if the collaborator has the current assessment in their assessments list
              const hasAccess = collaborator.assessments.includes(assessmentId);
              const accessLevel = hasAccess
                ? "Editing Access"
                : "Viewing Access";

              // Get initials
              const initials = `${collaborator.firstName[0]}${collaborator.lastName[0]}`;

              console.log("collaborator", collaborator);

              return (
                <div
                  key={collaborator.id}
                  className="flex items-center justify-between hover:bg-bghover rounded-lg desktop:px-9 mobile:px-4 py-3"
                >
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-nafsnBlue flex items-center justify-center mr-3 text-white custom-14-semi">
                      {initials}
                    </div>
                    <div>
                      <div className="text-gray700 custom-14-med">
                        {`${collaborator.firstName} ${collaborator.lastName}`}{" "}
                        {collaborator.email === user.email ? " (you)" : ""}
                      </div>
                      <div className="text-gray700 custom-14">
                        {accessLevel}
                      </div>
                    </div>
                  </div>
                  {!hasAccess && collaborator.email !== user.email && (
                    <div className="relative flex flex-row gap-2">
                      <button
                        onClick={() => {
                          if (collaborator.email !== user.email) {
                            handleRemoveCollaborator(collaborator.id);
                          }
                        }}
                        className="small-button"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => addAssessmentToUser(collaborator.id)}
                        className="small-button"
                      >
                        Make Editor
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-8 flex justify-end space-x-2 desktop:px-9 mobile:px-4">
            <button className="pop-up-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="pop-up-cta" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCollaboratorsForm;
