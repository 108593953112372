import React, { useState, useEffect, useRef } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const EditAssessmentForm = ({
  assessmentId,
  onClose,
  onAssessmentUpdate,
  assessmentCollaborators,
}) => {
  const { dispatch } = useAssessmentsContext();
  const { user } = useAuthContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [error, setError] = useState(null);
  const [collaboratorError, setCollaboratorError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [descError, setDescError] = useState(null);
  const modalRef = useRef();

  const fetchUserEmailById = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/email`
      );
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Error fetching user email");
      }
      return json.email;
    } catch (error) {
      console.error("Failed to fetch user email:", error);
    }
  };

  const fetchUserInfoByEmail = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${email}`
      );
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "User not found");
      }
      return json;
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || "Error fetching assessment");
        }
        setName(data.name);
        setDescription(data.description);

        // Reordering the collaborators to ensure user is first
        setCollaborators(
          assessmentCollaborators
            .filter((c) => c)
            .sort((a, b) => {
              if (a.email === user.email) return -1;
              if (b.email === user.email) return 1;
              return 0;
            })
        );
      } catch (error) {
        setError("Failed to load assessment details");
      }
    };

    fetchAssessmentDetails();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assessmentId]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameError("Please enter a name for the assessment");
      return;
    }

    if (!description) {
      setDescError("Please enter a short description for the assessment");
      return;
    }

    try {
      const collaboratorIds = collaborators.map((c) => c.id);
      const updatedAssessment = {
        name,
        description,
        user_ids: collaboratorIds,
      };
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
        {
          method: "PATCH",
          body: JSON.stringify(updatedAssessment),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );

      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Failed to update assessment");
      }

      // Notify the parent component (Themes) about the update
      const updatedDataForParent = {
        name: name,
        description: description,
        collaborators: collaborators, // Make sure this is the correct data format expected by the parent
      };
      onAssessmentUpdate(updatedDataForParent);

      dispatch({ type: "UPDATE_ASSESSMENT", payload: json });
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddCollaborator = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop the event from bubbling up

    if (collaboratorEmail === user.email) {
      setCollaboratorError("You are already a collaborator");
      return;
    }

    const isDuplicate = collaborators.some(
      (c) => c.email === collaboratorEmail
    );
    if (isDuplicate) {
      setCollaboratorError("Collaborator already added");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${collaboratorEmail}`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error || "User not found");
      }

      setCollaborators((prev) => [...prev, json]);
      setCollaboratorEmail("");
    } catch (error) {
      setCollaboratorError(error.message);
    }
  };

  const handleRemoveCollaborator = (collaboratorId) => {
    setCollaborators(collaborators.filter((c) => c.id !== collaboratorId));
  };

  // Render form with pre-filled values
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[70]">
      <div
        ref={modalRef}
        className="w-[500px] desktop:p-9 mobile:p-4 bg-white rounded-lg shadow m-2"
      >
        <h1 className="custom-18-semi text-gray900 mb-4">Edit assessment</h1>
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={handleSubmit} className="flex-col space-y-6">
          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Assessment Name:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setName(e.target.value);
                if (nameError) setNameError(null);
              }}
              value={name}
              placeholder={name || "Enter a name for the assessment"}
            />
            {nameError && (
              <div className="text-error custom-functional mt-1">
                {nameError}
              </div>
            )}
          </div>

          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Short Description:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setDescription(e.target.value);
                if (descError) setDescError(null);
              }}
              value={description}
              placeholder={description || "Enter a short description"}
            />
            {descError && (
              <div className="text-error custom-functional mt-1">
                {descError}
              </div>
            )}
          </div>

          <div className="flex-col">
            <div className="flex items-end">
              <div className="flex-grow mr-2">
                <label className="block mb-2 custom-14-med text-gray700">
                  Collaborators:
                </label>
                <input
                  type="text"
                  className="input-field"
                  onChange={(e) => setCollaboratorEmail(e.target.value)}
                  value={collaboratorEmail}
                  placeholder="Enter their email"
                />
              </div>
              <button
                onClick={handleAddCollaborator}
                className={`${
                  collaboratorEmail ? "blue-button" : "normal-button"
                } `}
              >
                Add
              </button>
            </div>
            <div className="pt-1">
              {collaboratorError === "User not found" && (
                <div id="error" className="text-error custom-14">
                  {collaboratorError}. Please encourage your team members to
                  each make their own account on CARAT. You will be able to add
                  them as collaborators after they've established their own free
                  accounts.
                </div>
              )}
              {collaboratorError !== "User not found" &&
                collaboratorError !== null && (
                  <div id="error" className="text-error custom-14">
                    {"Please enter add a valid collaborator"}
                  </div>
                )}
            </div>
          </div>

          <div>
            <div id="CollabDivider" className="flex items-center gap-2">
              <div className="h-[1px] flex-1 bg-clearWater"></div>
              <h3 className="text-gray600 custom-14-med">
                Current collaborators: [{collaborators.length}]
              </h3>
              <div className="h-[1px] flex-1 bg-bordersecondary"></div>
            </div>

            <div className="text-gray700 custom-14-med mt-4">
              {collaborators.map((collaborator, index) => (
                <span
                  key={collaborator.id}
                  className="relative inline-block mr-2"
                >
                  <span
                    className={`${
                      collaborator.email === user.email
                        ? "cursor-default"
                        : "hover:text-red-500 hover:line-through cursor-pointer"
                    }`}
                    onClick={() => {
                      if (collaborator.email !== user.email) {
                        handleRemoveCollaborator(collaborator.id);
                      }
                    }}
                  >
                    {`${collaborator.firstName} ${collaborator.lastName}`}
                    {collaborator.email === user.email ? " (you)" : ""}
                  </span>
                  {index < collaborators.length - 1 && ", "}
                </span>
              ))}
            </div>
          </div>

          <div className="mt-8 flex justify-end space-x-2">
            <button className="pop-up-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="pop-up-cta" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAssessmentForm;
