import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useAuthContext } from "./useAuthContext";
import { useAssessmentsContext } from "./useAssessmentsContext";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { user, dispatch } = useAuthContext();
  const { dispatch: assessmentsDispatch } = useAssessmentsContext();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user ? user.token : null;
    console.log("Token from user in localStorage:", token);

    if (token) {
      const decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);
      const isExpired = isTokenExpired(token);
      console.log("Is Token Expired:", isExpired);

      if (!isExpired) {
        setIsAuthenticated(true);
        scheduleLogout(token);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [user]);

  const isTokenExpired = (token) => {
    try {
      const { exp } = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return exp < currentTime;
    } catch {
      return true;
    }
  };

  const scheduleLogout = (token) => {
    const { exp } = jwtDecode(token);
    const currentTime = Date.now();
    const delay = exp * 1000 - currentTime;
    setTimeout(() => {
      logoutUser();
    }, delay);
  };

  const logoutUser = () => {
    // remove user from storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    // dispatch logout actions
    dispatch({ type: "LOGOUT" });
    assessmentsDispatch({ type: "SET_ASSESSMENTS", payload: null });

    setIsAuthenticated(false);
    window.location.href = "/login";
  };

  console.log("isAuthenticated: ", isAuthenticated);

  return {
    isAuthenticated,
    logoutUser,
  };
};

export default useAuth;
