import React, { useState, useEffect } from "react";

const DefinitionsComponent = () => {
  const [opDefs, setOpDefs] = useState([]);

  // Fetch operational definitions from the backend
  const fetchOpDefs = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/opdefs`,
        {}
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setOpDefs(data);
    } catch (error) {
      console.error("Fetch operational definitions failed:", error);
    }
  };

  useEffect(() => {
    fetchOpDefs();
  }, []);
  return (
    <div className="reading-overall-container">
      <div id="operational definitions" className="reading-sections-container">
        {/* List of operational definitions */}
        <div>
          {opDefs
            .sort((a, b) => a.Term.localeCompare(b.Term))
            .map((opDef) => (
              <div
                key={opDef._id}
                className="mb-4 p-4 border border-bordersecondary rounded-lg"
              >
                <div className="mb-2">
                  <strong>Term:</strong> {opDef.Term}
                </div>
                <div className="mb-2">
                  <strong>Definition:</strong> {opDef.Definition}
                </div>
                <div className="mb-4">
                  <strong>Details:</strong> {opDef.Details}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DefinitionsComponent;
