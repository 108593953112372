import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import IndicatorSection from "../overviewreportcomponents/IndicatorSection";
import ThemeReportSection from "../overviewreportcomponents/ThemeReportSection";
import { useAuthContext } from "../hooks/useAuthContext";
import { useIsMobile } from "../hooks/useIsMobile";

import axios from "axios";

const ThemeBarGraph = ({ themes, onThemeSelect, selectedTheme, isMobile }) => {
  const yAxisLabels = [20, 40, 60, 80, 100];

  // set chartheight to 350px if mobile else 400px
  const chartHeight = isMobile ? 240 : 340;
  const yAxisHeight = isMobile ? 240 : 340;
  const themeNames = isMobile ? "T" : "Theme";

  return (
    <div className="flex items-end h-full gap-4 mt-4">
      {/* Y-axis */}
      <div
        className="flex flex-col-reverse items-end mr-2 mb-[26px]"
        style={{ height: `${yAxisHeight}px` }}
      >
        <p className="text-right custom-12 text-gray600">0%</p>
        {yAxisLabels.map((label) => (
          <div
            key={label}
            className="h-[25%] text-right custom-12 text-gray600"
          >
            {label}%
          </div>
        ))}
      </div>

      {/* Themes and bars */}
      <div className="flex justify-around items-end flex-grow h-full">
        {themes.map((theme) => {
          let themeScore = 0;
          let themeMaxScore = 0;

          theme.sections.forEach((section) => {
            section.questions.forEach((question) => {
              if (question.answer !== null && question.answer !== 5) {
                themeScore += question.answer - 1;
                themeMaxScore += 3;
              }
            });
          });

          const completionPercentage = Math.round(
            (themeScore / themeMaxScore) * 100
          );
          const barHeight = chartHeight * (completionPercentage / 100); // Calculate bar height as a percentage of chartHeight
          const barColor = selectedTheme === theme ? "#185481" : "#D5DDE5"; // Color based on selected theme

          return (
            <div
              key={theme.name}
              className="text-center flex flex-col items-center tablet:mx-1 mobile:mx-0.5 flex-grow group"
            >
              <div
                className={`w-full rounded-t-md transition-all duration-300 ease-in-out ${
                  selectedTheme === theme
                    ? "bg-nafsnBlue group-hover:bg-nafsnBlue"
                    : "bg-gray200 group-hover:bg-gray300 cursor-pointer"
                }`}
                style={{ height: `${barHeight}px` }}
                onClick={() => onThemeSelect(theme)}
              ></div>

              <div
                className={`w-full h-full rounded custom-12 mt-2 cursor-pointer transition-all duration-300 ease-in-out ${
                  selectedTheme === theme
                    ? "text-nafsnBlue group-hover:text-nafsnBlue bg-bghoverblue"
                    : " text-gray900 group-hover:text-gray600 tablet:group-hover:bg-bghoverblue bg-bghover"
                }`}
                onClick={() => onThemeSelect(theme)}
              >
                {themeNames} {theme.tNum}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProgressBar = ({
  completionPercentage,
  showAllIndicators,
  isMobile,
  onClick,
}) => {
  const radius = isMobile ? 100 : 100; // Adjust radius based on mobile or desktop
  const strokeWidth = isMobile ? 20 : 20; // Adjust stroke width based on mobile or desktop
  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - (completionPercentage / 100) * circumference;

  const rotation = -90;
  const rotationTransform = `rotate(${rotation} ${radius} ${radius})`;
  const strokeColor = showAllIndicators ? "#185481" : "#D5DDE5";

  return (
    <div
      className={`relative flex justify-center items-center ${
        isMobile ? "w-[200px] h-[200px]" : "w-[200px] h-[200px]"
      } ${
        showAllIndicators
          ? ""
          : "cursor-pointer hover:scale-103 active:scale-95"
      } transition-all duration-300`}
      onClick={onClick}
    >
      <svg height={isMobile ? 200 : 200} width={isMobile ? 200 : 200}>
        <circle
          stroke="#EAECF0"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          strokeLinecap="round"
          transform={rotationTransform}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          className="transition-all duration-300 ease-in-out"
        />
      </svg>
      <span
        className={`absolute ${
          isMobile ? "custom-24" : "custom-30"
        } font-semibold text-gray900`}
      >
        {`${completionPercentage}%`}
      </span>
    </div>
  );
};

const OverviewReport = () => {
  const { assessmentId } = useParams(); // Extract the assessment ID from the URL
  const { user } = useAuthContext();
  const [assessment, setAssessment] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [showAllIndicators, setShowAllIndicators] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [breadcrumbTop, setBreadcrumbTop] = useState("80");
  const [readyToPrint, setReadyToPrint] = useState(false);

  const isMobile = useIsMobile();

  console.log("assessmentID", assessmentId);

  useEffect(() => {
    if (readyToPrint) {
      // Ensuring the component updates are rendered before printing
      window.print();
      setReadyToPrint(false); // Reset the flag to avoid re-printing on subsequent renders
    }
  }, [readyToPrint]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // Added threshold to avoid immediate hide
        // Scrolling down
        setBreadcrumbTop("0px");
      } else {
        // Scrolling up
        setBreadcrumbTop("80px");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    if (user) {
      fetchBookmarks(user.id);
    }
  }, [user]);

  useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        setAssessment(data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    if (user) {
      fetchAssessment();
    }
    console.log("assessment in fetch", assessment);
  }, [assessmentId, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!assessment) {
    return <div>No assessment data found.</div>;
  }

  const handleViewAllIndicators = () => {
    setSelectedTheme(null);
    setShowAllIndicators(true);
  };
  const prepareForPrint = () => {
    setShowAllIndicators(true);
    setSelectedTheme(null);

    // This will indirectly trigger the useEffect below
    setReadyToPrint(true);
  };

  // Function to calculate the total resiliency score and other metrics
  const calculateScores = () => {
    let totalScore = 0;
    let totalMaxScore = 0;
    let numericAnswers = 0;
    let notApplicableCount = 0;
    assessment.themes.forEach((theme) => {
      theme.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.answer !== null) {
            if (question.answer !== 5) {
              totalScore += question.answer - 1; // As max is 3 (4-1)
              numericAnswers++;
              totalMaxScore += 3; // Add to max score only if not N/A
            } else {
              notApplicableCount++;
            }
          }
        });
      });
    });
    return {
      totalScore,
      totalMaxScore,
      numericAnswers,
      notApplicableCount,
    };
  };

  console.log("assessment before const", assessment);
  const { totalScore, totalMaxScore, numericAnswers, notApplicableCount } =
    calculateScores();
  // Calculate the overall completion percentage
  const overallCompletionPercentage = Math.round(
    (totalScore / totalMaxScore) * 100
  );

  // Function declarations
  async function fetchBookmarks(userId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/bookmarks`
      );
      // Check if the response is an array
      if (Array.isArray(response.data)) {
        setBookmarks(response.data);
      } else {
        // If not an array, log an error or set bookmarks to an empty array
        console.error(
          "Expected an array for bookmarks, received:",
          response.data
        );
        setBookmarks([]);
      }
    } catch (error) {
      console.error("Error fetching bookmarks", error);
      // In case of error, also set bookmarks to an empty array
      setBookmarks([]);
    }
  }

  async function handleBookmark(resource, assessmentName) {
    const userId = user.id;
    // Ensure bookmarks is an array
    if (!Array.isArray(bookmarks)) {
      console.error("Bookmarks is not an array");
      return;
    }

    const isBookmarked = bookmarks.some(
      (bookmark) => bookmark._id === resource._id
    );

    try {
      if (isBookmarked) {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/bookmarks/${resource._id}`
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/bookmarks`,
          { resource, assessmentName }
        );
      }
      fetchBookmarks(userId);
    } catch (error) {
      console.error("Error updating bookmark", error);
    }
  }

  const findLowestScoringTheme = () => {
    let lowestScore = Infinity;
    let lowestScoringTheme = "";

    assessment.themes.forEach((theme) => {
      let themeScore = 0;
      let themeMaxScore = 0;

      theme.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.answer !== null && question.answer !== 5) {
            themeScore += question.answer - 1;
            themeMaxScore += 3;
          }
        });
      });

      const themeCompletionPercentage = themeScore / themeMaxScore;

      if (themeCompletionPercentage < lowestScore) {
        lowestScore = themeCompletionPercentage;
        lowestScoringTheme = theme.name;
      }
    });

    return lowestScoringTheme;
  };

  const lowestScoringTheme = findLowestScoringTheme();

  return (
    <div
      id="Overall-Container"
      className="justify-between items-center w-full mx-auto print:mt-0 mt-20 pb-32 margin-adaptive"
    >
      <div
        className="fixed top-[80px] transition-all duration-700 ease-in-out z-[54]"
        style={{ top: breadcrumbTop }}
      >
        <Breadcrumbs assessmentName={assessment.name} />
      </div>
      <div className="h-[53px] bg-white print:hidden"></div>
      <div id="Main-Content" className="flex-col flex gap-8">
        <div className="flex flex-col gap-6 mt-6" div id="Intro-content">
          <div
            id="Overview-section"
            className="flex tablet:flex-row mobile:flex-col mobile:gap-4 tablet:gap-0 justify-between tablet:items-end  w-full"
          >
            <div>
              <h3 className="text-nafsnBlue custom-16-semi mb-1">
                CARAT Report
              </h3>
              <h3 className="custom-30 text-gray900 mb-2">{assessment.name}</h3>
              <h3 className="custom-16 text-stone">{assessment.description}</h3>
            </div>
            <div className="flex flex-row tablet:gap-3 mobile:gap-2">
              <button
                className="normal-button flex-shrink-0 print:hidden"
                onClick={prepareForPrint}
              >
                <img
                  src="/assets/print-icon.png"
                  alt="Edit Icon"
                  className="w-[20px] h-[20px]"
                />
                <p>Print</p>
              </button>
              <button
                className="normal-button flex-shrink-0 print:hidden"
                onClick={() =>
                  (window.location.href = `/assessment/${assessment._id}`)
                }
              >
                <img
                  src="/assets/assessment-icon.png"
                  alt="Edit Icon"
                  className="w-[20px] h-[20px]"
                />
                <p className="text-nafsnBlue">Review</p>
              </button>
            </div>
          </div>
          <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
        </div>

        <div id="Assessment overview" className="flex-col">
          <div className="flex-col flex space-y-4">
            <div
              id="assessment data"
              className="flex justify-between desktop:flex-row mobile:flex-col gap-6"
            >
              <div className="border rounded-lg border-bordersecondary shadow-sm tablet:min-w-[360px]">
                <div className="px-5 py-6 flex flex-col gap-1">
                  <h3 className="custom-18-semi text-gray900">
                    Overall resilience score
                  </h3>
                  <p className="custom-14 text-gray600">
                    {numericAnswers} answered indicators, {notApplicableCount}{" "}
                    not applicable
                  </p>
                </div>
                <div
                  id="Divider"
                  className="h-[1px] w-full bg-bordersecondary"
                ></div>
                <div className="p-6 flex flex-col gap-8 items-center">
                  <ProgressBar
                    completionPercentage={overallCompletionPercentage}
                    showAllIndicators={showAllIndicators}
                    isMobile={isMobile}
                    onClick={handleViewAllIndicators}
                  />
                  <div className="flex flex-col gap-1">
                    <p className="custom-16-med text-gray900">
                      Your score breakdown: {totalScore}/{totalMaxScore}
                    </p>
                    <p className="custom-14 text-gray600">
                      Your lowest scoring theme is {lowestScoringTheme}
                    </p>
                  </div>
                </div>
                <div
                  id="Divider"
                  className="h-[1px] w-full bg-bordersecondary print:hidden"
                ></div>
                <div className="px-6 py-4 w-full ">
                  <button
                    onClick={handleViewAllIndicators}
                    className={`${
                      showAllIndicators ? "secondary-button" : "normal-button"
                    } ml-auto print:hidden`}
                  >
                    View All Indicators
                  </button>
                </div>
              </div>
              <div className="print-page-break flex flex-col align-bottom p-6 w-full border rounded-lg border-bordersecondary shadow-sm ">
                <div>
                  <h3 className="custom-18-semi text-gray900">
                    Individual theme ratings
                  </h3>
                  <p className="custom-14 text-gray600 print:hidden">
                    Click on each bar to view the detailed view of the theme
                  </p>
                </div>
                <ThemeBarGraph
                  themes={assessment.themes}
                  onThemeSelect={(theme) => {
                    setSelectedTheme(theme);
                    setShowAllIndicators(false);
                  }}
                  selectedTheme={selectedTheme}
                  isMobile={isMobile}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="Divider"
          className="h-[1px] w-full bg-bordersecondary print:hidden"
        ></div>
      </div>

      {showAllIndicators && (
        <IndicatorSection
          assessment={assessment}
          bookmarks={bookmarks}
          handleBookmark={handleBookmark}
        />
      )}
      <ThemeReportSection
        theme={selectedTheme}
        assessment={assessment}
        bookmarks={bookmarks}
        handleBookmark={handleBookmark}
      />
    </div>
  );
};

export default OverviewReport;
