import React, { useState, useEffect } from "react";

const sections = ["interpretation"];

const HowToInterpretScores = () => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div id="interpretation" className="reading-sections-container">
          <div className="reading-subsections-container">
            <h1 className="custom-display text-gray900">
              How to Interpret Your Scores
            </h1>
            <p className="custom-18 text-gray600">
              The scores generated by the CARAT tool are designed to give you a
              comprehensive overview of the current structure of your
              community’s food system and its relationship to resilience. This
              includes areas of relative strength as well as areas that may
              require additional attention and improvements.
            </p>
          </div>
          <p className="custom-18 text-gray600">
            To help you discuss potential action steps after reviewing your
            community’s scores, we have created a supplemental reference called{" "}
            <a
              href="https://docs.google.com/document/d/1WzuuYXPqL2LqLuV34QmP8oVuo846VmUvIKdcFEcp8Yk/edit"
              className="text-nafsnBlue underline"
            >
              the CARAT Resources Archive
            </a>
            . This collection, which includes funding opportunities, services,
            and model programs, may help your community make practical,
            actionable plans once gaps have been identified. The online version
            of CARAT automatically generates relevant resources based on your
            scores.
          </p>
          <p className="custom-18 text-gray600">
            Above all, however, CARAT is a self-assessment tool, and we believe
            that progress must come from within your own community.
            Fundamentally, community resilience is built on the combined
            strengths of its individuals. Encourage your working group to
            connect stakeholders from different fields and backgrounds and
            engage the skill sets and experiences of the members in your
            community. There is a lot of potential in your community, and we
            hope that the CARAT tool can help you better recognize and make use
            of the assets and strengths already present in your community and
            create new connections and networks across traditionally siloed
            groups.
          </p>
          <p className="custom-18 text-gray600">
            For further information or assistance, NAFSN invites you to{" "}
            <a
              className="text-nafsnBlue underline"
              href="mailto:info@foodsystemsnetwork.org"
            >
              contact us
            </a>
            . NAFSN is a professional association of people working together to
            strengthen local and regional food systems. Through our network, we
            strive to help strengthen yours.
          </p>
        </div>
      </div>
      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[108px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToInterpretScores;
