import React from "react";
import { Link } from "react-router-dom";
// You'll need to import your logo SVG and C icon SVG here, replace the paths accordingly
// import { ReactComponent as YourLogo } from 'path-to-your-logo.svg';
// import { ReactComponent as CIcon } from 'path-to-your-c-icon.svg';

const Footer = () => {
  return (
    <div className="bg-[#0C111D] pt-16 pb-16  w-full">
      <div className="margin-adaptive mx-auto">
        <div className="flex desktop:flex-row mobile:flex-col gap-36">
          <div className="desktop:w-2/5 flex flex-col gap-4">
            <Link
              to="https://foodsystemsnetwork.org/"
              className="flex items-center"
            >
              <img
                src="/assets/nafsn-white-logo.png"
                alt="NAFSN Logo"
                className="w-[160px]"
              />
            </Link>
            <p className="text-gray400 custom-16">
              {" "}
              NAFSN is sponsored by the Thomas A. Lyson Center for Civic
              Agriculture and Food Systems, a project of the Center for
              Transformative Action (a 501(c)(3) nonprofit affiliate of Cornell
              University).
            </p>
          </div>

          <div className="flex desktop:flex-row mobile:flex-grid justify-between desktop:w-3/5">
            <div>
              <p className="custom-14-med text-gray500 mb-4">Instructions</p>
              <ul>
                <li>
                  <a
                    href="/guide/introduction"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Introduction
                  </a>
                </li>

                <li>
                  <a
                    href="/guide/preparing-to-use-carat"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Preparation
                  </a>
                </li>
                <li>
                  <a
                    href="/guide/scoring-the-carat-tool"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Scoring
                  </a>
                </li>
                <li>
                  <a
                    href="/guide/how-to-interpret-your-scores"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Interpretation
                  </a>
                </li>
              </ul>
            </div>

            {/* Column 3 */}
            <div>
              <p className="custom-14-med text-gray500 mb-4">Developers</p>
              <ul>
                <li>
                  <a
                    href="/guide/notes-from-the-developers"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Notes from us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.foodsystemsnetwork.org/carat_working_group.php"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Meet the developers
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.foodsystemsnetwork.org/contact.php"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>

            {/* Column 4 */}
            <div>
              <p className="custom-14-med text-gray500 mb-4">Appendix</p>
              <ul>
                <li>
                  <a
                    href="https://docs.google.com/document/d/13iM5lSGG3B_pFJ2rgerPORcLZRqYherlY4CtRxVARoY/edit?usp=sharing"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    CARAT Resources
                  </a>
                </li>
                <li>
                  <a
                    href="/guide/operational-definitions"
                    className="block hover:underline custom-16-med text-gray400 pb-3"
                  >
                    Operational Definitions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <p className="custom-16 text-gray400">
            © 2024 CARAT. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
