import React, { useState, useEffect } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";

const AssessmentPreview = ({ assessment }) => {
  const { dispatch: assessmentDispatch } = useAssessmentsContext();
  const { user, dispatch: authDispatch } = useAuthContext();
  const [assessmentCollaborators, setAssessmentCollaborators] = useState([]);
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [isUserAuthorizedToDelete, setIsUserAuthorizedToDelete] =
    useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  // useEffect to fetch user names when component mounts or when assessment changes
  useEffect(() => {
    // Check if the logged-in user has this assessment in their assessments array
    if (user && user.assessments) {
      const isAuthorized = user.assessments.some(
        (assess) => assess._id === assessment._id
      );
      setIsUserAuthorizedToDelete(isAuthorized);
    }

    const fetchCollaborators = async () => {
      const collaborators = [];

      // Loop through each userId in assessment.user_ids
      for (const userId of assessment.user_ids) {
        // Fetch the user
        const userResponse = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/user/id/${userId}`,
          {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        // Check if user response is ok
        if (userResponse.ok) {
          const collaboratorData = await userResponse.json();

          collaborators.push(collaboratorData);
        }
      }

      // Set the fetched names to state variables
      setAssessmentCollaborators(collaborators);
    };

    fetchCollaborators();
  }, [user, assessment]);

  const deleteAssessmentButton = async () => {
    setShowConfirmationPopUp(false); // Hide confirmation pop-up
    if (!user) {
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/assessments/` + assessment._id,
      {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${user.token}`,
        },
      }
    );

    const json = await response.json();

    if (response.ok) {
      assessmentDispatch({ type: "DELETE_ASSESSMENT", payload: json });

      // Update local state and context
      const updatedAssessments = user.assessments.filter(
        (a) => a._id !== assessment._id
      );
      const updatedUser = { ...user, assessments: updatedAssessments };

      // Dispatch to update auth context
      authDispatch({
        type: "UPDATE_USER",
        payload: updatedUser,
      });

      // Make PATCH request to update user's assessments in the backend
      await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/id/${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            assessments: [assessment._id],
            operation: "remove",
          }),
        }
      );

      console.log("Assessment deleted successfully", updatedAssessments);
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmationPopUp(true); // Show confirmation pop-up
  };

  const cancelDelete = () => {
    setShowConfirmationPopUp(false); // Hide confirmation pop-up
  };

  // Function to calculate completion percentage based on questions
  const calculateCompletionPercentage = () => {
    let totalQuestions = 0;
    let completedQuestions = 0;

    assessment.themes.forEach((theme) => {
      theme.sections.forEach((section) => {
        section.questions.forEach((question) => {
          totalQuestions++;
          if (question.completion) {
            completedQuestions++;
          }
        });
      });
    });

    return totalQuestions > 0 ? (completedQuestions / totalQuestions) * 100 : 0;
  };

  const completionPercentage = calculateCompletionPercentage();

  // Determine the color based on completion percentage
  const barColor = completionPercentage === 100 ? "bg-sprout" : "bg-sprout";

  // Define the width of the progress bar
  const progressBarWidth = { width: `${completionPercentage}%` };

  return (
    <div
      className={`bg-white overflow-hidden rounded-lg shadow mobile:border ${
        assessment.completion ? "border-borderprimary" : "border-borderprimary"
      } ${
        !showConfirmationPopUp &&
        "group transition-all duration-300 desktop:hover:scale-102 ease-in-out desktop:hover:shadow"
      }`}
    >
      <div className="p-6 gap-4 flex-col flex">
        <div className="flex justify-between items-center ">
          <div className="flex gap-3 items-center bg-slate900 w-full ">
            <img
              src="/assets/assessment-icon.png"
              alt="Edit Icon"
              className="w-[24px] h-[24px]"
            />
            <div className="desktop:max-w-[240px] tablet:max-w-[400px] mobile:max-w-[200px]">
              <h4 className="custom-16-med text-gray900 truncate">
                {assessment.name}
              </h4>
              <p className="custom-14 text-gray600 truncate ">
                {assessment.description}
              </p>
            </div>
          </div>
          {isUserAuthorizedToDelete && (
            <button
              onClick={handleDeleteClick}
              className="h-5 w-5 hover:h-[22px] hover:w-[22px] relative transition-all duration-300 items-center justify-center flex  mr-[1px] hover:mr-[0px] flex-shrink-0"
            >
              <img
                src="/assets/trash-icon-hover.png"
                alt="Delete Icon"
                className=" absolute inset-0 transition-opacity duration-0 hover:opacity-0"
              />
              <img
                src="/assets/trash-icon.png"
                alt="Delete Icon"
                className=" absolute inset-0 transition-opacity duration-0 opacity-0 hover:opacity-100"
              />
            </button>
          )}
        </div>

        {/* Progress Bar */}
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="w-full bg-borderprimary bg-opacity-60 rounded-lg h-2">
            <div
              className="bg-nafsnBlue h-2 rounded-lg"
              style={progressBarWidth}
            ></div>
          </div>
          <div className="custom-14-med text-gray700 ">
            {Math.round(completionPercentage)}%
          </div>
        </div>

        <div id="ButtonsDiv" className="flex justify-between w-full items-end">
          <Link
            to={{
              pathname: assessment.completion
                ? `/assessment/${assessment._id}/overview-report`
                : `/assessment/${assessment._id}`,
              state: { assessmentCollaborators },
            }}
          >
            <button
              className={`${
                assessment.completion ? "secondary-button" : "normal-button"
              }`}
              onClick={scrollToTop}
            >
              <span className="">
                {assessment.completion
                  ? "View CARAT Report"
                  : "Resume assessment"}
              </span>
            </button>
          </Link>

          <div className="flex flex-row space-x-[-12px]">
            {assessmentCollaborators.map((collaborator) => {
              // Check if the collaborator has the current assessment in their assessments list
              const hasAccess = collaborator.assessments.includes(
                assessment.id
              );
              const accessLevel = hasAccess
                ? "Editing Access"
                : "Viewing Access";

              // Get initials
              const initials = `${collaborator.firstName[0]}${collaborator.lastName[0]}`;

              return (
                <div
                  key={collaborator.id}
                  className="w-7 h-7 rounded-full bg-nafsnBlue flex items-center justify-center text-white custom-12 border border-borderprimary shadow-sm"
                >
                  {initials.toUpperCase()}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* tetsing purposes <h3 className="mb-2 mt-2">
        Completion: {assessment.completion ? "Completed" : "Not Completed"}
      </h3>*/}
      {/* Confirmation Pop-up */}
      {showConfirmationPopUp && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center z-[60]">
          <div className="bg-white p-9 rounded w-[500px]">
            <h4 className="custom-18-semi text-gray900 mb-1">
              Are you sure you want to delete {assessment.name}?
            </h4>
            <p className="text-gray700 custom-14 mb-5">
              This change cannot be undone.
            </p>
            <div className="mt-8 flex justify-end space-x-2">
              <button className="pop-up-cancel" onClick={cancelDelete}>
                Cancel
              </button>
              <button className="pop-up-cta" onClick={deleteAssessmentButton}>
                Confirm Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentPreview;
