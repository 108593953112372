import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";

const DropdownItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const iconSrc = isOpen
    ? "/assets/minus-circle.png"
    : "/assets/plus-circle.png";

  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div className="my-6">
      <div className="flex justify-between items-center transition-all duration-300 ease-in-out">
        <h4 className="custom-18-med text-gray900">{question}</h4>
        <button className="" onClick={() => setIsOpen(!isOpen)}>
          <img
            src={iconSrc}
            alt={isOpen ? "Close" : "Open"}
            className="w-6 h-6 mt-[2px]" // Width and height set to 24px
          />
        </button>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: maxHeight }}
        className={`overflow-hidden transition-[max-height] duration-300 ease-in-out`}
      >
        <p className="mt-2 text-gray600 custom-16">{answer}</p>
      </div>
    </div>
  );
};

const Landing = () => {
  const [activeTab, setActiveTab] = useState("themes");

  const getHowImg = () => {
    switch (activeTab) {
      case "indicators":
        return "indicators-landing";
      case "themes":
        return "foodicons-landing";
      case "report":
        return "overview-landing";
      default:
        return "overview-landing";
    }
  };

  return (
    <div id="main-footer" className="">
      <div
        id="Overall-container"
        className="margin-adaptive mb-20 w-full mx-auto flex flex-col gap-24"
      >
        <div
          id="Landing-block"
          className="desktop:pt-44 tablet:pt-36 mobile:pt-32 flex flex-grow gap-16 flex-col bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url('/assets/bg-pattern-landing.png')`,
          }}
        >
          <div id="Intro" className="flex flex-col flex-grow gap-12">
            <div className="flex flex-col gap-6 items-center ">
              <h1 className="custom-display2 max-w-[1024px] text-center">
                Community & Agriculture Resilience Audit Tool
              </h1>
              {/* <p className="text-gray600 custom-20 max-w-[450px] text-center ">
                CARAT empowers communities to identify the gaps in their food
                systems to forge a path towards a more resilient system
                tomorrow.
              </p> */}
            </div>
            <div
              id="cta-buttons"
              className="flex flex-row gap-3 justify-center"
            >
              <Link to="/login">
                <button className="xlg-button">Log In</button>
              </Link>
              <Link to="/signup">
                <button className="xlg-button-cta">
                  Start using CARAT for free
                </button>
              </Link>
            </div>
          </div>

          <img src="/assets/landing-image.png" alt="" className="rounded" />
        </div>
        <div
          id="Collaborators-block"
          className="flex flex-col gap-8 items-center"
        >
          <p className="custom-16-med text-gray600">
            This tool has been developed and offered to the public by the North
            American Food Systems Network in collaboration with:
          </p>
          <img src="/assets/collaboratorinstitutions.png" alt="" />
          {/* <div className="flex flex-col gap-10 bg-slate-50">
          <div className="flex flex-row">
            <img src="/assets/flfpc.png" alt="" className="h-[115px]" />
            <img src="/assets/ufifas.png" alt="" className="h-[115px]" />
            <img
              src="/assets/witmeetgrit.png.png"
              alt=""
              className="h-[115px]"
            />
          </div>
          <div className="flex flex-row">
            <img src="/assets/einhorn.png" alt="" className="h-[81px]" />
            <img src="/assets/harrisdale.png" alt="" className="h-[95]" />
            <img src="/assets/indiana.png.png" alt="" className="h-[95px]" />
          </div>
          <div className="flex flex-row">
            <img src="/assets/uva.png" alt="" className="h-[111px]" />
            <img src="/assets/lyson.png" alt="" className="h-[111px]" />
          </div>
        </div> */}
        </div>
        <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
        <div
          id="TheWhat-block"
          className="flex-col flex items-center gap-14"
          // style={{
          //   backgroundImage: `url('/assets/gray-banner-bg.png')`,
          // }}
        >
          <div className="flex flex-col items-center max-w-[768px]">
            <p className="custom-16-semi text-nafsnBlue mb-3">The What</p>
            <h2 className="custom-36 text-center text-gray900 mb-5">
              A Comprehensive Assessment Tool
            </h2>
            <p className="custom-20 text-gray600 max-w-[768] text-center">
              The Community & Agriculture Resilience Audit Tool (CARAT) is a
              free, open-source tool for communities to self-assess how they
              currently utilize the assets of their local food system to achieve
              a substantial level of community resilience.
            </p>
          </div>

          <img src="/assets/landing-mockup.png" alt="" className="rounded" />
        </div>
        <div
          id="TheWhy-block"
          className="flex desktop:flex-row mobile:flex-col gap-14 desktop:my-16"
        >
          <div className="flex flex-col desktop:w-1/2">
            <p className="custom-16-semi text-nafsnBlue mb-3">The Why</p>
            <h2 className="custom-36 text-gray900 mb-5">
              Establish a Benchmark
            </h2>
            <p className="custom-20 text-gray600">
              As food system leaders representing a wide range of disciplines,
              we have observed that the strengths of local food systems
              contribute significantly to developing and maintaining community
              resilience. Use CARAT to establish a baseline, set priorities, and
              measure change in resilience over time.
            </p>
          </div>
          <div className="flex flex-col desktop:w-1/2 mt-10 gap-5">
            <h3 className="custom-30">Where CARAT Can Help</h3>
            <div className="flex flex-col gap-5 ml-4">
              <div className="flex flex-row gap-3">
                <img src="/assets/check-icon.png" alt="" className="w-7 h-7" />
                <p className="custom-18 text-gray600">
                  Prompt and guide an engaged community-wide conversation about
                  resilience priorities and needs.
                </p>
              </div>
              <div className="flex flex-row gap-3">
                <img src="/assets/check-icon.png" alt="" className="w-7 h-7" />
                <p className="custom-18 text-gray600">
                  Identify ideas and potential solutions that may increase your
                  community's resilience to unexpected disruptions.
                </p>
              </div>
              <div className="flex flex-row gap-3">
                <img src="/assets/check-icon.png" alt="" className="w-7 h-7" />
                <p className="custom-18 text-gray600">
                  Assess whether the resilience of your current local food
                  system is optimized to meet perceived community needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="TheHow-block" className="flex flex-col desktop:gap-14">
          <div className="flex flex-col max-w-[768px]">
            <p className="custom-16-semi text-nafsnBlue mb-3">The How</p>
            <h2 className="custom-36 text-gray900 mb-5">
              All-In-One Audit Tool for Any Community
            </h2>
            <p className="custom-20 text-gray600">
              CARAT is firmly rooted in equitable access and engagement. CARAT
              creates a team approach for auditing community resilience by
              centering the voices and leadership of those most negatively
              impacted by its food system.
            </p>
          </div>
          <div className="flex desktop:flex-row mobile:flex-col desktop:gap-14">
            <div className="flex flex-col max-w-[564px] desktop:order-1 mobile:order-2">
              {/* Themes Tab */}
              <div
                className={`${
                  activeTab === "themes"
                    ? "border-nafsnBlue"
                    : "border-bordersecondary hover:border-gray300 transition-all duration-100 ease-in-out"
                } border-l-4  px-6 py-4 hover:cursor-pointer transition-all duration-100 ease-in-out`}
                onClick={() => setActiveTab("themes")}
              >
                <h4 className="custom-20-semi text-gray900 mb-2">7 Themes</h4>
                <p className="custom-16 text-gray600 mb-5">
                  These indicators revolve around seven core themes that address
                  the complex nature of community resilience.
                </p>
                <button
                  onClick={() => (window.location.href = "/guide/introduction")}
                  className="custom-16-semi text-nafsnBlue hover:underline flex flex-row gap-1 items-center group"
                >
                  <p>Learn more</p>
                  <img
                    src="/assets/arrow-right-blue.png"
                    alt=""
                    className="h-5 w-5 group-hover:translate-x-1 transition-all duration-300 ease-in-out"
                  />
                </button>
              </div>

              {/* Indicators Tab */}
              <div
                className={`${
                  activeTab === "indicators"
                    ? "border-nafsnBlue"
                    : "border-bordersecondary hover:border-gray300 transition-all duration-100 ease-in-out"
                } border-l-4  px-6 py-4 hover:cursor-pointer transition-all duration-100 ease-in-out`}
                onClick={() => setActiveTab("indicators")}
              >
                <h4 className="custom-20-semi text-gray900 mb-2">
                  101 Indicators
                </h4>
                <p className="custom-16 text-gray600 mb-5">
                  CARAT measures the resources within a food system via 101
                  indicators to determine possible next steps to increase
                  community resiliency and food sovereignty.
                </p>
                <button
                  onClick={() => (window.location.href = "/guide/introduction")}
                  className="custom-16-semi text-nafsnBlue hover:underline flex flex-row gap-1 items-center group"
                >
                  <p>Learn more</p>
                  <img
                    src="/assets/arrow-right-blue.png"
                    alt=""
                    className="h-5 w-5 group-hover:translate-x-1 transition-all duration-300 ease-in-out"
                  />
                </button>
              </div>

              {/* Report Tab */}
              <div
                className={`${
                  activeTab === "report"
                    ? "border-nafsnBlue"
                    : "border-bordersecondary hover:border-gray300 transition-all duration-100 ease-in-out"
                } border-l-4  px-6 py-4 hover:cursor-pointer transition-all duration-100 ease-in-out`}
                onClick={() => setActiveTab("report")}
              >
                <h4 className="custom-20-semi text-gray900 mb-2">
                  CARAT Report
                </h4>
                <p className="custom-16 text-gray600 mb-5">
                  Measure what matters with CARAT’s overview reports. You can
                  filter, print, and view additional insight resources for each
                  indicator.
                </p>
                <button
                  onClick={() =>
                    (window.location.href =
                      "/guide/how-to-interpret-your-scores")
                  }
                  className="custom-16-semi text-nafsnBlue hover:underline flex flex-row gap-1 items-center group"
                >
                  <p>Learn more</p>
                  <img
                    src="/assets/arrow-right-blue.png"
                    alt=""
                    className="h-5 w-5 group-hover:translate-x-1 transition-all duration-300 ease-in-out"
                  />
                </button>
              </div>
            </div>
            <div
              className={`${getHowImg()} flex-grow desktop:order-2 mobile:order-1`}
            >
              <img
                src={`/assets/${getHowImg()}.png`}
                alt="Cover image"
                className=""
              />
            </div>
          </div>
        </div>
        <div
          id="Faq-block"
          className="flex flex-col items-center gap-14 desktop:my-16"
        >
          <div className="flex flex-col gap-5 items-center">
            <h2 className="custom-36 text-gray900">
              Frequently asked questions
            </h2>
            <p className="custom-20 text-gray600">
              About the Community & Agriculture Resilience Audit Tool.
            </p>
          </div>
          <div id="drop-downs" className="max-w-[768px] w-full">
            <DropdownItem
              question="Is CARAT free to use?"
              answer="Yes. The North American Food Systems Network is committed to keeping tools for community food systems professionals open-access."
            />
            <div
              id="Divider"
              className="h-[1px] w-full bg-bordersecondary"
            ></div>
            <DropdownItem
              question="Who developed CARAT?"
              answer={
                <span>
                  NAFSN is honored to have convened{" "}
                  <a
                    href="https://www.foodsystemsnetwork.org/carat_working_group.php"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nafsnBlue hover:underline custom-16-semi"
                  >
                    these talented collaborators
                  </a>{" "}
                  in the development of the CARAT Tool, and we appreciate their
                  time and energies.
                </span>
              }
            />

            <div
              id="Divider"
              className="h-[1px] w-full bg-bordersecondary"
            ></div>
            <DropdownItem
              question="What is CARAT’s Data Privacy Statement?"
              answer={
                <span>
                  If you choose to use the{" "}
                  <a
                    href="https://www.foodsystemsnetwork.org/docs/CART_Fillable_PDF_Oct_2023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nafsnBlue hover:underline custom-16-semi"
                  >
                    off-line, PDF version of CARAT
                  </a>
                  , you manage your own data simply by downloading the tool and
                  saving your information to it. No data is seen or saved at our
                  end. <br></br> <br></br> If you choose to use the on-line
                  version of CARAT, your data is saved in our CARAT database so
                  that you, and other members of your audit team, can access and
                  contribute to it. The on-line version also automatically
                  generates insights and resources listed as relevant to your
                  resilience scores in one or more indicator categories.{" "}
                  <br></br>
                  <br></br>NAFSN will not share the non-aggregated version of
                  your data with anyone except those specific individuals you
                  designate as collaborators of your CARAT audit team. <br></br>
                  <br></br>
                  NAFSN may conduct analysis of the aggregated data to inform
                  food systems policies, practices and research. To ensure that
                  the confidentiality of your data is preserved and that the
                  origin of your data cannot be identified, any data analysis
                  conducted by NAFSN using the CARAT database will be restricted
                  to the publication of aggregate values of data involving
                  respondents from at least 3 or more separate audit teams, and
                  these values will only be reported across broad categories
                  corresponding to the thematic subheadings of the CARAT audit
                  tool.
                </span>
              }
            />

            <div
              id="Divider"
              className="h-[1px] w-full bg-bordersecondary"
            ></div>
            <DropdownItem
              question="Who hosts CARAT?"
              answer="CARAT is hosted by the North American Food Systems Network, a professional association for people working to strengthen local and regional food systems."
            />
            <div
              id="Divider"
              className="h-[1px] w-full bg-bordersecondary"
            ></div>
            <DropdownItem
              question="What is CARAT's Inclusivity Statement?"
              answer={
                <span>
                  CARAT is firmly rooted in equitable access and engagement.
                  CARAT creates a team approach for auditing community
                  resilience by centering the voices and leadership of those
                  most negatively impacted by its food system. CARAT is offered
                  in both on-line and off-line versions. Please{" "}
                  <a
                    href="https://www.foodsystemsnetwork.org/carat_working_group.php"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nafsnBlue hover:underline custom-16-semi"
                  >
                    contact us
                  </a>{" "}
                  with your specific questions.
                </span>
              }
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Landing;
