import React, { useState, useEffect, useRef } from "react";
import { useAssessmentsContext } from "../hooks/useAssessmentsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  createTheme1,
  createTheme2,
  createTheme3,
  createTheme4,
  createTheme5,
  createTheme6,
  createTheme7,
} from "../components/TemplateAssessment";

const NewAssessmentForm = ({ onClose }) => {
  const { dispatch: assessmentDispatch } = useAssessmentsContext();

  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const { user, dispatch: authDispatch } = useAuthContext();
  const [description, setDescription] = useState("");

  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [collaboratorError, setCollaboratorError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [descError, setDescError] = useState(null);

  //dismissal

  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(); // Close the modal
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Assessment Creation

  const createDefaultThemes = () => {
    const themes = [];
    for (let i = 0; i < 2; i++) {
      themes.push({
        tNum: i,
        name: `Theme ${i + 1}`,
        description: "randomdesc",
        completion: false, // explicitly set completion
        sections: createDefaultSections(),
      });
    }
    return themes;
  };

  const createDefaultSections = () => {
    const sections = [];
    for (let i = 0; i < 2; i++) {
      sections.push({
        sNum: i,
        name: `Section ${i + 1}`,
        completion: false, // explicitly set completion
        questions: createDefaultQuestions(),
      });
    }
    return sections;
  };

  const createDefaultQuestions = () => {
    const questions = [];
    for (let i = 0; i < 2; i++) {
      questions.push({
        qNum: i,
        question: `Question ${i + 1}`,
        answer: null, // for n/a case
        comment: "",
        completion: false, // explicitly set completion
      });
    }
    return questions;
  };

  const addAssessmentToUser = async (assessmentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/id/${user.id}`, // Update the endpoint to match the updateUser route
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            assessments: [assessmentId],
            operation: "add",
          }),
        }
      );

      const json = await response.json();

      console.log("user data returned", json);

      if (!response.ok) {
        throw new Error(
          json.error || "Failed to update user with new assessment"
        );
      }

      // Dispatch the updated user to the auth context
      authDispatch({ type: "UPDATE_USER", payload: json });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    if (!name) {
      setNameError("Please enter a name for the assessment");
      return;
    }

    if (!description) {
      setDescError("Please enter a short description for the assessment");
      return;
    }

    // Map the collaborators array to extract the IDs
    const collaboratorIds = collaborators.map((c) => c.id);

    const assessmentGenToken = process.env.REACT_APP_GEN_TOKEN;

    var genTheme = [];

    console.log("assessmentGenToken", assessmentGenToken);

    if (assessmentGenToken === "production") {
      genTheme = [
        createTheme1(),
        createTheme2(),
        createTheme3(),
        createTheme4(),
        createTheme5(),
        createTheme6(),
        createTheme7(),
      ];
    } else if (assessmentGenToken === "development") {
      genTheme = createDefaultThemes();
    } else {
      throw new Error("No Assessment Gen Loaded");
    }

    const assessmentData = {
      name,
      description,
      user_ids: collaboratorIds,
      themes: genTheme,
      // themes: createDefaultThemes(),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/`,
        {
          method: "POST",
          body: JSON.stringify(assessmentData),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );

      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.error || "Failed to create assessment");
      }

      // Call the function to add the assessment to the user's profile
      await addAssessmentToUser(json._id);

      if (response.ok) {
        setName("");
        setDescription(""); // Reset the description state
        setCollaborators(""); // Reset the collaborators state
        setError(null);
        setCollaboratorError(null);
        console.log("New assessment added", json);
        assessmentDispatch({ type: "CREATE_ASSESSMENT", payload: json });
        onClose(); // Close the popup here
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchUserInfoByEmail = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/info-by-email/${email}`
      );
      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error || "User not found");
      }

      setCollaborators((prev) => [...prev, json]);
      setCollaboratorError(null);
    } catch (error) {
      setCollaboratorError(error.message);
    }
  };

  const handleAddCollaborator = () => {
    // Check if the collaborator is the same as the logged-in user
    if (user && collaboratorEmail === user.email) {
      setCollaboratorError("You are by default a collaborator");
      return;
    }

    // Check if the collaborator is already in the list
    const isDuplicate = collaborators.some(
      (collaborator) => collaborator.email === collaboratorEmail
    );

    if (!isDuplicate) {
      fetchUserInfoByEmail(collaboratorEmail);
      setCollaboratorEmail(""); // Clear the input field
    } else {
      setCollaboratorError("Collaborator already added");
    }
  };

  const handleRemoveCollaborator = (collaboratorId) => {
    setCollaborators(collaborators.filter((c) => c.id !== collaboratorId));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]">
      <div
        ref={modalRef}
        className="main-div w-[500px] desktop:p-9 mobile:p-4 bg-white rounded-lg shadow m-2"
      >
        <div className="content-div mb-8 space-y-6">
          <h1 className="custom-18-semi text-gray900 mb-1">
            Create a new assessment
          </h1>

          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Assessment Name:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setName(e.target.value);
                if (nameError) setNameError(null); // Clear nameError if it is set
              }}
              value={name}
              placeholder="Enter a name for the assessment"
            />
            {nameError && (
              <div className="text-error custom-functional mt-1">
                {nameError}
              </div>
            )}
          </div>
          <div className="">
            <label className="block mb-2 custom-14-med text-gray700">
              Short Description:
            </label>
            <input
              type="text"
              className="input-field"
              onChange={(e) => {
                setDescription(e.target.value);
                if (descError) setDescError(null); // Clear descError if it is set
              }}
              value={description}
              placeholder="Enter a short description"
            />
            {descError && (
              <div className="text-error custom-functional mt-1">
                {descError}
              </div>
            )}
          </div>
          <div className="flex-col">
            <div className="flex items-end">
              <div className="flex-grow mr-2">
                <label className="block mb-2 custom-14-med text-gray700">
                  Collaborators:
                </label>
                <input
                  type="text"
                  className="input-field"
                  onChange={(e) => {
                    setCollaboratorEmail(e.target.value);
                    if (collaboratorError) setCollaboratorError(null); // Clear collaboratorError if it is set
                  }}
                  value={collaboratorEmail}
                  placeholder="Enter their email"
                />
              </div>
              <button
                onClick={handleAddCollaborator}
                className={`${
                  collaboratorEmail ? "blue-button" : "normal-button"
                } `}
              >
                Add
              </button>
            </div>
            <div className="pt-1">
              {collaboratorError === "User not found" && (
                <div id="error" className="text-error custom-14">
                  {collaboratorError}. Please encourage your team members to
                  each make their own account on CARAT. You will be able to add
                  them as collaborators after they've established their own free
                  accounts.
                </div>
              )}
              {collaboratorError !== "User not found" &&
                collaboratorError !== null && (
                  <div id="error" className="text-error custom-14">
                    {"Please enter add a valid collaborator"}
                  </div>
                )}
            </div>
          </div>
          <div>
            <div id="CollabDivider" className="flex items-center gap-2">
              <div className="h-[1px] flex-1 bg-clearWater"></div>
              <h3 className="text-gray600 custom-14-med">
                Current collaborators: [{collaborators.length}]
              </h3>
              <div className="h-[1px] flex-1 bg-bordersecondary"></div>
            </div>

            <div className="text-stone custom-functional mt-4">
              {collaborators.map((collaborator, index) => (
                <span
                  key={collaborator.id}
                  className="relative inline-block mr-2"
                >
                  <span
                    className="hover:text-red-500 hover:line-through cursor-pointer"
                    onClick={() => handleRemoveCollaborator(collaborator.id)}
                  >
                    {`${collaborator.firstName} ${collaborator.lastName}`}
                  </span>
                  {index < collaborators.length - 1 && ", "}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-end space-x-2">
          <button className="pop-up-cancel" onClick={onClose}>
            Cancel
          </button>

          <button className="pop-up-cta" onClick={handleSubmit}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAssessmentForm;
