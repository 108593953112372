import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

const OpDefsEditor = () => {
  const { user } = useAuthContext();

  const [opDefs, setOpDefs] = useState([]);
  const [newOpDef, setNewOpDef] = useState({
    Term: "",
    Definition: "",
    Details: "",
  });
  const [editingOpDef, setEditingOpDef] = useState(null);

  // Fetch operational definitions from the backend
  const fetchOpDefs = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/opdefs`,
        {
          headers: {
            "Authorization": `Bearer ${user.token}`, // Include this if your endpoint requires authentication
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setOpDefs(data);
    } catch (error) {
      console.error("Fetch operational definitions failed:", error);
    }
  };

  useEffect(() => {
    fetchOpDefs();
  }, []);

  // Handle input changes
  const handleChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setEditingOpDef({ ...editingOpDef, [name]: value });
    } else {
      setNewOpDef({ ...newOpDef, [name]: value });
    }
  };

  // Add a new operational definition
  const addOpDef = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/opdefs`,
      {
        method: "POST",
        body: JSON.stringify(newOpDef),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`, // if required
        },
      }
    );

    if (response.ok) {
      setNewOpDef({ Term: "", Definition: "", Details: "" });
      fetchOpDefs(); // Refresh the list
    }
  };

  // Delete an operational definition
  const deleteOpDef = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/opdefs/${id}`,
      {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${user.token}`, // if required
        },
      }
    );
    if (response.ok) {
      fetchOpDefs(); // Refresh the list
    }
  };

  // Update an operational definition
  const updateOpDef = async () => {
    if (editingOpDef) {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/opdefs/${editingOpDef._id}`,
        {
          method: "PATCH",
          body: JSON.stringify(editingOpDef),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`, // if required
          },
        }
      );

      if (response.ok) {
        setEditingOpDef(null);
        fetchOpDefs(); // Refresh the list
      }
    }
  };

  return (
    <div className="mt-4 flex flex-col gap-6 flex-grow w-full ">
      <h1 className="custom-36 text-gray900">Edit Operational Definitions</h1>
      {/* Form for adding/editing an operational definition */}
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="Term"
          name="Term"
          value={editingOpDef ? editingOpDef.Term : newOpDef.Term}
          onChange={(e) => handleChange(e, !!editingOpDef)}
          className="input-field"
        />
        <textarea
          placeholder="Definition"
          name="Definition"
          value={editingOpDef ? editingOpDef.Definition : newOpDef.Definition}
          onChange={(e) => handleChange(e, !!editingOpDef)}
          className="input-field"
        />
        <textarea
          placeholder="Details"
          name="Details"
          value={editingOpDef ? editingOpDef.Details : newOpDef.Details}
          onChange={(e) => handleChange(e, !!editingOpDef)}
          className="input-field"
        />
        <div>
          <button
            onClick={editingOpDef ? updateOpDef : addOpDef}
            className={editingOpDef ? "normal-button" : "normal-button"}
          >
            {editingOpDef ? "Update" : "Add"}
          </button>
        </div>
      </div>
      {/* List of operational definitions */}
      <div>
        {opDefs
          .sort((a, b) => a.Term.localeCompare(b.Term))
          .map((opDef) => (
            <div
              key={opDef._id}
              className="mb-4 p-4 border border-bordersecondary rounded-lg"
            >
              <div className="mb-2">
                <strong>Term:</strong> {opDef.Term}
              </div>
              <div className="mb-2">
                <strong>Definition:</strong> {opDef.Definition}
              </div>
              <div className="mb-4">
                <strong>Details:</strong> {opDef.Details}
              </div>
              <div className="flex flex-row gap-2">
                <button
                  onClick={() => {
                    setEditingOpDef(opDef);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="normal-button"
                >
                  Edit
                </button>
                <button
                  onClick={() => deleteOpDef(opDef._id)}
                  className="normal-button"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OpDefsEditor;
