import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

const Bookmarks = () => {
  const { user } = useAuthContext();
  const [bookmarks, setBookmarks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchBookmarks();
  }, [user]);

  const fetchBookmarks = async () => {
    if (user && user.id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/user/${user.id}/bookmarks`
        );
        console.log("Bookmarks fetched successfully", response.data);
        setBookmarks(response.data.filter((bookmark) => bookmark != null));
      } catch (error) {
        console.error("Error fetching bookmarks", error);
      }
    }
  };

  const handleUnbookmark = async (bookmarkId) => {
    const userId = user.id;

    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${userId}/bookmarks/${bookmarkId}`
      );
      fetchBookmarks();
    } catch (error) {
      console.error("Error removing bookmark", error);
    }
  };

  const filteredBookmarks = bookmarks.filter((bookmark) =>
    bookmark.resourceName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="border border-bordersecondary rounded-lg">
      <div className="px-6 py-5">
        <h2 className="custom-18-semi text-gray900">Bookmarked Resources</h2>
        <p className="custom-14 text-gray600">
          Your saved resources for easy access
        </p>
      </div>
      <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
      <input
        type="text"
        placeholder="Search for a resource"
        className="search-bar mx-6 mt-5"
        style={{
          backgroundImage: "url('/assets/search-lg.png')",
          backgroundPosition: "10px center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "20px",
        }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {bookmarks.length === 0 && (
        <div className="space-y-6 px-6 py-5 custom-16 text-gray700">
          No bookmarks found
        </div>
      )}
      <ul className="space-y-6 px-6 py-5">
        {filteredBookmarks.map((bookmark) => (
          <li key={bookmark._id} className="resource-list flex flex-col gap-4">
            <div
              id="resource-item"
              className="border tablet:p-6 mobile:p-4 rounded-lg border-bordersecondary"
            >
              <div className="flex flex-row gap-6 justify-between">
                <p className="resource-name custom-18-semi text-gray900 mb-2">
                  {bookmark.resourceName}
                </p>
                <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm tablet:inline-flex mobile:hidden items-center max-h-[24px]">
                  <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                  <h2 className="custom-14-med text-gray700">Resource</h2>
                </div>
              </div>

              <p className="resource-description custom-16 text-gray600 mb-4">
                {bookmark.resourceDescription}
              </p>
              <div className="flex flex-row gap-3">
                <div className="small-button">
                  <img
                    src="/assets/link-external.png"
                    alt="External Link Icon"
                    className="w-4 h-4"
                  />
                  <a
                    href={bookmark.resourceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </a>
                </div>
                <button
                  onClick={() => handleUnbookmark(bookmark._id)}
                  className="small-button custom-14-med text-sprout"
                >
                  <img
                    src="/assets/bookmark-solid.png"
                    alt="Unbookmark Icon"
                    className="w-4 h-4"
                  />
                  Unbookmark
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Bookmarks;
