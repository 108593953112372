import { Link, useLocation } from "react-router-dom";
import React from "react";

const Breadcrumbs = ({ assessmentName }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  // Filter out the segments 'assessment' and 'sections'
  const filteredPathnames = pathnames.filter(
    (segment) => segment !== "assessment" && segment !== "sections"
  );

  // Replace the ID with assessmentName and construct the breadcrumb path
  const breadcrumbs = filteredPathnames.map((segment, index) => {
    // Check if the segment is an ID
    if (/^[0-9a-fA-F]{24}$/.test(segment)) {
      return { name: assessmentName, pathSegment: segment, index };
    }

    // Add the new condition here
    if (segment === "overview-report") {
      return { name: "Your CARAT Report", pathSegment: segment, index };
    }

    // Existing return for other cases
    return { name: segment, pathSegment: segment, index };
  });

  return (
    <nav
      aria-label="breadcrumb"
      className=" bg-white z-50 text-sm w-[1200px] mobile:mx-auto pb-2 print:hidden  mt-[-80px] pt-[100px]"
    >
      <ol className="flex">
        {/* Home Link */}
        <li className="breadcrumb-item py-1">
          <Link
            to="/overview"
            className="text-gray500 hover:text-gray900 custom-14 transition-all ease-in-out duration-300"
            onClick={scrollToTop}
          >
            Home
          </Link>
        </li>
        {/* Other Breadcrumb Links */}
        {breadcrumbs.map(({ name, pathSegment, index }) => {
          const last = index === breadcrumbs.length - 1;
          const linkClass = last
            ? "text-nafsnBlue custom-14-med py-1 px-2 bg-river rounded bg-opacity-10"
            : "text-gray500 hover:text-gray900 custom-14 transition-all ease-in-out duration-300";

          // Construct the path for the link
          // Use the original segment (ID) for the assessment link
          const to = `/assessment/${pathSegment}`;

          return (
            <React.Fragment key={pathSegment}>
              <div className="flex items-center mx-2 mt-[1px]">
                <img
                  src="/assets/chevron-right.png"
                  alt="NAFSN Logo"
                  className="w-[16px] h-[16px]"
                />
              </div>
              <li className="flex items-center">
                {last ? (
                  <span className={linkClass}>{decodeURIComponent(name)}</span>
                ) : (
                  <Link to={to} className={linkClass} onClick={scrollToTop}>
                    {decodeURIComponent(name)}
                  </Link>
                )}
              </li>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
