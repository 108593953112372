import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // "success" or "error"
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      if (countdown <= 0) {
        setIsButtonDisabled(false);
      }
    }

    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setMessage("");
    setMessageType("");
    setCountdown(60); // Reset countdown to 60 seconds

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/request-reset`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      // Handle response
      if (response.ok) {
        setMessage("Success! Check your inbox for a password reset link.");
        setMessageType("success");
      } else if (response.status === 404) {
        setMessage("User not found.");
        setMessageType("error");
      } else {
        throw new Error("Something went wrong. Please try again.");
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
      setIsButtonDisabled(false); // Allow immediate retry on error
    }
  };

  return (
    <div className="max-w-[400px] mx-auto mt-40 rounded margin-adaptive">
      <img
        src="/assets/caratlogo-color.png"
        alt="CARAT Logo"
        className="w-[64px] h-[64px]"
      />
      <h2 className="text-gray900 custom-30">Reset Your Password</h2>
      <p className="text-gray600 custom-14 mb-6">
        Enter the email address you used to register with.
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-0">
        <input
          type="email"
          className="input-field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          disabled={isButtonDisabled}
        />

        {isButtonDisabled && messageType !== "error" && (
          <div className="custom-14 text-gray600 mt-2">
            Reset link sent! You can resend in {countdown} seconds.
          </div>
        )}

        {message && isButtonDisabled && messageType === "error" && (
          <div className="custom-14 text-red-500 mt-2">
            {message} Retry in {countdown} seconds.
          </div>
        )}

        <div className="mt-8 flex justify-end space-x-2">
          <button onClick={() => navigate(-1)} className="normal-button">
            Back
          </button>

          <button
            type="submit"
            className={`normal-button ${
              isButtonDisabled
                ? "normal-button cursor-not-allowed"
                : "blue-button"
            }`}
            disabled={isButtonDisabled}
          >
            Send instructions
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
