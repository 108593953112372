import React, { useState, useEffect } from "react";
const sections = ["operational definitions"];

const OperationalDefinitions = () => {
  const [opDefs, setOpDefs] = useState([]);
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  // Fetch operational definitions from the backend
  const fetchOpDefs = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/opdefs`,
        {}
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setOpDefs(data);
    } catch (error) {
      console.error("Fetch operational definitions failed:", error);
    }
  };

  useEffect(() => {
    fetchOpDefs();
  }, []);

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div
          id="operational definitions"
          className="reading-sections-container"
        >
          <h1 className="custom-display text-gray900">
            Operational Definitions
          </h1>{" "}
          {/* List of operational definitions */}
          <div>
            {opDefs
              .sort((a, b) => a.Term.localeCompare(b.Term))
              .map((opDef) => (
                <div
                  key={opDef._id}
                  className="mb-4 p-4 border border-bordersecondary rounded-lg"
                >
                  <div className="mb-2">
                    <strong>Term:</strong> {opDef.Term}
                  </div>
                  <div className="mb-2">
                    <strong>Definition:</strong> {opDef.Definition}
                  </div>
                  <div className="mb-4">
                    <strong>Details:</strong> {opDef.Details}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[96px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperationalDefinitions;
