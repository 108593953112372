import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

import OpDefsEditor from "../jsoneditpagecomponents/OpDefsEditor";
import ResourcesEditor from "../jsoneditpagecomponents/ResourcesEditor";

const JsonEditorPage = () => {
  const [activeTab, setActiveTab] = useState("resources");
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // Whitelist of user IDs
  const whitelist = [
    "65b15b6f0c8cabba304cc374",
    "659585b00c590e08cb6ae646",
    "658cbfd2228e974c365c3df4",
    "65ca36cb4963717542af5544",
  ];

  useEffect(() => {
    // Redirect user if not whitelisted
    if (!whitelist.includes(user.id)) {
      navigate("/overview");
    }
  }, [user, navigate]);

  return (
    <div className="json-editor-page mt-40 margin-adaptive mx-auto">
      <div className="tabs flex flex-row w-full gap-3 mb-10">
        <button
          className={`${
            activeTab === "resources" ? "admin-tab-cta" : "admin-tab"
          }`}
          onClick={() => setActiveTab("resources")}
        >
          Edit Resources
        </button>
        <button
          className={`${
            activeTab === "opdefs" ? "admin-tab-cta" : "admin-tab"
          }`}
          onClick={() => setActiveTab("opdefs")}
        >
          Edit Operational Definitions
        </button>
      </div>

      {activeTab === "resources" && <ResourcesEditor />}
      {activeTab === "opdefs" && <OpDefsEditor />}
    </div>
  );
};

export default JsonEditorPage;
