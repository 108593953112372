import React from "react";

const SectionsPreview = ({ sections, onSectionSelect, selectedSection }) => {
  const getImagePath = (section) => {
    if (section.name === selectedSection.name && section.completion) {
      return "/assets/completed-current-section-indicator.png"; // Image for completed and current section
    } else if (section.name === selectedSection.name) {
      return "/assets/current-section-indicator.png"; // Image for the current section
    } else if (section.completion) {
      return "/assets/completed-section-indicator.png"; // Image for completed section
    } else {
      return "/assets/incomplete-section-indicator.png"; // Image for incomplete section
    }
  };

  const getLineColor = (section) => {
    return section.completion ? "bg-sprout" : "bg-borderprimary";
  };

  const isLastSection = (index) => {
    return index === sections.length - 1;
  };

  const isCurrentSection = (section) => {
    return section.name === selectedSection.name;
  };

  return (
    <div id="sections-preview">
      {sections.map((section, sNum) => (
        <div
          key={sNum}
          className="cursor-pointer flex items-start relative group"
          onClick={() => {
            onSectionSelect(section);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <div className="flex-shrink-0 flex flex-col items-center mr-4 transition-all duration-300">
            <div
              className={`relative ${
                section.name === selectedSection.name
                  ? section.completion
                    ? "green-shadow"
                    : "blue-shadow"
                  : ""
              }`}
            >
              <img
                src={getImagePath(section)}
                alt="Section Status"
                className="w-[32px] h-[32px] group-hover:scale-112 group-active:scale-90 transition-all duration-300"
              />
            </div>

            {!isLastSection(sNum) && (
              <div
                className={`w-0.5 ${getLineColor(
                  section
                )} rounded absolute left-[15px] my-1`}
                style={{ top: "32px", bottom: "0px" }}
              ></div>
            )}
          </div>

          <div className="flex flex-col justify-center">
            <h2
              className={`custom-16-semi text-gray700 mt-1 ${
                isCurrentSection(section) ? "text-nafsnBlue" : ""
              }`}
            >
              Section {sNum + 1}
            </h2>
            <h3
              className={`custom-16 text-gray600 mb-6 ${
                isCurrentSection(section) ? "text-nafsnBlue" : ""
              }`}
            >
              {section.name}
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SectionsPreview;
