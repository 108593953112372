import React, { useState, useEffect } from "react";

const sections = ["introduction"];

const NavigatingCaratOnline = () => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div id="introduction" className="reading-sections-container">
          <div className="reading-subsections-container">
            <h1 className="custom-display text-gray900">
              Navigating CARAT Online
            </h1>
            <p className="custom-18 text-gray600">
              The Community & Agriculture Resilience Audit Tool, commonly known
              as CARAT, is a collaborative platform that brings together
              community stakeholders for a self-assessment of their local food
              system. Its primary aim is to gauge how these assets contribute to
              achieving a substantial level of community resilience. To
              accomplish this, CARAT utilizes 101 indicators that measure the
              resources within a food system. These indicators are instrumental
              in determining the next steps for enhancing community resiliency
              and food sovereignty.
            </p>
          </div>
        </div>
        <div id="themes" className="reading-sections-container">
          <div className="reading-subsections-container">
            <h2 className="custom-30 text-gray700">Themes</h2>
            <p className="custom-18 text-gray600">
              The assessment revolves around seven core themes, addressing the
              complex nature of community resilience. It requires active
              participation and attention from a diverse group of actors.
              Together, these actors provide a holistic perspective on the
              integral role food systems play in the resilience of a community.
            </p>
          </div>
          <img
            src="/assets/Foodicons.png"
            alt="Foodicons"
            className="w-fill my-8"
          />
          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 1: Natural Resource Management, Use, Conservation, and
              Preservation of Ecosystems and Farmland
            </h2>
            <p className="custom-18 text-gray600">
              Creating an environment that is conducive to stimulating local
              food production by protecting farmland; facilitating access to
              land for local producers; establishing water quantity and climate
              action plans; enacting zoning and permitting policies that improve
              agricultural activities; and promoting the adoption of
              agricultural practices that promote soil, land, and water
              conservation.
            </p>
          </div>
          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 2: Community Health and Well-being
            </h2>
            <p className="custom-18 text-gray600">
              Recognizing the social determinants of health; improving citizen
              well-being through educational programs and activities that
              facilitate better access to nutritious, affordable, and culturally
              appropriate foods across all income groups, especially for highly
              food insecure or medically high-risk groups within the community.
            </p>
          </div>
          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 3: Community Self-Reliance in Food
            </h2>
            <p className="custom-18 text-gray600">
              Enhancing the degree of local influence and control in a
              community’s food system; increasing the community’s ability to
              meet its food needs; and reducing its dependence on external, less
              predictable supply chains.
            </p>
          </div>
          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 4: Distributed and Democratic Leadership
            </h2>
            <p className="custom-18 text-gray600">
              Providing broad access to leadership and decision-making authority
              among all stakeholder groups in a community, including those that
              have been historically marginalized, while demonstrating a strong
              commitment to public transparency in planning, decision-making,
              and project implementation.
            </p>
          </div>
          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 5: Focus on Local Farmers, Food Processors, and Food
              Distributors
            </h2>
            <p className="custom-18 text-gray600">
              Providing expanded opportunities for local farmers, ranchers, and
              other food producers to profit from their work by developing
              infrastructure to support robust local/regional food marketing
              channels that enable producers and food makers to retain a larger
              share of the consumer’s food spending.
            </p>
          </div>

          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 6: Food Sovereignty
            </h2>
            <p className="custom-18 text-gray600">
              Ensuring that the food system is equitable, accessible, and
              inclusive for all members of society by centering the voices and
              leadership of those most negatively impacted. As stated in the
              Declaration of Nyéléni in 2007, “food sovereignty is the right of
              peoples to healthy and culturally appropriate food produced
              through ecologically sound and sustainable methods, and their
              right to define their own food and agriculture systems.” See
              https://nyeleni.org/en/declaration-of-nyeleni/
            </p>
          </div>

          <div className="reading-subsections-container">
            <h2 className="custom-20-semi text-gray700">
              Theme 7: Place-Based Economics
            </h2>
            <p className="custom-18 text-gray600">
              Designing and implementing policies, programs, and investments
              that enhance local resource ownership and the ability of local
              people to participate in food system work; educating a skilled and
              capable labor force that can effectively participate in the
              agricultural or food manufacturing sector and develop relevant
              infrastructure to support local food production, processing, and
              distribution.
            </p>
          </div>
        </div>

        <div id="notes" className="reading-sections-container">
          <h2 className="custom-30 text-gray700">Notes</h2>
          <p className="custom-18 text-gray600">
            The impact of food systems on community resilience in each of these
            themes should not be understated. The dominant structure in our food
            system is based on business considerations rather than social
            determinants of health. As a result, it creates an environment which
            not only limits consumer choice but has implications for the
            community’s health and well-being, the community’s preparedness for
            disruptions in the food supply chain, the adoption of sustainable
            food practices, and food accessibility for food-insecure households.
            As such, we have developed the CARAT tool based on seven themes to
            help you identify gaps in your community’s current food system and
            guide you toward increasing your community’s resilience through food
            system improvements.
          </p>
          <p className="custom-18 text-gray600">
            Community food system assessments are already being used by
            municipalities, state governments, nonprofits, networks, counties,
            and other stakeholders to inform the creation of policies,
            practices, and programs. However, existing assessment tools are
            either not readily available and/or they examine just one part of
            the food system, such as agriculture, or food access. What
            distinguishes CARAT from other community food system assessments is
            our holistic approach to community resilience and our focus on
            examining the interconnectivity of distinct themes in our food
            system.
          </p>
          <p className="custom-18 text-gray600">
            CARAT allows you to conduct a comprehensive review of your “food
            system focus area,” as defined by you and your community. This
            flexibility allows you to determine the geographic scope of your
            project based on the context of your physical environment, trade
            patterns, and goals. As a community resilience tool, CARAT is
            designed to be used by food producers, consumers, food sovereignty
            advocates, municipalities, nonprofits, and all stakeholders
            interested in resiliency to: start a conversation focused on
            identifying barriers to community resilience and uncover potential
            underutilized assets; establish the baseline resilience status of a
            community; and discover potential action steps in local food systems
            development that could be taken to improve a community’s resilience
            level.
          </p>
          <p className="custom-18 text-gray600">
            Note that each section of the tool is structured to help your
            community set timelines, self-assess your food policies, and
            recognize inherent flaws and biases within the existing food systems
            infrastructure.
          </p>
          <p className="custom-18 text-gray600">
            Inclusivity is necessary to get the most out of the audit tool. We
            anticipate that the CARAT process will be most effective for
            community users if there is intentional diversity among the
            participants: diversity in the sector, race, ethnicity,
            socioeconomic status, gender, location (urban/rural), and levels of
            power in your community. Encourage members of your assessment team
            to think critically about current systems to ensure everyone has a
            voice.
          </p>
          <p className="custom-18 text-gray600">
            A comprehensive approach will provide more room for discovery and
            growth in your community, helping you formulate your future steps
            and create tangible results. CARAT is built for community
            collaboration; a broader range of perspectives will produce more
            accurate assessments. By the end of the assessment process, assuming
            that an appropriately diverse group of policymakers, supply chain
            practitioners, and grassroots stakeholders has been assembled, we
            believe that community participants will have compiled enough
            practical insights and been exposed to enough information about
            resources, relevant action steps, and potential funding
            opportunities to bring about meaningful change.
          </p>
        </div>
      </div>
      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[108px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavigatingCaratOnline;
