import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom";

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError(""); // Reset the password error

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    await signup(firstName, lastName, email, password);
  };

  return (
    <div className="flex h-screen tablet:items-center tablet:my-12 mobile:pt-32 margin-adaptive mx-auto">
      <form
        className="flex flex-col mx-auto tablet:w-[343px] mobile:w-full mobile:max-w-[343px]  "
        onSubmit={handleSubmit}
      >
        <h3 className="mb-3 custom-30 text-gray900 mx-auto">
          Create an account
        </h3>
        <h3 className="mb-8 custom-16 text-gray600 mx-auto">
          Start using CARAT for today.
        </h3>
        {/* First Name */}
        <div className="mb-4 w-full">
          <label className="input-tag" htmlFor="firstName">
            First Name:
          </label>
          <input
            id="firstName"
            type="text"
            className="input-field"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </div>

        {/* Last Name */}
        <div className="mb-4 w-full">
          <label className="input-tag" htmlFor="lastName">
            Last Name:
          </label>
          <input
            id="lastName"
            type="text"
            className="input-field"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>

        {/* Email */}
        <div className="mb-4 w-full">
          <label className="input-tag" htmlFor="email">
            Email:
          </label>
          <input
            id="email"
            type="email"
            className="input-field"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>

        {/* Password */}
        <div className="mb-4 w-full">
          <label className="input-tag" htmlFor="password">
            Password:
          </label>
          <input
            id="password"
            type="password"
            className="input-field"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>

        {/* Confirm Password */}
        <div className="mb-6 w-full">
          <label className="input-tag" htmlFor="confirmPassword">
            Confirm Password:
          </label>
          <input
            id="confirmPassword"
            type="password"
            className="input-field"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          {passwordError && (
            <div className="text-red-500 text-sm mb-4">{passwordError}</div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex-col items-center justify-between w-full">
          <button
            className="lg-button-cta w-full items-center justify-center"
            type="submit"
            disabled={isLoading}
          >
            Sign Up
          </button>
          {error && (
            <div className="error text-red-500 text-sm mt-2">{error}</div>
          )}
        </div>
        <div className="mt-4 mx-auto">
          <p className="text-gray600 text-sm">
            Already have an account?{" "}
            <Link
              to="/login"
              className="text-nafsnBlue custom-14-med hover:underline"
            >
              Log in
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signup;
