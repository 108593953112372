// TemplateAssessment.js

export const createTheme1 = () => {
  return {
    tNum: 1,
    name: "Natural Resource Management",
    description:
      "Natural resource management, use, and conservation including protecting the ecosystem and preserving farmland.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Protecting and Preserving Farmland",
        completion: false,
        questions: [
          {
            qNum: 1,
            question:
              "We have policies, practices, or programs that protect farmland from nonfarm development.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 2,
            question:
              "We have policies, practices, or programs that support water quality, water conservation, and watershed management.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 3,
            question:
              "We have policies, practices, or programs that encourage the adoption of practices that promote animal welfare.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 4,
            question:
              "We have policies, practices, or programs that keep food waste from residences, foodservice establishments, and supermarkets out of landfills through recycling and/or reusing, including by composting.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 5,
            question:
              "We have policies, practices, or programs that keep agricultural waste out of landfills through recycling and/or reusing, including by composting.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 6,
            question:
              "We have policies, practices, or programs that support “land back” and land reparations for BIPOC farmers and Indigenous lands.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Promoting soil health and conservation practices",
        completion: false,
        questions: [
          {
            qNum: 7,
            question:
              "We have policies, practices, or programs that support cover crop adoption or continuation and other tillage practices.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 8,
            question:
              "We have policies, practices, or programs that support the adoption or expansion of crop diversification efforts.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 9,
            question:
              "We have policies, practices, or programs that support diversified crop rotation adoption or continuation.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 10,
            question:
              "We have policies, practices, or programs that support the adoption of windbreaks and their preservation.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 11,
            question:
              "We have policies, practices, or programs that support the adoption of water conservation measures.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 2,
        name: "Preserving natural land resources to reduce soil and land erosion",
        completion: false,
        questions: [
          {
            qNum: 12,
            question:
              "We have policies, practices, or programs that incentivize or require preserving and/or restoring coastal wetlands.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 13,
            question:
              "We have policies, practices, or programs that incentivize or require preserving and/or adding new vegetative buffer zones in riparian areas.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 14,
            question:
              "We have policies, practices, or programs that support the use of pond and/or river fencing to prevent livestock damage.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 15,
            question:
              "We have policies, practices, or programs that facilitate the adoption of agroforestry practices, including those that integrate the management of forested lands with livestock and crop production.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 16,
            question:
              "We have policies, practices, or programs that prohibit or strictly monitor concentrated animal feeding operations (CAFOs).",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 17,
            question:
              "We have policies, practices or programs that encourage commercial fishers and/or seafood producers to manage and harvest fish and seafood in accordance with recognized indicators and/or standards of environmental sustainability.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 3,
        name: "Promoting practices aimed at reducing greenhouse gas emissions and fossil fuel dependence",
        completion: false,
        questions: [
          {
            qNum: 18,
            question:
              "We have policies. practices, or programs that promote the use of renewable energy sources and/or electric vehicles in food transport and logistics.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 19,
            question:
              "We have policies, practices, or programs that promote the use of renewable energy sources for on-farm-energy production and/or use.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 20,
            question:
              "We have policies, practices, or programs that restrict the types or amounts of fertilizers that may be used on commercial farmland.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 21,
            question:
              "We have policies, practices, or programs that restrict the types or amounts of fertilizers that may be used on public or residential properties.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme2 = () => {
  return {
    tNum: 2,
    name: "Community Health and Well-being",
    description:
      "Recognizing the social determinants of health; improving citizen well-being through educational programs and activities.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Improving citizen wellness",
        completion: false,
        questions: [
          {
            qNum: 22,
            question:
              "We have policies, practices, or programs that provide opportunities for leadership, collaboration, and connections with socially disadvantaged groups to foster equity and inclusion in community, professional, and grassroots contexts.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 23,
            question:
              "We have policies, practices, or programs that regularly monitor public health indicators to assess food system-related community health.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 24,
            question:
              "We have policies, practices, or programs that enable farm-to-institution procurement programs for schools, daycare facilities, hospitals, or correctional facilities.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 25,
            question:
              "We have policies, practices, or programs that promote retailing healthful local foods through demonstration or pilot projects, marketing campaigns, point-of-sale signage, social media, or other ways of distinguishing such foods and encouraging their consumption.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 26,
            question:
              "We have policies, practices, or programs that provide fresh food access for limitedresource and limited-mobility residents through farmers markets, fresh produce delivery services, SNAP and WIC-enabled purchasing, and cost-share incentive programs. This may include policies or programs that enhance transportation and/or access to local food market sites.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 27,
            question:
              "We have policies, practices, or programs that subsidize sliding-scale CSA subscriptions to increase accessibility.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 28,
            question:
              "We have policies, practices, or programs that make community-based nutrition education, cooking instruction, and youth education programming widely available.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 29,
            question:
              "We have policies, practices, or programs that assist and promote emergency and supplemental food providers, like food banks and food pantries, to source fresh food from local farmers.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Encouraging attention to food production and accessibility",
        completion: false,
        questions: [
          {
            qNum: 30,
            question:
              "We have policies, practices, or programs in place for specifically addressing food availability and accessibility in the event of a disaster or other emergency.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 31,
            question:
              "We have emergency and/or disaster plans that are integrated and coordinated with other types of local or regional emergency relief and  food access activities.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 32,
            question:
              "We have emergency plans that include specific acquisition and storage recommendations for household members, food retailers, public agencies, and relevant nonprofit organizations.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 33,
            question:
              "We have emergency plans that include networks of grassroots and community-led organizations that are able to quickly communicate with underserved and/or isolated communities to distribute emergency food resources as needed, especially in rural communities and in other low-access areas, including those with immigrant and migrant populations.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 34,
            question:
              "In our community, information about disaster and/or emergency plans, the conditions that would trigger their execution, and their expected impact is regularly and widely shared with local government officials, nongovernmental stakeholders, and members of the public.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 35,
            question:
              "In our community, a full range of diverse, local stakeholders, including those involved in food production, distribution, and grassroots organizing, have been engaged in disaster and/or emergency management planning, and their feedback is regularly solicited to reduce the chances of overlooking critical information.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 36,
            question:
              "In our community, stakeholder engagement in disaster and/or emergency management planning involves meeting people where they are, through public meetings, interviews, social media, postal mail, and outreach activities conducted at times convenient for them.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme3 = () => {
  return {
    tNum: 3,
    name: "Community Self-Reliance in Food",
    description:
      "Enhancing the degree of local influence and control in a community’s food system; increasing the community’s ability to meet its food needs.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Protecting community members against instability",
        completion: false,
        questions: [
          {
            qNum: 37,
            question:
              "We have policies, practices, or programs that include farmland-protection strategies like development rights programs and land trusts, among others.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 38,
            question:
              "We have policies, practices, or programs that educate and enable people to hunt, fish, and forage for food.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 39,
            question:
              "We have policies, practices, or programs that offer minimal barriers to starting new food production enterprises.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 40,
            question:
              "We have policies, ordinances, or zoning regulations that allow food for local consumption to be grown within the community's boundaries.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 41,
            question:
              "We have policies, ordinances, or zoning regulations that allow food for local consumption to be processed by small-scale cottage or other processors.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 42,
            question:
              "We have policies, practices, or programs that support affordable access to fresh water, mulch, compost, seeds, and other resources for food growing programs in our community.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 43,
            question:
              "In our community, local commercial farmers (those with more than $1,000 in annual sales) and/or local food processors produce and sell a substantial amount of food for consumption in households, restaurants, school cafeterias, hospital food service, as well as programs for donating to food-insecure residents in our community.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 44,
            question:
              "In our community, private household and community gardens produce a substantial amount of food that is consumed in our community.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Providing opportunities for additional local food production",
        completion: false,
        questions: [
          {
            qNum: 45,
            question:
              "We have a substantial amount of unplanted, arable, government-owned land in our community that could be used by current or new farmers for additional production of food for local consumption. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 46,
            question:
              "We have a substantial amount of unplanted, arable, privately owned land in our community that could be used by current or new farmers for substantial additional production of food for local consumption.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 47,
            question:
              "We have a substantial amount of unplanted, arable land owned by a nonprofit organization(s) in our community that could be used by current or new farmers for substantial additional production of food for local consumption.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 48,
            question:
              "We have unplanted, arable land owned by government, private, or nonprofit entities that could be used by a program that provides new farmers with land for producing food for local consumption.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 49,
            question:
              "We have policies, practices, or programs that match new and/or beginning farmers with farmland available for lease or purchase.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 50,
            question:
              "We have policies, practices, or programs that support soil remediation measures and/or the construction of raised beds where needed to enable residents to produce food safely.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme4 = () => {
  return {
    tNum: 4,
    name: "Distributive and Democratic Leadership",
    description:
      "Providing broad access tol eadership and decision-making authority among all stakeholder groups in a community.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Providing broad access to leadership and decision-making authority",
        completion: false,
        questions: [
          {
            qNum: 51,
            question:
              "We have policies, practices, or programs that provide educational opportunities for community stakeholders to build their capacity as leaders, champions, ambassadors, or otherwise become more actively and effectively engaged in the local food system.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 52,
            question:
              "We have a local food policy council (or similar established organization) that incorporates the concerns of local food system stakeholders into its work by regularly advertising and hosting open forums, and maintaining the transparency and accessibility of meetings and initiatives.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 53,
            question:
              "Our local food policy council (or similar established organization) actively seeks and recruits demographically diverse (racially, socioeconomically, lived experience, etc.) leadership to elevate the voices of those most affected by food policy.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 54,
            question:
              "The membership of our local food policy council (or similar established organization) reflects the demographic and socio-economic makeup of the community.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 55,
            question:
              "The membership of our local food policy council (or similar established organization) includes a wide range of food system practitioners from across the food supply chain, including growers, processors, distributors, marketers, procurement specialists, and personnel involved in nutrition assistance, nutrition education, and emergency feeding programs.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Building economic resilience and enhancing risk management",
        completion: false,
        questions: [
          {
            qNum: 56,
            question:
              "We have policies, practices, or programs that help create and grow formal agricultural cooperatives that sell local food in local markets.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 57,
            question:
              "We have policies, practices, or programs that help create and expand collective marketing networks (other than formal cooperatives). These may include networks that facilitate sharing equipment, packing facilities, distribution routes, and/or transportation expenses among producers involved in supplying locally produced foods to local markets.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 58,
            question:
              "We have policies, practices, or programs that create and grow cooperatively owned food retail venues that showcase locally grown foods and locally harvested fish or seafood to promote socially responsible practices in the food supply chain, and/or provide economic benefits to members.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 59,
            question:
              "We have policies, practices, or programs that encourage reuse innovations for food packaging.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 60,
            question:
              "In our community, representatives of established local businesses provide mentoring guidance on food business development and operations to new business entrants. ",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme5 = () => {
  return {
    tNum: 5,
    name: "Focus on Local Farmers, Food Processors, and Food Distributors",
    description:
      "Providing expanded opportunities for local farmers, ranchers, and other food producers to profit from their work",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Focusing on the needs and views of those involved",
        completion: false,
        questions: [
          {
            qNum: 61,
            question:
              "We have policies, practices, or programs that are structured to effectively and routinely bring farmer and processor concerns into community planning decision-making, including for emergencies.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 62,
            question:
              "We have policies, practices, or programs that direct resources to train aspiring BIPOC farmers and food producers.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 63,
            question:
              "We have an agricultural advisory board, composed primarily of farm (urban and rural) representatives, that provides guidance to local governments on agricultural matters that is listened to.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 64,
            question:
              "We have local Cooperative Extension staff who effectively supply farmers and foodmakers with critical information about local food production, distribution, and marketing.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 65,
            question:
              "We have local industry representatives (current or retired) who provide formal mentorship to new business entrants in food business development and operations.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 66,
            question:
              "We have food business accelerators or food technology programs that provide affordable support for retail product development to startup and small food-processing businesses.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 67,
            question:
              "We have public agencies or nonprofit organizations in the community that individually or jointly conduct distribution programs to provide healthful, fresh food to food-insecure households.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 68,
            question:
              "In our community, input from historically marginalized farmers about their needs and preferences is an explicit part of formulating community food system policies, practices, or programs.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Providing financial resources",
        completion: false,
        questions: [
          {
            qNum: 69,
            question:
              "We have a grant program or low-interest loan fund that provides affordable capital to small or beginning agricultural and/or food enterprises.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 70,
            question:
              "We have a program that connects farmers to markets for surplus food and imperfect produce.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 71,
            question:
              "We have policies, practices, or programs that invest in BIPOC-owned and -operated farms or food businesses through direct grants or lowinterest loans.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 72,
            question:
              "In our community, stakeholders from local governmental or nonprofit organizations collaborate with local food supply-chain actors (such as farmers, small processors, food banks, or other organizations) to obtain state or federal grant funding for local projects and initiatives.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 73,
            question:
              "In our community, public agencies, nonprofit organizations, and businesses have either adopted incentives or relaxed procurement rules that permit a substantial share of local institutional food purchases to be from local sources.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 74,
            question:
              "In our community, stakeholders from local governmental or nonprofit organizations collaborate with local food supply-chain actors to secure grant funding from private foundations or mission-driven financial institutions for local projects and initiatives.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 75,
            question:
              "In our community, government agencies or nonprofit organizations identify funding opportunities or assist in writing grants for state or federal funding targeted to developing BIPOC-owned and -operated farms or food businesses.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 76,
            question:
              "We have one or more Community Development Financial Institutions (CDFIs) that provide funding to local food system initiatives, such as financial assistance awards offered by the Healthy Food Financing Initiative. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 77,
            question:
              "In our community, BIPOC-owned and operated farms or food businesses receive assistance identifying funding opportunities and support with writing grant applications to foundations or mission-driven financial institutions. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 78,
            question:
              "In our community, private agricultural lending institutions, such as members of the Farm Credit Council, facilitate lending to BIPOCowned and -operated farms and food businesses. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 79,
            question:
              "In our community, private agricultural lending institutions, such as members of the Farm Credit Council, provide financial support to local food producers or processors. ",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme6 = () => {
  return {
    tNum: 6,
    name: "Food Sovereignty",
    description:
      "Ensuring that the food system is equitable, accessible, and inclusive for all members ofsociety by centering the voices and leadership of those most negatively impacted.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Acknowledging and addressing inequities in the food system",
        completion: false,
        questions: [
          {
            qNum: 80,
            question:
              "In our community, inequities, injustice, and barriers in the local food system are commonly and publicly identified and acknowledged at the leadership level through equity statements, planning documents, laws, policies, and/or staffing decisions.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 81,
            question:
              "In our community, we have planning and implementation steps to correct inequities in the food system that are based on information from adversely affected stakeholders obtained during public listening sessions in which compensation and accommodations facilitate equitable participation.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 82,
            question:
              "We have policies, practices, or programs that address the social determinants of health and root causes of inequities in the food system for historically disadvantaged community members.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 83,
            question:
              "We have policies, practices, or programs that empower individuals and organizations from historically disadvantaged backgrounds to collaborate on and co-create solutions and resources to overcome barriers and address root causes.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 84,
            question:
              "We have an explicit policy goal to reduce the BIPOC unemployment rate (especially among youth) by identifying potential local food system job opportunities and matching qualified applicants with openings.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};

export const createTheme7 = () => {
  return {
    tNum: 7,
    name: "Place-Based Economics",
    description:
      "Place-based economics that enhance local control, local food system resource ownership, and the ability of local people to participate fully in food system work.",
    completion: false,
    sections: [
      {
        sNum: 0,
        name: "Promoting and supporting informal agricultural sector activities",
        completion: false,
        questions: [
          {
            qNum: 85,
            question:
              "Our local leadership and/or food policy council understands and acknowledges the unique challenges facing the local food system specific to land use, land access, labor, infrastructure, transportation, distribution, education, impacts from climate change, immigration, economic development, and healthcare.  ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 86,
            question:
              "Our local leadership and/or food policy council understands, acknowledges, and has collected data on the social determinants of health in our community and the unique challenges in our area based on demographics, socioeconomics, and location. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 87,
            question:
              "In our community, public meetings including council meetings, land use planning meetings, zoning meetings, and school board meetings are easily accessible by the general public, hosted near public transportation, provide virtual access when possible, and include accommodations for those with physical impairments as well as translation for ESL residents.  ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 88,
            question:
              "In our community, planning departments and/or councils of government have staff with expertise in food systems, farmland, gardening, and/or agriculture.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 89,
            question:
              "In our community, comprehensive plans include input from food system leaders including farmers, gardeners, local food markets, farmland conservation groups, food distributors, and food access organizations.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 90,
            question:
              "In our community, community economic development agencies and/or organizations lead, collaborate, or partner with food system organizations to identify and support farming and food system businesses, local food supply chains, and local food infrastructure.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 1,
        name: "Creating local zoning and land use regulations",
        completion: false,
        questions: [
          {
            qNum: 91,
            question:
              "We have local zoning, licensing, and/or permitting ordinances that allow backyard poultry. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 92,
            question:
              "We have local zoning, licensing, and/or permitting ordinances that allow farm stands. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 93,
            question:
              "We have local zoning, licensing, and/or permitting ordinances that allow household composting. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 94,
            question:
              "We have local zoning, licensing, and/or permitting ordinances that allow vegetable gardens in lieu of lawns.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 95,
            question:
              "We have local zoning, licensing, and/or permitting ordinances that allow community gardens.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 96,
            question:
              "We have community land-bank programs that give residents a formal voice and input in determining neighborhood land uses, often with the help of community advisory boards composed of local residents.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 2,
        name: "Developing a skilled and capable labor force",
        completion: false,
        questions: [
          {
            qNum: 97,
            question:
              "We have public agencies or nonprofit organizations in the community that invest in workforce training and professional development programs to address labor force needs relevant to the local food system. ",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 98,
            question:
              "In our community, professional education credits and/or certificates aligned with the local food system labor, skill, and leadership requirements are available to residents through online or in-person programs, such as those offered by land-grant institutions or community colleges.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
      {
        sNum: 3,
        name: "Developing relevant infrastructure",
        completion: false,
        questions: [
          {
            qNum: 99,
            question:
              "We have policies, practices, or programs that provide financial support and/or resource commitments devoted to developing local/regional food packing and processing infrastructure.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 100,
            question:
              "We have policies, practices, or programs that provide financial support and/or resource commitments to investments in developing local/regional food distribution infrastructure.",
            answer: null,
            comment: "",
            completion: false,
          },
          {
            qNum: 101,
            question:
              "We have policies, practices, or programs that provide financial support and/or resource commitments devoted to developing BIPOCdesigned and -led local/regional food distribution infrastructure.",
            answer: null,
            comment: "",
            completion: false,
          },
        ],
      },
    ],
  };
};
