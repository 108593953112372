import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import React, { useState, useEffect } from "react";

// pages and components
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import Themes from "./pages/Themes";
import Sections from "./pages/Sections";
import Landing from "./pages/Landing";
import OverviewReport from "./pages/OverviewReport";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetPage from "./pages/PasswordResetPage";
import JsonEditorPage from "./pages/JsonEditorPage";
import Guide from "./pages/Guide";
import CaratResources from "./pages/CaratResources";
import useAuth from "./hooks/useAuth";

function App() {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // Assume a function checkAuthState() that checks and sets the user state
    // You can replace this with your actual logic to check the user's authentication state
    const checkAuthState = async () => {
      // Your logic to check the user's authentication state
      // For example, checking localStorage and updating the context/state
      // After checking, set the loading to false
      setIsLoading(false);
    };

    checkAuthState();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading component
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/login"
              element={
                !isAuthenticated ? <Login /> : <Navigate to="/overview" />
              }
            />
            <Route
              path="/signup"
              element={
                !isAuthenticated ? <Signup /> : <Navigate to="/overview" />
              }
            />
            <Route path="/guide/:guideName" element={<Guide />} />

            <Route path="/resources" element={<CaratResources />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path="/reset/:token" element={<PasswordResetPage />} />

            {/* Protected Routes */}
            <Route
              path="/overview"
              element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/assessment/:assessmentId"
              element={isAuthenticated ? <Themes /> : <Navigate to="/login" />}
            />
            <Route
              path="/assessment/:assessmentId/:themeName/sections"
              element={
                isAuthenticated ? <Sections /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/assessment/:assessmentId/overview-report"
              element={
                isAuthenticated ? <OverviewReport /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/editcarat"
              element={
                isAuthenticated ? <JsonEditorPage /> : <Navigate to="/login" />
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
