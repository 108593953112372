import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const PasswordResetPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/reset-password/${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: newPassword }),
        }
      );

      if (!response.ok) {
        throw new Error("Password reset failed. Please try again.");
      }

      setSuccess(true);
      // Optionally, redirect user after a delay
      setTimeout(() => navigate("/login"), 3000);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="max-w-[400px] mx-auto mt-40 rounded margin-adaptive">
      <img
        src="/assets/caratlogo-color.png"
        alt="CARAT Logo"
        className="w-[64px] h-[64px]"
      />
      <h2 className="text-gray900 custom-30">Reset Your Password</h2>
      <p className="mb-8 custom-16 text-gray600 mx-auto">
        Enter a new password for your account.
      </p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="password"
          className="input-field"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          required
        />
        <input
          type="password"
          className="input-field"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
        />
        <button
          type="submit"
          className="lg-button-cta w-full items-center justify-center"
        >
          Reset Password
        </button>
        {error && <p className="text-error text-center mt-2">{error}</p>}
        {success && (
          <p className="text-sprout text-center mt-2">
            Password reset successfully. Redirecting...
          </p>
        )}
      </form>
    </div>
  );
};

export default PasswordResetPage;
