// Questions.js
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { sortQuestions } from "../pages/Sections";
import { useIsMobile } from "../hooks/useIsMobile";

const Questions = ({
  questions,
  assessmentId,
  updatedVariable,
  onVariableUpdate,
  themeName,
  onSectionUpdate,
  selectedSection,
}) => {
  const { user } = useAuthContext();
  const [answers, setAnswers] = useState({});
  const [isUserAuthorizedToEdit, setIsUserAuthorizedToEdit] = useState(false);
  const [saveStatus, setSaveStatus] = useState({});

  const handleSaveStatus = (questionId, status) => {
    setSaveStatus((prev) => ({ ...prev, [questionId]: status }));

    if (status === "saving") {
      setTimeout(() => {
        setSaveStatus((prev) => ({ ...prev, [questionId]: "saved" }));
        setTimeout(() => {
          setSaveStatus((prev) => ({ ...prev, [questionId]: "" }));
        }, 1500);
      }, 300);
    }
  };

  useEffect(() => {
    if (user && user.assessments) {
      const isAuthorized = user.assessments.some(
        (userAssessment) => userAssessment._id === assessmentId
      );
      setIsUserAuthorizedToEdit(isAuthorized);
    }
  }, [user, assessmentId]);

  useEffect(() => {
    // Initialize answers with the question's current answers
    const initialAnswers = questions.reduce((acc, question) => {
      acc[question._id] = question.answer ? question.answer.toString() : "";
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, [questions]);

  const processedQuestions = sortQuestions(questions);

  const handleAnswerChange = async (questionId, newAnswer) => {
    const updatedAnswers = { ...answers, [questionId]: newAnswer };
    setAnswers(updatedAnswers);

    // Set status to "saving"
    handleSaveStatus(questionId, "saving");

    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${user.token}`,
        },
      };
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/updatequestion/${assessmentId}/${questionId}`,
        {
          answer: newAnswer,
        },
        config
      );
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/completion/${assessmentId}/questions/${questionId}/completion`,
        { completion: true },
        config
      );
    } catch (error) {
      console.error("Error updating question:", error);
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/assessments/${assessmentId}/${encodeURIComponent(
          themeName
        )}/sections`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      onSectionUpdate(data);
    } catch (error) {
      console.error("Section fetch error:", error);
    }

    onVariableUpdate(!updatedVariable);
    // After successful save
    handleSaveStatus(questionId, "saving");
  };

  const handleCommentChange = async (questionId, newComment) => {
    // Set status to "saving"
    handleSaveStatus(questionId, "saving");

    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${user.token}`,
        },
      };
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/updateQuestionComment/${assessmentId}/${questionId}`,
        {
          comment: newComment,
        },
        config
      );
    } catch (error) {
      console.error("Error updating comment:", error);
    }

    // After successful save
    handleSaveStatus(questionId, "saving");
  };

  // Function to auto-resize textarea
  const handleTextareaInput = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  // ref for popup
  const popupRef = useRef();

  // State to track the clicked word
  const [clickedWordKey, setClickedWordKey] = useState(null);

  // Function to check if click is outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setClickedWordKey(null); // Close the popup if click is outside
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isMobile = useIsMobile();

  // Function to handle word click
  const handleClickWord = (event, term, key) => {
    event.stopPropagation();
    const wordPosition = event.target.getBoundingClientRect();

    if (clickedWordKey?.key === key) {
      setClickedWordKey(null);
    } else {
      setClickedWordKey({
        term,
        key,
        position: {
          top: wordPosition.bottom + window.scrollY,
          left: isMobile
            ? wordPosition.left + window.scrollX - 20
            : wordPosition.left + window.scrollX - 170, // Adjust for non-mobile
        },
      });
    }
  };

  const [operationalDefinitions, setOperationalDefinitions] = useState([]);

  // Fetch operational definitions from the backend
  useEffect(() => {
    const fetchOperationalDefinitions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/opdefs`,
          {
            headers: {
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );
        setOperationalDefinitions(response.data);
      } catch (error) {
        console.error("Error fetching operational definitions:", error);
      }
    };

    fetchOperationalDefinitions();
  }, []);

  const matchTerm = (word) => {
    // Ensure word is a string and trim it
    const trimmedWord = String(word)
      .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
      .trim()
      .toLowerCase();

    console.log("Trimmed words:", trimmedWord);

    console.log("OpDefs IN MATCHED TERMS:", operationalDefinitions);
    // Find the matching term
    const matchedTerm = operationalDefinitions.find(
      (def) => def.Term.toLowerCase().trim() === trimmedWord
    );

    return matchedTerm;
  };

  const renderWord = (word, questionId, index) => {
    const matchedTerm = matchTerm(word);
    console.log("Matched Term:", matchedTerm);
    const wordKey = `${questionId}-${index}`;
    if (matchedTerm) {
      return (
        <React.Fragment key={wordKey}>
          <span
            className={` ${
              clickedWordKey?.key === wordKey
                ? "bg-highlight custom-20-med text-black p-1 m-[-4px] transition-all ease-[cubic-bezier(.17,.67,.18,2.0)] shadow"
                : "text-nafsnBlue underline"
            } cursor-pointer`}
            onClick={(event) => handleClickWord(event, matchedTerm, wordKey)}
          >
            {word}
            {clickedWordKey?.key === wordKey && (
              <div
                ref={popupRef}
                style={{
                  position: "absolute",
                  top: `${clickedWordKey.position.top}px`,
                  left: `${clickedWordKey.position.left}px`,
                  zIndex: 40,
                }}
                className="custom-18 absolute transform m-2 bg-white rounded-lg shadow-xl border border-borderprimary group max-w-[400px]"
              >
                <div className="flex flex-row flex-grow justify-between p-4">
                  <strong>{matchedTerm.Term}</strong>
                  <img
                    src="/assets/x.png"
                    alt=""
                    className="w-5 h-5 group-hover:scale-120 group-hover:rotate-90 transition-all duration-200 active:scale-90 cursor-pointer"
                  />
                </div>
                <div className="overflow-y-auto flex flex-col max-h-[230px] px-4 pb-4">
                  {" "}
                  <p className="text-gray600">{matchedTerm.Definition}</p>
                  <br />
                  <p className="text-gray600">{matchedTerm.Details}</p>
                  <br />
                  <p className="text-gray500 custom-functional">
                    For more details, visit the appendix of the paper version of
                    CARAT
                  </p>
                </div>
              </div>
            )}
          </span>{" "}
        </React.Fragment>
      );
    } else {
      return <React.Fragment key={wordKey}>{word} </React.Fragment>;
    }
  };

  return (
    <div className="questions space-y-8">
      {operationalDefinitions ? (
        <div className="space-y-8">
          {processedQuestions.map((question) => (
            <div key={question._id} className="">
              {/* ContentDiv - The rest of the content */}
              <div className="contentDiv flex-grow">
                <div className="flex flex-row gap-2 flex-grow">
                  <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm inline-flex items-center">
                    <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                    <h2 className="custom-14-med text-gray700">
                      Indicator {question.qNum}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-[6px] items-center mb-2">
                    {saveStatus[question._id] === "saving" && (
                      <img
                        src="/assets/loading-icon.png"
                        alt="Loading"
                        className="h-[18px] w-[18px] animate-spin"
                      />
                    )}
                    {saveStatus[question._id] === "saved" && (
                      <img
                        src="/assets/saved-icon.png"
                        alt="Saved"
                        className="h-[18px] w-[18px]"
                      />
                    )}
                    {saveStatus[question._id] && (
                      <div
                        className={`custom-14 text-${
                          saveStatus[question._id] === "saving"
                            ? "gray600"
                            : "gray600"
                        }`}
                      >
                        {saveStatus[question._id]}
                      </div>
                    )}
                  </div>
                </div>

                <p className="mb-4 custom-20">
                  {question.question
                    .split(" ")
                    .map((word, index) =>
                      renderWord(word, question._id, index)
                    )}
                </p>

                <div className="flex mb-2 w-full space-x-2">
                  {["1", "2", "3", "4", "5"].map((value) => {
                    const displayValue =
                      value === "5" ? "N/A" : (parseInt(value) - 1).toString();
                    return (
                      <button
                        key={value}
                        className={`custom-18-med desktop:py-2 desktop:px-4 tablet:py-2 tablet:px-4 mobile:py-2 mobile:px-1 rounded-lg w-full transition-all ease-in-out duration-300 ${
                          answers[question._id] === value
                            ? "bg-white border-sprout border-2 shadow-md text-sprout"
                            : "bg-white border-2 border-borderprimary text-gray500"
                        } ${
                          !isUserAuthorizedToEdit
                            ? "opacity-70 cursor-not-allowed"
                            : (answers[question._id] === value
                                ? "hover:border-sprout hover:text-sprout"
                                : "desktop:hover:border-river hover:text-nafsnBlue") +
                              " desktop:hover:-translate-y-0.5 active:scale-90 "
                        }`}
                        onClick={() => handleAnswerChange(question._id, value)}
                        disabled={!isUserAuthorizedToEdit} // Disable button if user is not authorized
                      >
                        {displayValue}
                      </button>
                    );
                  })}
                </div>

                {/* Comment Box */}
                <textarea
                  className={`block custom-16 w-full py-3.5 px-4 rounded-lg border border-bordersecondary focus:border-borderprimary focus:outline-none transition duration-300 ease-in-out ${
                    !isUserAuthorizedToEdit
                      ? "cursor-not-allowed opacity-70"
                      : ""
                  }`}
                  placeholder="Sources of information and related notes"
                  defaultValue={question.comment}
                  onBlur={(e) =>
                    handleCommentChange(question._id, e.target.value)
                  }
                  onInput={handleTextareaInput} // Added event listener for input
                  style={{ overflowY: "hidden" }} // Prevent scrollbar from appearing
                  disabled={!isUserAuthorizedToEdit}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>Loading operational definitions...</div>
      )}
    </div>
  );
};

export default Questions;
