import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const GuideNav = () => {
  const { logout } = useLogout();
  const { user, dispatch } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarTop, setNavbarTop] = useState("0"); // Change to control the position
  const [transitionDuration, setTransitionDuration] = useState("1000ms");
  const [curve, setCurve] = useState("ease-in-out");

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const dropdownRef = useRef(null);
  const hamburgerDropdownRef = useRef(null);

  const location = useLocation();
  const isGuideActive = location.pathname.startsWith("/guide");

  const whitelist = [
    "65b15b6f0c8cabba304cc374",
    "659585b00c590e08cb6ae646",
    "658cbfd2228e974c365c3df4",
  ];
  const [isNavOpen, setIsNavOpen] = useState(false); // State to handle navigation dropdown

  // Toggle hamburger menu
  const toggleHamburgerMenu = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  // Dismiss menu on item click
  const handleMenuItemClick = () => {
    setShowHamburgerMenu(false);
  };

  // Handle clicks outside the hamburger menu
  useEffect(() => {
    const handleHamburgerClickOutside = (event) => {
      if (
        hamburgerDropdownRef.current &&
        !hamburgerDropdownRef.current.contains(event.target)
      ) {
        setShowHamburgerMenu(false);
      }
    };

    // Only add the listener if the hamburger menu is shown
    if (showHamburgerMenu) {
      document.addEventListener("mousedown", handleHamburgerClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleHamburgerClickOutside);
    };
  }, [showHamburgerMenu]);

  // Handle clicks outside the mobile dropdown
  useEffect(() => {
    const handleMobileDropdownClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleMobileDropdownClickOutside);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleMobileDropdownClickOutside
      );
    };
  }, []);

  // Function to render sidebar items for dropdown
  const renderSidebarItems = () => {
    // This function should return the same items as in the sidebar in Guide.js
    // For simplicity, I'm using static items. Replace these with your actual sidebar items.
    return (
      <>
        <Link
          to="/introduction"
          className="w-full px-4 py-2 custom-14 text-left text-gray900"
          onClick={handleMenuItemClick}
        >
          Introduction
        </Link>
        <Link
          to="/navigating-carat-online"
          className="w-full px-4 py-2 custom-14 text-left text-gray900"
          onClick={handleMenuItemClick}
        >
          Navigating CARAT Online
        </Link>
        <Link
          to="/preparing-to-use-carat"
          className="w-full px-4 py-2 custom-14 text-left text-gray900"
          onClick={handleMenuItemClick}
        >
          Preparing to use CARAT
        </Link>
        {/* ... add other items */}
      </>
    );
  };

  const dropdownFacing = isOpen
    ? "/assets/chevron-top.png"
    : "/assets/chevron-bottom.png";

  // Function to toggle navigation dropdown
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    logout();
    navigate("/login"); // Redirect to login page after logout
    setIsOpen(false); // Add this line
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Print user information only on component mount
    console.log("Navbar render", user);
  }, []);

  const linkTarget = user ? "/" : "/";

  // Function to determine the class for each nav link
  const getNavLinkClass = (isActive) => {
    return isActive ? "activeNavLink" : "inactiveNavLink";
  };

  return (
    <header className="fixed top-0 w-full bg-white border-b border-bordersecondary z-[60] transition-all duration-1000 delay-50">
      <div className="mx-auto">
        <div className="flex justify-between items-center w-full mx-auto tablet:py-5 mobile:py-4 desktop-lg:px-8 desktop:px-8 tablet:px-4 mobile:px-4">
          <div className="flex flex-row gap-6 items-center">
            {/* Hamburger Menu Icon */}
            <button
              onClick={toggleHamburgerMenu}
              className="tablet:flex mobile:flex desktop:hidden"
            >
              <img
                src="/assets/hamburger-icon.png"
                alt="Hamburger Icon"
                className="h-5 w-5"
              />
            </button>

            {/* Hamburger Menu Dropdown */}
            {showHamburgerMenu && (
              <div
                ref={hamburgerDropdownRef}
                className="absolute flex flex-col top-full left-0 bg-white border border-bordersecondary rounded-b shadow-lg z-[55] tablet:flex mobile:flex desktop:hidden"
              >
                {renderSidebarItems()}
              </div>
            )}
            <Link to={linkTarget} className="flex items-center">
              <img
                src="/assets/carat-logo-color.png"
                alt="CARAT Logo"
                className="h-[40px]"
              />
            </Link>
            <div className=" space-x-1 desktop:flex mobile:hidden">
              <NavLink
                to="/overview"
                className={({ isActive }) => getNavLinkClass(isActive)}
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top of the window
                }}
              >
                Dashboard
              </NavLink>
              <NavLink
                to="/guide/introduction"
                className={isGuideActive ? "activeNavLink" : "inactiveNavLink"}
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top of the window
                }}
              >
                Guide
              </NavLink>

              <a
                href="https://www.foodsystemsnetwork.org/docs/CART_Fillable_PDF_Oct_2023.pdf"
                className="inactiveNavLink desktop:block mobile:hidden"
                target="_blank"
              >
                Paper Version
              </a>
            </div>
          </div>
          <nav>
            {user ? (
              <div className="relative flex items-center">
                <div
                  className="flex flex-row gap-2 items-center custom-14-med text-gray700 hover:cursor-pointer"
                  onClick={toggleDropdown}
                >
                  <button className="w-7 h-7 rounded-full bg-nafsnBlue text-cloud custom-12-semi flex items-center justify-center">
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </button>
                  Account
                  <img
                    src={dropdownFacing}
                    alt="Dropdown Icon"
                    className="w-4 h-4"
                  />
                </div>
                {isOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full right-0 mobile:mt-5 tablet:mt-6 w-48 bg-white border border-bordersecondary rounded-b shadow-lg z-[55]"
                  >
                    <p className="px-4 py-2 text-title custom-16-med">
                      {user.firstName} {user.lastName}
                    </p>
                    <button
                      className="w-full px-4 py-2 custom-14 text-left text-gray900 hover:bg-bghover"
                      onClick={() => {
                        navigate("/overview");
                        window.scrollTo(0, 0); // Scroll to the top of the window
                        setIsOpen(false);
                      }}
                    >
                      Dashboard
                    </button>
                    {whitelist.includes(user.id) && (
                      <button className="w-full px-4 py-2 custom-14 text-left text-gray900 hover:bg-bghover flex-grow">
                        <Link to="/editcarat" className="">
                          Edit CARAT
                        </Link>
                      </button>
                    )}
                    <button
                      className="w-full px-4 py-2 custom-14 text-left text-gray900 hover:bg-bghover"
                      onClick={handleClick}
                    >
                      Log out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex space-x-2">
                <Link to="/login">
                  <div id="Login button" className="lg-button">
                    Login
                  </div>
                </Link>
                <Link to="/signup">
                  <div id="Sign up button" className="lg-button-cta">
                    Sign Up
                  </div>
                </Link>
              </div>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default GuideNav;
