import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password, rememberMe);
  };

  return (
    <div className="flex h-screen tablet:items-center tablet:pt-0 mobile:pt-32  margin-adaptive mx-auto">
      <form
        className="flex flex-col mx-auto tablet:w-[343px] mobile:w-full mobile:max-w-[343px]  "
        onSubmit={handleSubmit}
      >
        <h3 className="mb-3 custom-30 text-gray900 mx-auto">
          Log in to your account
        </h3>
        <h3 className="mb-8 custom-16 text-gray600 mx-auto">
          Welcome back! Please enter your details.
        </h3>

        <div className="mb-5 w-full">
          <label className="input-tag" htmlFor="email">
            Email:
          </label>
          <input
            id="email"
            type="email"
            className="input-field"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>

        <div className="mb-6 w-full">
          <label className="input-tag" htmlFor="password">
            Password:
          </label>
          <input
            id="password"
            type="password"
            className="input-field"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>

        <div className="flex flex-row justify-between mb-6">
          <label className="flex flex-row gap-2 items-center">
            <input
              type="checkbox"
              className="w-4 h-4 bg-gray-100 border-borderprimary"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <p className="custom-14-med text-gray700">Remember me</p>
          </label>
          <Link
            to="/passwordreset"
            className="custom-14-semi text-nafsnBlue hover:underline"
          >
            Forgot Password?
          </Link>
        </div>

        <div className="flex-col items-center justify-between w-full">
          <button
            className="lg-button-cta w-full items-center justify-center"
            type="submit"
            disabled={isLoading}
          >
            Log In
          </button>
          {error && (
            <div className="error text-red-500 text-sm mt-2">{error}</div>
          )}
        </div>
        <div className="mt-4 mx-auto">
          <p className="custom-14 text-gray600">
            Don't have an account?{" "}
            <Link
              to="/signup"
              className="text-nafsnBlue hover:underline custom-14-semi"
            >
              Sign up
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
