import { AssessmentsContext } from "../context/AssessmentsContext";
import { useContext } from "react";

export const useAssessmentsContext = () => {
  const context = useContext(AssessmentsContext);

  if (!context) {
    throw Error(
      "useAssessmentsContext must be used inside an AssessmentsContextProvider"
    );
  }

  return context;
};
