import React, { useState } from "react";

const InterpretationComponent = () => {
  return (
    <div className="reading-overall-container">
      <div id="interpretation" className="reading-sections-container">
        <div className="reading-subsections-container">
          <p className="custom-18 text-gray600">
            The scores generated by the CARAT tool are designed to give you a
            comprehensive overview of the current structure of your community’s
            food system and its relationship to resilience. This includes areas
            of relative strength as well as areas that may require additional
            attention and improvements.
          </p>
        </div>
        <p className="custom-18 text-gray600">
          To help you discuss potential action steps after reviewing your
          community’s scores, we have created a supplemental reference called{" "}
          <a
            href="https://docs.google.com/document/d/1WzuuYXPqL2LqLuV34QmP8oVuo846VmUvIKdcFEcp8Yk/edit"
            className="text-nafsnBlue underline"
          >
            the CARAT Resources Archive
          </a>
          . This collection, which includes funding opportunities, services, and
          model programs, may help your community make practical, actionable
          plans once gaps have been identified. The online version of CARAT
          automatically generates relevant resources based on your scores.
        </p>
        <p className="custom-18 text-gray600">
          Above all, however, CARAT is a self-assessment tool, and we believe
          that progress must come from within your own community. Fundamentally,
          community resilience is built on the combined strengths of its
          individuals. Encourage your working group to connect stakeholders from
          different fields and backgrounds and engage the skill sets and
          experiences of the members in your community. There is a lot of
          potential in your community, and we hope that the CARAT tool can help
          you better recognize and make use of the assets and strengths already
          present in your community and create new connections and networks
          across traditionally siloed groups.
        </p>
        <p className="custom-18 text-gray600">
          For further information or assistance, NAFSN invites you to{" "}
          <a
            className="text-nafsnBlue underline"
            href="mailto:info@foodsystemsnetwork.org"
          >
            contact us
          </a>
          . NAFSN is a professional association of people working together to
          strengthen local and regional food systems. Through our network, we
          strive to help strengthen yours.
        </p>
      </div>
    </div>
  );
};

export default InterpretationComponent;
