import React, { useState, useEffect } from "react";

const sections = ["working group", "timeline", "focus area"];

const PreparationToUseCarat = () => {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    let currentSection = "";
    let nearestSection = "";
    let nearestDistance = Infinity;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;
      const gap = 32; // Assuming a 32px gap between sections

      // Extend the range of each section to include part of the gap
      if (
        scrollPosition >= elementTop - gap &&
        scrollPosition <= elementBottom + gap
      ) {
        currentSection = section;

        // Determine the nearest section if in the gap
        const distanceToTop = Math.abs(scrollPosition - elementTop);
        const distanceToBottom = Math.abs(scrollPosition - elementBottom);
        const minDistance = Math.min(distanceToTop, distanceToBottom);

        if (minDistance < nearestDistance) {
          nearestDistance = minDistance;
          nearestSection = section;
        }
      }
    });

    // Set the active section to the nearest section if in the gap
    setActiveSection(currentSection || nearestSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const topPosition = element.offsetTop - 240; // 16px above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8 ">
      <div className="reading-overall-container">
        <div className="reading-sections-container">
          <div className="reading-subsections-container">
            <h1 className="custom-display text-gray900">
              Preparing to use CARAT
            </h1>
            <p className="custom-18 text-gray600">
              {" "}
              The CARAT tool guide emphasizes forming an inclusive working group
              and defining a local food system focus area, involving extensive
              community engagement and a flexible yet structured timeline. It
              guides users through a thorough assessment process, ensuring
              diverse representation and adapting to the unique dynamics of each
              community's food system.
            </p>
          </div>
        </div>
        <div id="working group" className="reading-sections-container">
          <h2 className="custom-30 text-gray700">
            1. Define your working group and its dynamics
          </h2>
          <p className="custom-18 text-gray600">
            Identify food system professionals, advocates, and other interested
            stakeholders in the food system focus area who can devote time and
            contribute knowledge to this assessment. Consider ways to make
            discussions accessible to all community residents by reaching out to
            key stakeholders, adjusting meeting times, and providing childcare,
            compensation, and transportation. Determine how you will listen to,
            hear from, and create space for marginalized groups, neighborhoods,
            and individuals in your process. Your group may change throughout
            the process, as people have varying levels of time to devote to this
            project. Adjust its scale, direction, and approach as needed over
            time. Assigning roles and responsibilities will help ensure the team
            works on schedule and allow future members to pick up from where
            others left off.
          </p>
          <p className="custom-18 text-gray600">
            Some of the functions in your food system focus area may be managed
            by entities outside of the area. For example, a food system focus
            area might be defined by city boundaries but may still need to
            consider water and food regulatory activity managed at the county
            level. The audit should reflect a comprehensive illustration of how
            your food system operates.
          </p>
          <img
            src="/assets/caratprocess.png"
            alt="Carat Process"
            className="w-fill"
          />
        </div>
        <div id="timeline" className="reading-sections-container">
          <h2 className="custom-30 text-gray700">2. Determine your timeline</h2>
          <p className="custom-18 text-gray600">
            Fact-finding may take time. Gathering voices from the community from
            the public, private, and nonprofit sectors is challenging, and
            creating spaces where people can feel heard and safe is necessary.
            Allow your timeline to be flexible, but insert approximate deadlines
            and goals to ensure the process remains on schedule. Your timeline
            will be determined by the size and capacity of your food system
            focus area, although the recommended minimum timeline is at least
            six months. Below is a CARAT process flow chart you can use to
            establish a timeline for your own group.
          </p>
        </div>
        <div id="focus area" className="reading-sections-container">
          <h2 className="custom-30 text-gray700">
            3. Define your food system focus area
          </h2>
          <div className="flex flex-col gap-2">
            <p className="custom-18 text-gray600">
              Think about your food system focus area as representative of a
              relatively small ecological niche instead of a part of the global
              food system. Consider defining your food system focus area along
              the lines of your “local foodshed”—the geographic location defined
              by the routes of your food system, including food producers,
              processors, distributors, and consumers, among others.
            </p>
            <p className="text-gray600 custom-16">
              Definition of "foodshed" from this article:
            </p>
            <p className="text-gray600 custom-16">
              <a
                href="https://cias.wisc.edu/wp-content/uploads/sites/194/2008/07/comingin1.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nafsnBlue underline"
              >
                https://cias.wisc.edu/wp-content/uploads/sites/194/2008/07/comingin1.pdf
              </a>
            </p>
          </div>
          <p className="custom-18 text-gray600">
            Early in the project, it is necessary to specify the size and scope
            of your project by defining your working group and others who will
            be involved. To provide the best benefit, take time to ensure that
            all communities in your food system focus area are represented and
            included in conversations. For assistance, it may be helpful to
            describe the geographic area and significant features of what you
            conceive to be your food system focus area. We have provided some
            guidelines below to organize your ideas:
          </p>
          <p className="custom-18 text-gray600">
            Understand the scope: - How will you determine the boundaries of
            your community food system (geographic, political, economic, etc.)?
            - Does your food system encompass several
            communities/municipalities? Identify networking possibilities: -
            What organizations could you reach out to for agricultural
            inquiries? - What food systems initiatives and leadership
            stakeholders are present in your community? Recognize the edges: -
            Are historically underrepresented groups included in your
            conversations? - Does your working group have representation from
            diverse groups in your community?
          </p>
        </div>
      </div>
      <div className="min-w-[200px] desktop:flex mobile:hidden flex-col gap-3 mt-4 sticky top-[96px] h-[fit-content] ">
        <div className="text-gray400 custom-14-semi pl-8">ON THIS PAGE</div>
        {sections.map((section) => (
          <div
            key={section}
            onClick={() => scrollToSection(section)}
            className={`cursor-pointer pl-8 custom-14-semi ${
              activeSection === section.toLocaleLowerCase()
                ? "text-nafsnBlue border-l-2 border-nafsnBlue"
                : "text-gray600 border-l-2 border-transparent"
            }`}
          >
            {console.log(
              "comparison is",
              activeSection === section.toLocaleLowerCase()
            )}
            {section.charAt(0).toUpperCase() +
              section.slice(1).replaceAll("-", " ")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreparationToUseCarat;
