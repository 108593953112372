import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import EditAssessmentForm from "../themecomponents/EditAssessmentForm";
import EditCollaboratorsForm from "../themecomponents/EditCollaboratorsForm";

import { useAuthContext } from "../hooks/useAuthContext";
import Breadcrumbs from "../components/Breadcrumbs";

// sidebar components
import ThemesPreview from "../themecomponents/ThemesPreview";
import CaratGuideComponent from "../homecomponents/CaratGuideComponent";
import PreparationComponent from "../homecomponents/PreparationComponent";
import ScoringComponent from "../homecomponents/ScoringComponent";
import InterpretationComponent from "../homecomponents/InterpretationComponent";
import DefinitionsComponent from "../homecomponents/DefinitionsComponent";

import axios from "axios";

export function processThemesData(themesData) {
  if (!themesData || !Array.isArray(themesData.themes)) {
    console.error("Invalid themes data:", themesData);
    return [];
  }

  return themesData.themes.map((theme) => {
    const totalQuestions = theme.sections.reduce(
      (acc, section) => acc + section.questions.length,
      0
    );
    const answeredQuestions = theme.sections.reduce(
      (acc, section) =>
        acc + section.questions.filter((q) => q.answer !== null).length,
      0
    );

    const completionStatus =
      totalQuestions === answeredQuestions ? "Completed" : "In Progress";

    const completionPercentage = Math.round(
      (answeredQuestions / totalQuestions) * 100
    );

    const shouldMarkComplete = completionPercentage === 100;

    return {
      tNum: theme.tNum,
      name: theme.name,
      description: theme.description,
      sections: theme.sections,
      completionStatus: completionStatus,
      completionPercentage: completionPercentage,
      shouldMarkComplete: shouldMarkComplete,
    };
  });
}

const Themes = () => {
  const { assessmentId } = useParams();
  const [themes, setThemes] = useState([]);
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const [assessment, setAssessment] = useState({}); // New state to store assessment data
  const [assessmentName, setAssessmentName] = useState("");
  const [assessmentDesc, setAssessmentDesc] = useState("");
  const [assessmentCollaborators, setAssessmentCollaborators] = useState([]);
  // New state to track the overall completion status
  const [completionStatus, setCompletionStatus] = useState("inProgress");

  // State to manage the visibility of the NewAssessmentForm modal
  const [showEditAssessmentModal, setShowEditAssessmentModal] = useState(false);
  const [showEditCollabModal, setShowEditCollabModal] = useState(false);

  const [isUserAuthorizedToEdit, setIsUserAuthorizedToEdit] = useState(false);

  const navigate = useNavigate();

  // State for active tab
  const [activeTab, setActiveTab] = useState("Themes");

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}/themes`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched themes:", data);

        const processedThemes = processThemesData(data);
        setThemes(processedThemes);

        // New logic to handle completion
        processedThemes.forEach((theme) => {
          if (theme.shouldMarkComplete) {
            markThemeComplete(theme.name);
          }
        });
      } catch (error) {
        console.error("Fetch error mannn:", error);
      }
    };

    const fetchAssessmentName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/assessments/${assessmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched assessments:", data);
        setAssessment(data);
        setAssessmentName(data.name);
        setAssessmentDesc(data.description);

        const collaborators = [];

        // Loop through each userId in assessment.user_ids
        for (const userId of data.user_ids) {
          const userResponse = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/user/id/${userId}`,
            {
              method: "GET",
              headers: {
                "Authorization": `Bearer ${user.token}`,
              },
            }
          );

          // Check if user response is ok
          if (userResponse.ok) {
            const collaboratorData = await userResponse.json();

            collaborators.push(collaboratorData);
          }
        }
        setAssessmentCollaborators(collaborators);
        console.log("collaborators", collaborators);
      } catch (error) {
        console.error("Fetch error mannn:", error);
      }
    };

    // Check if the logged-in user has this assessment in their assessments array
    if (user && user.assessments) {
      const isAuthorized = user.assessments.some(
        (userAssessment) => userAssessment._id === assessmentId
      );
      console.log("isAuthorized", isAuthorized);
      setIsUserAuthorizedToEdit(isAuthorized);
    }

    if (user) {
      fetchThemes();
      fetchAssessmentName();
    }
  }, [assessmentId, user]);

  useEffect(() => {
    // New useEffect to handle assessment completion
    const checkAndMarkAssessmentComplete = async () => {
      const allThemesCompleted = themes.every(
        (theme) => theme.shouldMarkComplete
      );

      if (allThemesCompleted) {
        await updateAssessmentCompletion();
      }
    };

    if (themes.length > 0) {
      checkAndMarkAssessmentComplete();
    }
  }, [themes]); // Dependency on 'themes'

  const updateAssessmentCompletion = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/completion/${assessmentId}`,
        {
          completion: true,
        },
        {
          headers: { "Authorization": `Bearer ${user.token}` },
        }
      );

      // Update local state or trigger UI changes if needed
    } catch (error) {
      console.error("Error updating assessment completion:", error);
    }
  };

  // Function to handle theme completion
  const markThemeComplete = async (themeName) => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${user.token}`,
        },
      };
      await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/api/assessments/completion/${assessmentId}/themes/${themeName}/completion`,
        { completion: true },
        config
      );
      // Handle any additional UI updates or logic here
    } catch (error) {
      console.error("Error updating theme completion:", error);
    }
  };

  useEffect(() => {
    // Check the aggregate completion status of themes
    const allComplete = themes.every(
      (theme) => theme.completionPercentage === 100
    );
    const allIncomplete = themes.every(
      (theme) => theme.completionPercentage === 0
    );

    if (allComplete) {
      setCompletionStatus("complete");
    } else if (allIncomplete) {
      setCompletionStatus("incomplete");
    } else {
      setCompletionStatus("inProgress");
    }
  }, [themes]);

  // Function to toggle the modal
  const toggleEditAssessmentModal = () => {
    setShowEditAssessmentModal(!showEditAssessmentModal);
  };

  // Function to toggle the modal
  const toggleEditCollabModal = () => {
    setShowEditCollabModal(!showEditCollabModal);
  };

  const handleAssessmentUpdate = (updatedAssessment) => {
    setAssessmentName(updatedAssessment.name);
    setAssessmentDesc(updatedAssessment.description);
    setAssessmentCollaborators(updatedAssessment.collaborators);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [lastScrollY, setLastScrollY] = useState(0);
  const [breadcrumbTop, setBreadcrumbTop] = useState("80");
  const [sidebarTop, setSidebarTop] = useState("80px");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // Added threshold to avoid immediate hide
        // Scrolling down
        setBreadcrumbTop("0px");
        setSidebarTop("80px");
      } else {
        // Scrolling up
        setBreadcrumbTop("80px");
        setSidebarTop("156px");
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // Function to change active tab
  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };

  // Function to get button style based on active tab
  const getButtonStyle = (tabName) => {
    return activeTab === tabName
      ? "sidebar-button-active"
      : "sidebar-button-inactive";
  };

  return (
    <div
      id="Overall-Container"
      className="flex-col justify-between items-center w-full mx-auto mt-20 pb-32 margin-adaptive"
    >
      <div
        className="fixed top-[80px] transition-all duration-700 ease-in-out z-[54]"
        style={{ top: breadcrumbTop }}
      >
        <Breadcrumbs assessmentName={assessmentName} />
      </div>
      <div className="h-[53px] bg-white"></div>
      <div id="Main-Content" className="flex-col space-y-8">
        <div id="Intro-content" className="mt-6">
          {/* <div id="Low Message" className="mt-6 mb-4">
            {completionStatus === "incomplete" && (
              <img
                src="/assets/FirstInsight.png"
                style={{ width: "100%" }}
                alt="Incomplete"
              />
            )}
          </div> */}
          <div className="desktop:rounded mb-6">
            <div
              id="Overview-section"
              className="flex desktop:flex-row mobile:flex-col desktop:justify-between mobile:justify-start desktop:items-end gap-4"
            >
              <div>
                <h3 className="text-nafsnBlue custom-16-semi mb-1">Overview</h3>
                <h3 className="custom-30 text-gray900 mb-2">
                  {assessmentName}
                </h3>
                <h3 className="custom-16 text-stone">{assessmentDesc}</h3>
              </div>
              <div className="desktop:hidden">
                <h3 className="text-gray900 custom-16-med mb-2">
                  Collaborators
                </h3>
                <div className="grid tablet:grid-cols-3 mobile:grid-cols-2 gap-2">
                  {assessmentCollaborators.map((collaborator) => {
                    // Check if the collaborator has the current assessment in their assessments list
                    const hasAccess =
                      collaborator.assessments.includes(assessmentId);
                    const accessLevel = hasAccess
                      ? "Editing Access"
                      : "Viewing Access";

                    // Get initials
                    const initials = `${collaborator.firstName[0]}${collaborator.lastName[0]}`;

                    return (
                      <div
                        key={collaborator.id}
                        className="flex items-center mb-2"
                      >
                        <div className="w-8 h-8 rounded-full bg-nafsnBlue flex items-center justify-center mr-3 text-white custom-14-semi">
                          {initials}
                        </div>
                        <div>
                          <div className="text-gray700 custom-14-med">{`${collaborator.firstName} ${collaborator.lastName}`}</div>
                          <div className="text-gray700 custom-14">
                            {accessLevel}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row gap-3">
                {isUserAuthorizedToEdit && (
                  <button
                    className="normal-button max-w-[170px]"
                    onClick={toggleEditAssessmentModal}
                  >
                    <img
                      src="/assets/edit-icon.png"
                      alt="Edit Icon"
                      className="w-[20px] h-[20px]"
                    />
                    <p>Edit assessment</p>
                  </button>
                )}
                {completionStatus === "complete" && (
                  <Link
                    to={`/assessment/${assessmentId}/overview-report`}
                    state={{ assessment }}
                  >
                    <button className="secondary-button">
                      <img
                        src="/assets/view-report.png"
                        alt="View Report Icon"
                        className="w-[20px] h-[20px]"
                      />
                      <p>View report</p>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
        </div>

        <div
          id="Sidebar-content-collaborators"
          className="flex desktop:space-x-10 tablet:space-x-10 mobile:space-x-0"
        >
          <div
            id="Sidebar-div"
            className="mobile:hidden tablet:flex tablet:flex-col tablet:space-y-1 tablet:w-[120px] desktop:w-[118px] tablet:sticky transition-all duration-700 ease-in-out"
            style={{ top: sidebarTop }}
          >
            <button
              id="essentials-title"
              onClick={() => changeTab("Themes")}
              className={getButtonStyle("Themes")}
            >
              Assessment
            </button>
            <button
              id="essentials-title"
              onClick={() => changeTab("CARATGuide")}
              className={getButtonStyle("CARATGuide")}
            >
              Explanation
            </button>
            <button
              id="essentials-title"
              onClick={() => changeTab("Preparation")}
              className={getButtonStyle("Preparation")}
            >
              Preparation
            </button>
            <button
              id="essentials-title"
              onClick={() => changeTab("Scoring")}
              className={getButtonStyle("Scoring")}
            >
              Scoring
            </button>
            <button
              id="essentials-title"
              onClick={() => changeTab("Interpretation")}
              className={getButtonStyle("Interpretation")}
            >
              Interpretation
            </button>
            <button
              id="essentials-title"
              onClick={() => changeTab("Definitions")}
              className={getButtonStyle("Definitions")}
            >
              Definitions
            </button>
          </div>

          <div className=" flex-grow max-w-[762px] min-w-[300px]">
            {/* Right for Overview */}
            {activeTab === "Themes" && (
              <div id="AllThemeInfo" className="flex flex-col gap-6">
                <div className="flex-col flex gap-1">
                  <h3 className="custom-18-semi text-gray900">
                    Theme overview
                  </h3>
                  <p className="custom-14 text-gray600">
                    An overview of all the themes in the assessment
                  </p>
                </div>
                {!isUserAuthorizedToEdit && (
                  <div className="warning-banner">
                    <img
                      src="/assets/warning-block.png"
                      alt="Bookmarked"
                      className="w-12 h-12"
                    />
                    <div>
                      <p className="custom-16-med text-gray700">
                        You do not have edit access to this CARAT assessment
                      </p>
                      <p className="custom-14 text-gray600">
                        If you believe this is an error, try log out and log
                        back in. Otherwise, please reach out to the owner(s) of
                        the assessment for access.
                      </p>
                    </div>
                  </div>
                )}
                <ThemesPreview themes={themes} assessmentId={assessmentId} />
              </div>
            )}

            {/* Right for CARAT Guide */}
            {activeTab === "CARATGuide" && (
              <div className="assessments flex-grow space-y-4 mb-6">
                <h1 className="custom-30 text-gray900">Introduction</h1>
                <CaratGuideComponent />
              </div>
            )}

            {/* Right for Preparation */}
            {activeTab === "Preparation" && (
              <div className="assessments flex-grow space-y-4 mb-6">
                <h1 className="custom-30 text-gray900">
                  Preparing to use CARAT
                </h1>
                <PreparationComponent />
              </div>
            )}

            {/* Right for Scoring */}
            {activeTab === "Scoring" && (
              <div className="assessments flex-grow space-y-4 mb-6">
                <h1 className="custom-30 text-gray900">
                  Scoring and Using the Audit Tool
                </h1>
                <ScoringComponent />
              </div>
            )}

            {/* Right for Interpretation */}
            {activeTab === "Interpretation" && (
              <div className="assessments flex-grow space-y-4 mb-6">
                <h1 className="custom-30 text-gray900">
                  How to Interpret Your Scores
                </h1>
                <InterpretationComponent />
              </div>
            )}

            {/* Right for Definitions */}
            {activeTab === "Definitions" && (
              <div className="assessments flex-grow space-y-4 mb-6">
                <h1 className="custom-30 text-gray900">
                  Operational Definitions
                </h1>{" "}
                <DefinitionsComponent />
              </div>
            )}

            <button onClick={scrollToTop} className="normal-button">
              Scroll up
            </button>
          </div>

          <div
            className="min-w-[240px] desktop:block mobile:hidden tablet:sticky"
            style={{ top: sidebarTop }}
          >
            <h3 className="text-gray900 custom-18-semi mb-6">Collaborators</h3>
            <div>
              {assessmentCollaborators.map((collaborator) => {
                // Check if the collaborator has the current assessment in their assessments list
                const hasAccess =
                  collaborator.assessments.includes(assessmentId);
                const accessLevel = hasAccess
                  ? "Editing Access"
                  : "Viewing Access";

                // Get initials
                const initials = `${collaborator.firstName[0]}${collaborator.lastName[0]}`;

                return (
                  <div key={collaborator.id} className="flex items-center mb-5">
                    <div className="w-8 h-8 rounded-full bg-nafsnBlue flex items-center justify-center mr-3 text-white custom-14-semi">
                      {initials}
                    </div>
                    <div>
                      <div className="text-gray700 custom-14-med">{`${collaborator.firstName} ${collaborator.lastName}`}</div>
                      <div className="text-gray700 custom-14">
                        {accessLevel}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {isUserAuthorizedToEdit && (
              <button className="normal-button" onClick={toggleEditCollabModal}>
                <img
                  src="/assets/users-edit.png"
                  alt="Edit Icon"
                  className="w-[20px] h-[20px]"
                />
                <p>Edit collaborators</p>
              </button>
            )}
          </div>
        </div>
      </div>
      {showEditAssessmentModal && (
        <EditAssessmentForm
          onClose={toggleEditAssessmentModal}
          assessmentId={assessmentId}
          onAssessmentUpdate={handleAssessmentUpdate}
          assessmentCollaborators={assessmentCollaborators}
        />
      )}
      {showEditCollabModal && (
        <EditCollaboratorsForm
          onClose={toggleEditCollabModal}
          assessmentId={assessmentId}
          onAssessmentUpdate={handleAssessmentUpdate}
          assessmentCollaborators={assessmentCollaborators}
        />
      )}
    </div>
  );
};

export default Themes;
