// ThemesPreview.js
import React from "react";
import { Link } from "react-router-dom";

const ProgressBar = ({ completionPercentage }) => {
  const radius = 22;
  const strokeWidth = 4;
  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - (completionPercentage / 100) * circumference;

  // Rotate the circle to start from the top
  const rotation = -90;
  const rotationTransform = `rotate(${rotation} ${radius} ${radius})`;

  return (
    <div className="relative flex justify-center items-center w-11 h-11">
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#D0D5DD" // Background circle color (Tailwind gray-300)
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#185481" // Progress circle color (Tailwind green-500)
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          strokeLinecap="round"
          transform={rotationTransform}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <span className="absolute custom-12 font-semibold text-gray-700">
        {`${completionPercentage}%`}
      </span>
    </div>
  );
};

const ThemeSectionComponent = ({ theme, assessmentId, scrollToTop }) => {
  return (
    <div className="">
      {(() => {
        let foundIncomplete = false;
        const getImagePath = (section) => {
          if (section.completion) {
            return "/assets/completed-section-indicator.png"; // Image for completed section
          } else {
            return "/assets/incomplete-section-indicator.png"; // Image for incomplete section
          }
        };

        const getLineColor = (section) => {
          return section.completion ? "bg-sprout" : "bg-borderprimary";
        };

        const isLastSection = (index) => {
          return index === theme.sections.length - 1;
        };

        return theme.sections.map((section, sNum) => {
          let textColorClass = "";
          if (!foundIncomplete && !section.completion) {
            foundIncomplete = true; // Set flag to true when first incomplete section is found
            textColorClass = "text-nafsnBlue"; // Apply nafsnBlue for the first incomplete section
            const buttonText =
              theme.completionPercentage === 0 ? "Begin" : "Resume";

            // Incomplete sections
            return (
              <div key={sNum}>
                <div
                  id="sectionName"
                  className={`custom-functional ${textColorClass} flex justify-between`}
                >
                  <div className="flex items-start relative mr-4 ">
                    <div className="flex-shrink-0 flex flex-col items-center mr-4 transition-all duration-300 desktop:w-[48px] mobile:w-[40px]">
                      <div className="blue-shadow">
                        <img
                          src="/assets/current-section-indicator.png"
                          alt="Section Status"
                          className="w-[24px] h-[24px] group-hover:scale-112 group-active:scale-90 transition-all duration-300"
                        />
                      </div>

                      {!isLastSection(sNum) && (
                        <div
                          className={`w-0.5 ${getLineColor(
                            section
                          )} rounded absolute my-1`}
                          style={{ top: "24px", bottom: "0px" }}
                        ></div>
                      )}
                    </div>
                    <div>
                      <p className="custom-14-semi">Section {sNum + 1}</p>
                      <p className="custom-14 mb-6">{section.name}</p>
                    </div>
                  </div>
                  <Link
                    to={`/assessment/${assessmentId}/${theme.name}/sections`}
                    onClick={scrollToTop}
                    className="flex h-full items-center flex-shrink-0"
                  >
                    <button className="secondary-button mt-3">
                      <img
                        src="/assets/arrow-circle-blue.png"
                        alt="Section Status"
                        className="w-[20px] h-[20px]"
                      />
                      <p className="">{buttonText}</p>
                    </button>
                  </Link>
                </div>
              </div>
            );
          }
          // Incomplete sections
          return (
            <div key={sNum}>
              <div
                id="sectionName"
                className={`custom-functional flex justify-between w-full `}
              >
                <div className="flex items-start relative">
                  <div className="flex-shrink-0 flex flex-col items-center mr-4 transition-all duration-300 desktop:w-[48px] mobile:w-[40px]">
                    <div className="blue-shadow">
                      <img
                        src={getImagePath(section)}
                        alt="Section Status"
                        className="w-[24px] h-[24px] group-hover:scale-112 group-active:scale-90 transition-all duration-300"
                      />
                    </div>

                    {!isLastSection(sNum) && (
                      <div
                        className={`w-0.5 ${getLineColor(
                          section
                        )} rounded absolute my-1`}
                        style={{ top: "24px", bottom: "0px" }}
                      ></div>
                    )}
                  </div>
                  <div>
                    <p className="custom-14-semi text-gray700">
                      Section {sNum + 1}
                    </p>
                    <p className="custom-14 mb-6 text-gray600">
                      {section.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      })()}
    </div>
  );
};

const ThemesPreview = ({ themes, assessmentId }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const getImageForTNum = (tNum) => {
    return `/assets/theme${tNum}-icon.png`; // Assuming image names are in this format
  };

  return (
    <div className="">
      {themes.map((theme, index) => (
        <div
          key={index}
          className="bg-white desktop:px-6 desktop:py-5 mobile:p-4 mb-4 space-y-5 rounded border border-bordersecondary"
        >
          <div className="flex justify-between items-center ">
            <div className="flex items-center gap-4 w-full">
              <div className="flex-shrink-0 rounded flex justify-center items-center desktop:w-12 desktop:h-12 mobile:w-10 mobile:h-10">
                <img
                  src={getImageForTNum(theme.tNum)}
                  alt={`Theme ${theme.tNum}`}
                  className="w-full h-full object-contain" // Adjust these styles as needed
                />
              </div>
              <div className="flex-grow min-w-0 flex flex-col space-y-1">
                <h3 className="text-gray900 custom-16-med flex-grow">
                  {theme.name}
                </h3>
                <h3 className="text-gray600 custom-14 truncate flex-grow">
                  {theme.description}
                </h3>
              </div>
              {theme.completionPercentage === 100 ? (
                <Link
                  to={`/assessment/${assessmentId}/${theme.name}/sections`}
                  onClick={scrollToTop}
                  className="flex h-full items-center flex-shrink-0"
                >
                  <button className="secondary-button mt-3">
                    <img
                      src="/assets/arrow-circle-blue.png"
                      alt="Section Status"
                      className="w-[20px] h-[20px]"
                    />
                    <p>Review</p>
                  </button>
                </Link>
              ) : (
                <ProgressBar
                  completionPercentage={theme.completionPercentage}
                />
              )}
            </div>
          </div>
          <div
            id="Divider"
            className="h-[1px] w-full bg-bordersecondary mb-4"
          ></div>
          <ThemeSectionComponent
            theme={theme}
            assessmentId={assessmentId}
            scrollToTop={scrollToTop}
            className="w-full"
          />
        </div>
      ))}
    </div>
  );
};

export default ThemesPreview;
