import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";

import GuideIntro from "../guidecomponents/GuideIntro";
import NavigatingCaratOnline from "../guidecomponents/NavigatingCaratOnline";
import PreparingToUseCarat from "../guidecomponents/PreparingToUseCarat";
import GuideNav from "../guidecomponents/GuideNav";
import HowToInterpretScores from "../guidecomponents/HowToInterpretScore";
import NotesFromDev from "../guidecomponents/NotesFromDev";
import ScoringCaratTool from "../guidecomponents/ScoringCaratTool";
import OperationalDefinitions from "../guidecomponents/OperationalDefinitions";

const Guide = () => {
  const { guideName } = useParams();

  const [activeTab, setActiveTab] = useState("Introduction");

  const navigate = useNavigate();

  const scrollToTopNonSmooth = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    switch (guideName) {
      case "introduction":
        setActiveTab("Introduction");
        break;
      case "navigating-carat-online":
        setActiveTab("Navigating CARAT Online");
        break;
      case "preparing-to-use-carat":
        setActiveTab("Preparing to use CARAT");
        break;
      case "scoring-the-carat-tool":
        setActiveTab("Scoring CARAT");
        break;
      case "how-to-interpret-your-scores":
        setActiveTab("How to Interpret Your Scores");
        break;
      case "notes-from-the-developers":
        setActiveTab("Notes from the Developers");
        break;
      case "operational-definitions":
        setActiveTab("Operational Definitions");
        break;
      // ... other cases
    }
  }, [guideName]);

  const handleTabClick = (tabName) => {
    let path = "";
    switch (tabName) {
      case "Introduction":
        path = "/guide/introduction";
        break;
      case "Navigating CARAT Online":
        path = "/guide/navigating-carat-online";
        break;
      case "Preparing to use CARAT":
        path = "/guide/preparing-to-use-carat";
        break;
      case "Scoring CARAT":
        path = "/guide/scoring-the-carat-tool";
        break;
      case "How to Interpret Your Scores":
        path = "/guide/how-to-interpret-your-scores";
        break;
      case "Notes from the Developers":
        path = "/guide/notes-from-the-developers";
        break;
      case "Operational Definitions":
        path = "/guide/operational-definitions";
        break;
      // ... other cases
    }
    navigate(path);
  };

  const getComponent = () => {
    switch (activeTab) {
      case "Introduction":
        return <GuideIntro />;
      case "Navigating CARAT Online":
        return <NavigatingCaratOnline />;
      case "Preparing to use CARAT":
        return <PreparingToUseCarat />;
      case "Scoring CARAT":
        return <ScoringCaratTool />;
      case "How to Interpret Your Scores":
        return <HowToInterpretScores />;
      case "Notes from the Developers":
        return <NotesFromDev />;
      case "Operational Definitions":
        return <OperationalDefinitions />;
      // ... other cases
      default:
        return <GuideIntro />;
    }
  };

  const renderTab = (tabName) => {
    const isActive = activeTab === tabName;
    return (
      <div
        className={`cursor-pointer transition-all duration-300 ease-in-out ${
          isActive ? "guide-tab-active" : "guide-tab-inactive"
        }`}
        onClick={() => {
          setActiveTab(tabName); // Immediate visual update
          handleTabClick(tabName); // Navigate to the new URL
          scrollToTopNonSmooth();
        }}
      >
        {tabName}
      </div>
    );
  };

  return (
    <div id="main-footer" className="flex flex-col w-full ">
      <GuideNav></GuideNav>
      <div
        id="Overall-container"
        className="flex-grow desktop-lg:px-6 desktop:px-6 tablet:px-4 mobile:px-4 mt-20 flex flex-row desktop-xl:gap-40 desktop-lg:gap-16 tablet:gap-8"
      >
        <div className="border-r border-bordersecondary desktop:block mobile:hidden">
          <div
            id="Sidebar"
            className=" flex-col flex desktop:min-w-[262px] desktop:max-w-[262px] desktop-xl:min-w-[360px] desktop-xl:max-w-[360px] pr-6 sticky top-[80px] h-[fit-content]"
          >
            <div id="Divider" className="h-[4px] w-full bg-transparent"></div>
            <div
              id="tag"
              className="px-4 pt-4 pb-2 text-gray400 custom-12-semi"
            >
              Overview
            </div>
            {renderTab("Introduction")}
            {renderTab("Notes from the Developers")}
            {/* {renderTab("Navigating CARAT Online")} */}
            <div
              id="Divider"
              className="h-[4px] w-full bg-transparent"
            ></div>{" "}
            <div
              id="tag"
              className="px-4 pt-4 pb-2 text-gray400 custom-12-semi"
            >
              Taking CARAT
            </div>
            {renderTab("Preparing to use CARAT")}
            {renderTab("Scoring CARAT")}
            {renderTab("How to Interpret Your Scores")}
            <div id="Divider" className="h-[4px] w-full bg-transparent"></div>
            <div
              id="tag"
              className="px-4 pt-4 pb-2 text-gray400 custom-12-semi"
            >
              Resources
            </div>
            {renderTab("Operational Definitions")}
            <div
              className="guide-tab-inactive cursor-pointer justify-between"
              onClick={() =>
                window.open(
                  "https://docs.google.com/document/d/13iM5lSGG3B_pFJ2rgerPORcLZRqYherlY4CtRxVARoY/edit?usp=sharing",
                  "_blank"
                )
              }
            >
              Resource Archive
              <img
                src="/assets/link-external.png"
                alt="external-link-icon"
                className="w-4 h-4"
              />
            </div>
            <div
              className="guide-tab-inactive cursor-pointer justify-between"
              onClick={() =>
                window.open(
                  "https://www.foodsystemsnetwork.org/carat_working_group.php",
                  "_blank"
                )
              }
            >
              About the Developers
              <img
                src="/assets/link-external.png"
                alt="external-link-icon"
                className="w-4 h-4"
              />
            </div>
          </div>
        </div>

        <div className=" flex flex-col gap-4 flex-grow mb-8">
          {getComponent()}{" "}
          <div>
            <button onClick={scrollToTop} className="normal-button">
              Scroll up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
