import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";

const EditProfileForm = () => {
  const { user, dispatch } = useAuthContext();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isModified, setIsModified] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/id/${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
          },
          body: JSON.stringify({ firstName, lastName, email }),
        }
      );

      if (!response.ok) {
        // If response is not OK, read the response as text or json based on the error response type
        const errorText = response.statusText || "Failed to update profile";
        throw new Error(errorText);
      }

      const json = await response.json();
      dispatch({ type: "UPDATE_USER", payload: json });

      // Set success message
      setSuccessMessage("Profile updated successfully!");
      setIsModified(false); // Reset isModified
      setError(null); // Clear any previous errors
    } catch (error) {
      setError(error.message);
      setSuccessMessage("");
    }
  };

  // Function to reset form fields to initial values
  const handleCancel = () => {
    setSuccessMessage("");
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setError(null);
    setIsModified(false);
  };

  return (
    <div className="edit-profile-form main-div flex flex-col gap-6">
      <div>
        <h2 className="text-gray900 custom-18-med mb-1">Personal info</h2>
        <h3 className="text-gray500 custom-14">
          Update your personal details here.
        </h3>
      </div>

      <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* First Name */}
        <div className="flex tablet:flex-row mobile:flex-col">
          <label className="input-tag">First Name</label>
          <input
            type="text"
            className="input-field"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setSuccessMessage("");
              setIsModified(true);
            }}
            placeholder={user.firstName}
          />
        </div>

        {/* Last Name */}
        <div className="flex tablet:flex-row mobile:flex-col">
          <label className="input-tag">Last Name</label>
          <input
            type="text"
            className="input-field"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setSuccessMessage("");
              setIsModified(true);
            }}
            placeholder={user.lastName}
          />
        </div>

        {/* Email */}
        <div className="flex tablet:flex-row mobile:flex-col">
          <label className="input-tag">Email</label>
          <input
            type="email"
            className="input-field"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setSuccessMessage("");
              setIsModified(true);
            }}
            placeholder={user.email}
          />
        </div>

        {/* Email */}
        <div className="flex tablet:flex-row mobile:flex-col">
          <p className="input-tag">Password</p>
          <div>
            <Link
              to="/passwordreset"
              className="min-w-[350px] max-w-[512px] transition-all rounded-lg border border-borderprimary custom-14-semi text-gray700 block w-full py-[10px] px-[14px] duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95"
            >
              Change password
            </Link>
          </div>
        </div>

        {/* Error/Success Message */}
        {error && (
          <div className="text-error custom-functional mt-1">{error}</div>
        )}
        {successMessage && (
          <div className="text-green-600 custom-functional mt-1">
            {successMessage}
          </div>
        )}

        <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>

        {/* Submit Button */}
        <div className="flex justify-end gap-3">
          <button
            type="button" // Explicitly set the type to 'button'
            onClick={handleCancel}
            className={`${
              isModified ? "secondary-button" : "normal-button"
            } normal-button`}
            disabled={!isModified}
          >
            Reset
          </button>
          <button
            type="submit"
            className={`${isModified ? "blue-button" : "normal-button"}`}
            disabled={!isModified}
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfileForm;
