import axios from "axios";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

const ThemeReportProgressBar = ({ completionPercentage }) => {
  const completedWidth = Math.min(completionPercentage, 100);
  // Adjusting the indicator position to account for its width
  const indicatorWidth = 12; // Width of the indicator in pixels
  const indicatorPosition = `calc(${completedWidth}% - ${
    indicatorWidth / 2
  }px)`;

  return (
    <div className="relative h-[48px] bg-bordersecondary rounded-lg z-[-1]">
      <div
        className="absolute bottom-full mb-4"
        style={{ left: indicatorPosition }}
      ></div>
      <div
        className="h-full bg-nafsnBlue rounded-lg"
        style={{ width: `${completedWidth}%` }}
      ></div>
    </div>
  );
};

const ThemeReportSection = ({
  theme,
  assessment,
  bookmarks,
  handleBookmark,
}) => {
  const { user } = useAuthContext();
  const [resourcesData, setResourcesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  console.log("assessment", assessment);

  useEffect(() => {
    // Fetch resources from the backend
    const fetchResources = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/resources`
        );
        console.log("Resources fetched successfully", response.data);
        setResourcesData(response.data);
      } catch (error) {
        console.error("Error fetching resources", error);
      }
    };

    if (user) {
      fetchResources();
    }
  }, [user]);

  if (!theme) return null; // If no theme is selected, don't render the component

  const themeScore = theme.sections.reduce((acc, section) => {
    return (
      acc +
      section.questions.reduce((acc, question) => {
        return (
          acc +
          (question.answer && question.answer !== 5 ? question.answer - 1 : 0)
        );
      }, 0)
    );
  }, 0);

  const themeMaxScore = theme.sections.reduce((acc, section) => {
    return (
      acc +
      section.questions.reduce((sectionAcc, question) => {
        return sectionAcc + (question.answer !== 5 ? 3 : 0); // Add 3 only if answer is not N/A
      }, 0)
    );
  }, 0);

  const themeCompletionPercentage = Math.round(
    (themeScore / themeMaxScore) * 100
  );
  const answeredQuestions = theme.sections.reduce((acc, section) => {
    return (
      acc +
      section.questions.filter(
        (question) => question.answer && question.answer !== 5
      ).length
    );
  }, 0);
  const notApplicableQuestions = theme.sections.reduce((acc, section) => {
    return (
      acc + section.questions.filter((question) => question.answer === 5).length
    );
  }, 0);

  const fetchedThemeQuestions = theme.sections.flatMap((section) =>
    section.questions
      .filter((question) => question.answer < 4 || question.answer === 5)
      .map((question) => ({
        questionText: question.question,
        qNum: question.qNum,
        answer:
          question.answer === 5 ? "N/A" : (question.answer - 1).toString(),
        comment: question.comment,
      }))
  );

  const findResourcesForIndicator = (indicatorNum) => {
    if (!resourcesData) return [];

    // Assuming each resource in resourcesData has an 'indicatorNum' field
    return resourcesData.filter(
      (resource) => resource.indicatorNum === indicatorNum
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const answerColor = (answer) => {
    if (["0", "1", "2"].includes(answer)) {
      return "text-error"; // existing red color for 0, 1, 2
    } else if (answer === "N/A") {
      return "text-stone"; // new color for N/A
    } else {
      return "text-sprout"; // new color for other values
    }
  };

  // Filter questions based on search query
  const filteredThemeQuestions = fetchedThemeQuestions.filter(
    (question) =>
      question.questionText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      question.qNum.toString().includes(searchQuery)
  );

  return (
    <div
      id="Theme Specific Section"
      className="border rounded-lg border-bordersecondary"
    >
      {/* Theme Intro Section */}
      <div id="theme specific intro" className="px-6 py-5">
        <h3 className="custom-18-semi text-gray900 mb-2">{theme.name}</h3>
        <ThemeReportProgressBar
          completionPercentage={themeCompletionPercentage}
        />
        <div className="mt-2 flex justify-between">
          <p className="text-gray600 custom-14">
            Resiliency Score: {themeScore}/{themeMaxScore}(
            {themeCompletionPercentage}%), {answeredQuestions} answered,{" "}
            {notApplicableQuestions} not applicable
          </p>
        </div>
      </div>

      <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>
      <div className="px-6 py-5 flex tablet:flex-row mobile:flex-col gap-3 justify-between">
        {/* Implement search bar here */}
        <div>
          <input
            type="text"
            placeholder="Search for an indicators name or number"
            className="search-bar"
            style={{
              backgroundImage: "url('/assets/search-lg.png')",
              backgroundPosition: "10px center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "20px",
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Link
          to={`/assessment/${assessment._id}/${theme.name}/sections`}
          onClick={scrollToTop}
          className="flex-shrink-0"
        >
          <button className="normal-button">Review Theme</button>
        </Link>
      </div>
      <div id="Divider" className="h-[1px] w-full bg-bordersecondary"></div>

      {fetchedThemeQuestions.length === 0 ? (
        // Display this message when there are no theme questions
        <div className="px-6 py-5">
          <div className="warning-banner">
            <img
              src="/assets/warning-block.png"
              alt="Warning"
              className="w-12 h-12"
            />
            <div>
              <p className="custom-16-med text-gray700">
                You have assigned a score of 3 for all indicators
              </p>
              <p className="custom-14 text-gray600">
                If you wish to view the indicator resources, please click "View
                All Indicators"
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Display a different message when there are theme questions
        <div className="px-6 py-5">
          <div className="warning-banner">
            <img
              src="/assets/warning-block.png"
              alt="Warning"
              className="w-12 h-12"
            />
            <div>
              <p className="custom-16-med text-gray700">
                For those indicators to which you assigned a score of 2 or less
              </p>
              <p className="custom-14 text-gray600">
                We have provided you with resources to help your community
                consider next steps in addressing areas of concern. These
                resources, which include funding sources, services, model
                programs, articles, references, and case studies - are not meant
                to serve as recommendations or endorsements. Rather, we point to
                them for clarity, guidance, and inspiration.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Questions and Resources Section */}
      <div id="Question specific" className="flex-col space-y-12 px-6 py-5">
        {fetchedThemeQuestions.length === 0 ? (
          <div className=""></div>
        ) : (
          filteredThemeQuestions.map((processedQuestion) => {
            const resources = findResourcesForIndicator(processedQuestion.qNum);

            return (
              <div
                key={processedQuestion.qNum}
                id="question-resource-section"
                className="flex flex-col gap-12"
              >
                <div className="desktop:flex desktop:flex-row mobile:flex-col desktop:gap-16 mobile:gap-8">
                  <div
                    id="indicator-info"
                    className="desktop:w-[320px] desktop:min-w-[320px]"
                  >
                    <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm inline-flex items-center">
                      <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                      <h2 className="custom-14-med text-gray700">
                        Indicator {processedQuestion.qNum}
                      </h2>
                    </div>
                    <p className="custom-20-med text-gray900 mb-2">
                      {processedQuestion.questionText}
                    </p>
                    <p className="custom-16 text-gray600 mb-1">
                      Your response:{" "}
                      <span className={answerColor(processedQuestion.answer)}>
                        {processedQuestion.answer}
                      </span>
                    </p>
                    <p className="custom-16 text-gray600 mb-4">
                      Your notes:{" "}
                      <span className="text-gray900 custom-16">
                        {processedQuestion.comment}
                      </span>
                    </p>
                  </div>

                  <div className="resource-list flex flex-col gap-4 w-full">
                    {resources.length === 0 ? (
                      <div
                        id="no-resources"
                        className="p-6 rounded-lg border-bordersecondary border flex flex-grow w-full"
                      >
                        <p className="custom-16 text-gray600">
                          No resources available for this indicator
                        </p>
                      </div>
                    ) : (
                      resources.map((resource) => {
                        const isBookmarked = bookmarks.some(
                          (bookmark) => bookmark._id === resource._id
                        );

                        return (
                          <div
                            key={resource._id}
                            id="resource-item"
                            className="border tablet:p-6 mobile:p-4 rounded-lg border-bordersecondary"
                          >
                            <div className="flex flex-row gap-6 justify-between">
                              <p className="resource-name custom-18-semi text-gray900 mb-2">
                                {resource.resourceName}
                              </p>
                              <div className="border px-2 py-0.5 mb-2 rounded-md border-borderprimary shadow-sm items-center max-h-[24px] tablet:inline-flex mobile:hidden">
                                <div className="w-1.5 h-1.5 rounded-full bg-nafsnBlue mr-2"></div>
                                <h2 className="custom-14-med text-gray700">
                                  Resource
                                </h2>
                              </div>
                            </div>
                            <p className="resource-description custom-16 text-gray600 mb-4">
                              {resource.resourceDescription}
                            </p>
                            <div className="flex flex-row gap-3">
                              <div className="small-button">
                                <img
                                  src="/assets/link-external.png"
                                  alt="External Link Icon"
                                  className="w-4 h-4"
                                />
                                <a
                                  href={resource.resourceLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Learn More
                                </a>
                              </div>
                              <button
                                onClick={() =>
                                  handleBookmark(resource, assessment.name)
                                }
                                className={`small-button custom-14-med  ${
                                  isBookmarked
                                    ? "text-sprout"
                                    : "text-nafsnBlue"
                                }`}
                              >
                                <div>
                                  {isBookmarked ? (
                                    <img
                                      src="/assets/bookmark-solid.png"
                                      alt="Unbookmark Icon"
                                      className="w-4 h-4"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/bookmark.png"
                                      alt="Bookmark Icon"
                                      className="w-4 h-4"
                                    />
                                  )}
                                </div>
                                <p>
                                  {isBookmarked ? "Unbookmark" : "Bookmark"}
                                </p>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ThemeReportSection;
